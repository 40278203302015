import {
  Button,
  Card,
  CardHeader,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import OnlyHeader from "../components/Headers/OnlyHeader";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { askQuestions } from "../store/actions/askQuestionsAction";
import LoadingOverlay from "react-loading-overlay";
import { addAnswer } from "../store/actions/askQuestionsAction";

export default function AskQuestions() {
  const dispatch = useDispatch();
  const { questions, loading, addLoader } = useSelector(
    (state) => state.question
  );
  const { uid } = useSelector((state) => state.authUser);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedDropdown, setSelectedDropdown] = useState("All");
  const [selectedAskQuestions, setSelectedAskQuestions] = useState([]);
  const [answer, setAnswer] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [answerModal, setAnswerModal] = useState(false);
  const answerToggle = () => setAnswerModal(!answerModal);
  const toggle = () => {
    setDropdownOpen((prevState) => !prevState);
  };
  const handleDropdownSelect = (value) => {
    setSelectedDropdown(value);
  };
  const [askQuestionDetail, setAskQuestionDetail] = useState([
    {
      id: 0,
      issue: "How we reset the digital watch?",
      name: "Lenovo i12",
      status: false,
    },
    {
      id: 1,
      issue: "How we change the time in manual watch?",
      name: "Simple watch",
      status: false,
    },
    {
      id: 2,
      issue: "How we set alarm in watch?",
      name: "Simple watch",
      status: true,
    },
  ]);

  const handleSubmit = () => {
    dispatch(
      addAnswer(answer, selectedId, () => {
        answerToggle();
        setAnswer("");
        setSelectedId("");
      })
    );
  };
  useEffect(() => {
    const filteredQuestions = questions.filter((question) => {
      if (selectedDropdown === "All") {
        return true;
      } else if (selectedDropdown === true) {
        return question.status === true;
      } else if (selectedDropdown === false) {
        return question.status === false;
      }
      return false;
    });
    setSelectedAskQuestions(filteredQuestions);
  }, [selectedDropdown, questions]);
  useEffect(() => {
    dispatch(askQuestions(uid));
  }, []);
  return (
    <>
      <OnlyHeader />
      <Container fluid className="mt--7">
        <Row>
          <div className="col">
            <LoadingOverlay
              active={loading}
              spinner
              text="Ask Questions Loading...."
            >
              <Card className="shadow-sm">
                <CardHeader className="border-0 d-flex justify-content-between align-items-center">
                  <h3 className="mb-0">Ask Questions</h3>
                  <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                    <DropdownToggle color="primary" caret>
                      {selectedDropdown == true
                        ? "Answered"
                        : selectedDropdown == "All"
                        ? " All Questions"
                        : "Unanswered"}
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={() => handleDropdownSelect("All")}>
                        All Questions
                      </DropdownItem>
                      <DropdownItem onClick={() => handleDropdownSelect(true)}>
                        Answered
                      </DropdownItem>
                      <DropdownItem onClick={() => handleDropdownSelect(false)}>
                        Unanswered
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead">
                    <tr>
                      <th scope="col">What is the issue?</th>
                      <th scope="col">Product name</th>
                      <th scope="col">Status</th>
                      <th scope="col" className="text-right">
                        Answer here
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedAskQuestions &&
                      selectedAskQuestions?.map((item) => {
                        return (
                          <>
                            <tr>
                              {" "}
                              <td>{item?.issue}</td>
                              <td>{item?.itemName}</td>
                              <td>
                                {item?.status == true
                                  ? "answered"
                                  : "unanswered"}
                              </td>
                              <td className="text-right">
                                <Button
                                  size="sm"
                                  color={`${
                                    item?.status == true ? "success" : "warning"
                                  }`}
                                  onClick={() => {
                                    answerToggle();
                                    setSelectedId(item?.id);
                                    setAnswer(item?.answer ? item?.answer : "");
                                  }}
                                >
                                  {item?.status == true
                                    ? "Update Answer"
                                    : "Add Answer"}
                                </Button>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                  </tbody>
                </Table>
              </Card>
            </LoadingOverlay>
          </div>
        </Row>
        <Modal isOpen={answerModal} toggle={answerToggle}>
          <ModalHeader toggle={answerToggle}>Add Answer</ModalHeader>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <ModalBody>
              <Label>Answer</Label>
              <Input
                placeholder="Enter the answer..."
                required
                value={answer}
                onChange={(e) => {
                  setAnswer(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button color="primary" type="submit" disabled={addLoader}>
                {addLoader ? <Spinner size="md" /> : "Add"}
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      </Container>
    </>
  );
}
