import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Form,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

export default function Repository({ repository, setRepository }) {
  const [IsOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!IsOpen);
  };
  const [currentRepository, setCurrentRepository] = useState({
    title: "",
    url: "",
  });
  const [modal, setModal] = useState(false);
  const repositoryToggle = () => setModal(!modal);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCurrentRepository({ ...currentRepository, [name]: value });
  };
  const handleRemoveRepository = (index) => {
    const updatedCalculator = repository.filter((_, i) => i !== index);
    setRepository(updatedCalculator);
  };
  const handleAddRepository = () => {
    if (currentRepository.title && currentRepository.url) {
      setRepository([...repository, currentRepository]);
      setCurrentRepository({ title: "", url: "" });
      repositoryToggle();
    } else {
      alert("All fields are required");
    }
  };
  return (
    <div className="my-3 border border-2 p-2 shadow-sm">
      <Form className="my-2 form-2">
        <div
          className="d-flex justify-content-between align-items-center cursor"
          onClick={() => {
            toggle();
            setIsOpen(!IsOpen);
          }}
        >
          <h4 className="mb-0 pb-0 font-weight-bold">Repository</h4>
          <i
            className={`fas ${IsOpen ? "fa-chevron-up" : "fa-chevron-down"}`}
          ></i>
        </div>
        <Collapse isOpen={IsOpen}>
          <Row>
            {repository?.map((item, index) => {
              return (
                <Col md={6}>
                  <Card className="shadow-sm border">
                    <CardHeader className="m-0">
                      <div className="d-flex justify-content-between">
                        <h4 className="font-weight-bold">{item?.title}</h4>
                        <i
                          className="fas fa-times mt-1 cursor"
                          onClick={() => handleRemoveRepository(index)}
                        />
                      </div>
                    </CardHeader>
                    <CardBody>
                      <span>Url: {item?.url}</span>
                    </CardBody>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Collapse>
        <div
          className="d-flex alig-items-center cursor mt-3"
          onClick={() => {
            repositoryToggle();
          }}
        >
          <div>
            <i className="fas fa-plus cursor"></i>
          </div>
          <label className="mx-2 cursor">ADD REPOSITORY</label>
        </div>
        <Modal isOpen={modal} toggle={repositoryToggle}>
          <Form>
            <ModalHeader toggle={repositoryToggle}>Add Repository</ModalHeader>
            <ModalBody>
              <Input
                className="mb-1"
                name="title"
                type="text"
                placeholder="Title..."
                value={currentRepository.title}
                onChange={(e) => {
                  handleInputChange(e);
                }}
              />
              <Input
                className="mb-1"
                name="url"
                type="text"
                placeholder="Url..."
                value={currentRepository.url}
                onChange={(e) => {
                  handleInputChange(e);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button
                className="bg-primary text-secondary"
                onClick={() => {
                  handleAddRepository();
                }}
              >
                Add
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      </Form>
    </div>
  );
}
