import { useState } from "react";

const useArrayState = (initialArray) => {
  const [array, setArray] = useState(initialArray);

  const addItem = (item) => {
    setArray((prev) => [...prev, item]);
  };

  const removeItem = (index) => {
    if (index < 0 || index >= array.length) {
      return;
    }
    const updatedArray = array.filter((_, i) => i !== index);
    setArray(updatedArray);
  };

  const updateItem = (index, newItem) => {
    if (index < 0 || index >= array.length) {
      return;
    }
    const updatedArray = array.map((item, i) => (i === index ? newItem : item));
    setArray(updatedArray);
  };

  const clearArray = () => {
    setArray([]);
  };

  return {
    array,
    addItem,
    removeItem,
    updateItem,
    clearArray,
  };
};

export default useArrayState;
