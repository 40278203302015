import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import sampleCSV from "../assets/CSV/teamManagement.csv";
import csvtojson from "csvtojson";
import { useDispatch, useSelector } from "react-redux";
import { getCategories } from "store/actions/categoryAction";
import { createItem } from "store/actions/itemAction";
import { getCountries } from "store/actions/CountryAction";
import { sendInvite } from "store/actions/distributorAction";
import _ from "lodash";

const TeamCSVModal = ({ modal, toggle }) => {
  const dispatch = useDispatch();
  const { uid } = useSelector((state) => state.authUser);
  const { countries } = useSelector((state) => state.country);
  const seletionTypes = ["Upload File", "Download Sample File"];
  const [selectedItem, setSelectedItem] = useState("Upload File");
  const [isDownloading, setIsDownloading] = useState(false);
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  useEffect(() => {
    dispatch(getCountries());
  }, []);

  const downloadSampleCSV = () => {
    setIsDownloading(true);
    const downloadLink = document.createElement("a");
    downloadLink.href = sampleCSV;
    downloadLink.download = "teamManagement.csv";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    setIsDownloading(false);
  };
  const handleImport = (event) => {
    setIsDownloading(true);
    const file = event.target.files[0];
    const fileName = file.name;
    const fileExtension = fileName.split(".").pop().toLowerCase();

    if (fileExtension !== "csv") {
      setIsDownloading(false);
      alert("Invalid file format. Please select a CSV file.");
      return;
    }

    if (fileExtension === "csv") {
      const reader = new FileReader();
      reader.readAsText(file);

      reader.onload = async () => {
        try {
          const importedData = await csvtojson().fromString(reader.result);

          if (importedData?.length > 0) {
            const successfulItems = [];
            const unsuccessfulItems = [];
            const promises = importedData.map(async (data) => {
              const country = countries?.find(
                (countr) =>
                  countr?.label.toLowerCase() ===
                  data?.countryName.toLowerCase()
              );

              let validRoles = ["customer support", "finance", "logistics"];

              let role = validRoles.includes(data?.role)
                ? _.capitalize(data?.role)
                : "";

              const name = data?.name;
              const email = data?.email;

              if (!name || !email || !role || !country) {
                unsuccessfulItems.push(data?.email);
                return Promise.resolve();
              }

              let payload = {
                name: data.name,
                email: data.email,
                country: country,
                role: role,
                creatorId: uid,
              };

              return new Promise((resolve) => {
                dispatch(
                  sendInvite(payload, () => {
                    successfulItems.push(data?.email);
                    resolve();
                    toggle();
                  })
                );
              });
            });

            await Promise.all(promises);

            if (successfulItems.length > 0) {
              alert(`Invitation sent to ${successfulItems.join(", ")}`);
            }

            if (unsuccessfulItems.length > 0) {
              alert(`Invitation not sent to ${unsuccessfulItems.join(", ")}, Enter valid data`);
            }
            setIsDownloading(false);
            toggle();
          } else {
            setIsDownloading(false);
            alert("Nothing to import");
          }
        } catch (error) {
          setIsDownloading(false);
          console.error("Error parsing CSV:", error);
        }
      };
    }
  };

  return (
    <div>
      {" "}
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Upload Team</ModalHeader>
        <ModalBody className="py-0">
          <Row>
            {seletionTypes?.map((item, index) => {
              return (
                <Col
                  className={`text-center p-4 mr-2 ${
                    item == selectedItem ? "csv_modal-selected" : "csv_modal"
                  }`}
                  onClick={() => setSelectedItem(item)}
                >
                  <p>{item}</p>
                  {item == "Download Sample File" && (
                    <span
                      onClick={(e) => {
                        e.preventDefault();
                        downloadSampleCSV();
                      }}
                    >
                      {" "}
                      Download Sample
                    </span>
                  )}
                </Col>
              );
            })}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" size="sm" onClick={toggle}>
            Cancel
          </Button>

          <Button
            color="primary"
            size="sm"
            onClick={handleButtonClick}
            disabled={selectedItem !== "Upload File"}
          >
            {isDownloading ? <Spinner size="sm" className="mx-3" /> : "Upload"}
          </Button>
          <input
            type="file"
            ref={fileInputRef}
            accept=".csv"
            style={{ display: "none" }}
            onChange={handleImport}
          />
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default TeamCSVModal;
