export const getFieldType = (field) => {
  const fieldMap = {
    city: "text",
    line1: "text",
    postal_code: "text",
    state: "text",
    first_name: "text",
    last_name: "text",
    phone: "text",
    email: "email",
  };

  return fieldMap[field.split(".").at(-1)] || "text";
};
