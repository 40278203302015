// import { RepositoryFactory } from "../../repository/RepositoryFactory";
import firebase from "firebase";
// var country = RepositoryFactory.get("country");
export const getCountries = () => async (dispatch) => {
  try {
    const countriesDocs = await firebase
      .firestore()
      .collection("countries")
      .get();
    //  const { data } = await country.getCountry();
    //   console.log(data);
    // if (data?.error == false) {
    if (!countriesDocs.empty) {
      const formattedData = countriesDocs.docs.map((doc) => ({
        value: doc.data().iso3,
        label: doc.data().name,
        iso2: doc.data().iso2
      }));
      // console.log("countries", formattedData);
      dispatch({ type: "COUNTRIES", payload: formattedData });
    }
    //  }
  } catch (error) {
    console.log("", error);
  }
};
