import OnlyHeader from "components/Headers/OnlyHeader";
import firebase from "../store/firebase";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import {
  Card,
  CardHeader,
  Container,
  Row,
  Button,
  Table,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Spinner,
} from "reactstrap";
import { getOrderItems, upgradeStatus } from "../store/actions/orderAction";
import LoadingOverlay from "react-loading-overlay";
import { MdArrowBackIosNew } from "react-icons/md";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const OrderDetails = () => {
  const { id } = useParams();
  const history = useHistory();
  const { allOrderItems, getLoading, updateLoader } = useSelector(
    (state) => state.order
  );
  const { uid, user } = useSelector((state) => state.authUser);
  const dispatch = useDispatch();
  const status = ["confirmed", "dispatched", "delivered", "completed"];
  const [totalPrice, setTotalPrice] = useState(0);
  const [orderHistory, setOrderHistory] = useState([]);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const getOrderStatus = (latestStatusArray) => {
    const {
      confirmed = 0,
      dispatched = 0,
      delivered = 0,
    } = latestStatusArray.reduce((acc, status) => {
      acc[status.type] = (acc[status.type] || 0) + 1;
      return acc;
    }, {});
    const orderStatus =
      confirmed >= 1 && dispatched >= 1 && delivered >= 1
        ? "dispatched"
        : confirmed >= 1 && delivered >= 1
        ? "dispatched"
        : dispatched >= 1
        ? "dispatched"
        : delivered === latestStatusArray.length
        ? "delivered"
        : "confirmed";
    return orderStatus;
  };
  const handleNext = () => {
    const latestStatus = {};
    orderHistory.forEach((item) => {
      const { orderItemId, createdAt, type, message } = item;
      if (
        !latestStatus[orderItemId] ||
        createdAt.seconds > latestStatus[orderItemId].createdAt.seconds
      ) {
        latestStatus[orderItemId] = {
          orderItemId,
          createdAt,
          message,
          type,
        };
      }
    });
    const latestItemsStatus = Object.values(latestStatus);
    let obj = {
      orderId: allOrderItems?.order?.orderId,
      status: getOrderStatus(latestItemsStatus),
      history: orderHistory,
      updatedAt: firebase.firestore.Timestamp.now(),
      updatedItemStatus: latestItemsStatus,
    };
    dispatch(
      upgradeStatus(obj, () => {
        toggle();
        setOrderHistory([]);
      })
    );
  };
  const getStatusIndex = (statuses) => {
    return status.indexOf(statuses);
  };
  const getButtonLabel = () => {
    const newStatusObjects = orderHistory.filter((item) => {
      return !allOrderItems.order.history.some(
        (historyItem) =>
          historyItem.orderItemId === item.orderItemId &&
          historyItem.type === item.type
      );
    });
    if (newStatusObjects.length > 0) {
      const uniqueStatuses = new Set(newStatusObjects.map((item) => item.type));
      if (uniqueStatuses.size === 1) {
        const status = newStatusObjects[0].type;
        return `Move to ${status?.charAt(0).toUpperCase()}${status?.slice(1)}`;
      }
    }
    return "Move to Next";
  };
  // Update handleCheckboxChange function
  const handleCheckboxChange = (event, detail) => {
    const isChecked = event.target.checked;
    const statusIdx = getStatusIndex(detail.status);
    const newStatus = status[statusIdx + 1];

    const existingItemIndex = orderHistory.findIndex(
      (item) =>
        item.orderItemId === detail.trackingNo && item.type === newStatus
    );

    if (isChecked) {
      if (existingItemIndex === -1) {
        setOrderHistory([
          ...orderHistory,
          {
            orderItemId: detail.trackingNo,
            type: newStatus,
            message: `your item has been ${newStatus} for order`,
            createdAt: firebase.firestore.Timestamp.now(),
            upgraderId: uid,
          },
        ]);
      }
    } else {
      if (existingItemIndex !== -1) {
        const updatedHistory = [...orderHistory];
        updatedHistory.splice(existingItemIndex, 1);
        setOrderHistory(updatedHistory);
      }
    }
  };

  useEffect(() => {
    if (allOrderItems && allOrderItems.orderItems) {
      const calculatedTotal = allOrderItems.orderItems.reduce(
        (total, item) => total + (item.price * item.quantity || 0),
        0
      );
      setTotalPrice(calculatedTotal);
      setOrderHistory(allOrderItems.order.history);
    }
  }, [allOrderItems.orderItems]);
  useEffect(() => {
    // dispatch(getOrderItems(id, user.role, user.country.value));
    dispatch(getOrderItems(id));
  }, []);
  return (
    <>
      <OnlyHeader />
      <Container className="mt--7" fluid>
        <LoadingOverlay
          active={getLoading}
          spinner
          text="Order Detail Loading...."
        >
          <Row>
            <div className="col">
              <Card className="shadow-sm p-4">
              <h3 className="mb-2"><MdArrowBackIosNew
                        className="mx-2"
                        style={{ cursor: "pointer" }}
                        onClick={() => history.goBack()}
                      />Order Details</h3>
                <div className="progressbar-wrapper">
                  <ul className="progressbar">
                  {allOrderItems &&
                      status.map((statusItem, index) => {
                        const isActive =
                          status.indexOf(allOrderItems?.order?.status) >= index;

                        return (
                          <li
                            key={index}
                            className={`progressbar__step ${
                              isActive ? "progressbar__step--active" : ""
                            }`}
                          >
                            <div className="progressbar__step-content">
                              <span className="progressbar__step-number"></span>
                              <span className="text-progressbar-small progressbar__step-text mt-1 mb-sm-3 mb-0">
                                {statusItem}
                              </span>
                            </div>
                          </li>
                        );
                      })}
                  </ul>
                </div>
                <CardHeader className="border-0 d-flex justify-content-between align-items-center py-2 px-1">
                  {/* <Button className="" color="primary" type="button">
                  Add Distributor
                </Button> */}
                </CardHeader>
                <div className="shadow-lg border rounded row m-0 py-2">
                  {allOrderItems && allOrderItems.order && (
                    <>
                      <div className="col-sm-6 col-xs-12 px-5">
                        <span className="font-weight small">
                          Order#
                        </span>
                        <p className="font-weight-bold m-0">
                          {allOrderItems.order.orderId}
                        </p>
                      </div>
                      <div className="col-sm-6 col-xs-12 px-5">
                        <span className="font-weight small">
                          Customer name
                        </span>
                        <p className="font-weight-bold m-0">
                          {allOrderItems.order.customerName}
                        </p>
                      </div>
                      <div className="col-sm-6 col-xs-12 px-5">
                        <span className="font-weight small">
                          Order date
                        </span>
                        <p className="font-weight-bold m-0">
                          {moment
                            .unix(allOrderItems.order.createdAt.seconds)
                            .format("DD-MM-YYYY")}
                        </p>
                      </div>
                      <div className="col-sm-6 col-xs-12 px-5">
                        <span className="font-weight small">
                          Status
                        </span>
                        <p className="font-weight-bold m-0">
                          {allOrderItems.order.status}
                        </p>
                      </div>
                    </>
                  )}
                </div>

                <div className="shadow-lg border rounded m-0 mt-4 py-2">
                  <Button
                    className="float-right text-secondary my-2 status-moved"
                    disabled={
                      orderHistory?.length ==
                      allOrderItems?.order?.history.length
                        ? true
                        : false
                    }
                    onClick={toggle}
                  >
                    {getButtonLabel()}
                  </Button>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead">
                      <tr>
                        <th scope="col">Product Name</th>
                        <th scope="col">Upgrade Status</th>
                        <th scope="col">Qty</th>
                        <th scope="col">Status</th>
                        <th scope="col" className="text-right">
                          Amount
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {allOrderItems &&
                      allOrderItems.orderItems &&
                      allOrderItems.orderItems.length == 0 ? (
                        <tr>
                          <td></td>
                          <td></td>
                          <td className="fw--700">
                            Order items out of reached!
                          </td>
                          <td></td>
                        </tr>
                      ) : (
                        allOrderItems &&
                        allOrderItems.orderItems &&
                        allOrderItems.orderItems.map((detail, index) => {
                          return (
                            <tr key={index}>
                              <th scope="row" className="truncate">
                                <span className="mb-0 text-sm">
                                  {detail.name}
                                </span>
                              </th>
                              <td>
                                {!allOrderItems?.orderItems?.isRefunded ? (
                                  <input
                                    type="checkbox"
                                    className="cursor"
                                    onChange={(event) =>
                                      handleCheckboxChange(event, detail)
                                    }
                                    disabled={
                                      detail.status == "completed" ||
                                      detail.status == "delivered"
                                        ? true
                                        : false
                                    }
                                    checked={orderHistory.some(
                                      (item) =>
                                        item.orderItemId ===
                                          detail.trackingNo &&
                                        item.type ===
                                          status[
                                            getStatusIndex(detail.status) + 1
                                          ]
                                    )}
                                  />
                                ) : (
                                  "This order is refunded"
                                )}
                              </td>
                              <td>{detail.quantity}</td>
                              <td>{detail.status}</td>
                              <td className="text-right">${detail.price}</td>
                            </tr>
                          );
                        })
                      )}
                      <tr className="border-top">
                        <th scope="row">
                          <span className="mb-0 text-sm">Total</span>
                        </th>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="text-right">${totalPrice}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </Card>
            </div>
          </Row>
        </LoadingOverlay>
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>Confirmation Box</ModalHeader>
          <ModalBody>
            <h5 className="text-center ">
              Are you Sure for upgrade the items status?
            </h5>
            <div className="d-flex justify-content-center align-items-center my-3">
              <Button
                className="w-25 mx-2"
                color="primary"
                onClick={() => {
                  handleNext();
                }}
                disabled={updateLoader}
              >
                {updateLoader ? <Spinner size="sm" /> : "Yes"}
              </Button>
              <Button
                className="w-25"
                color="danger"
                onClick={toggle}
              >
                No
              </Button>
            </div>
          </ModalBody>
        </Modal>
      </Container>
    </>
  );
};

export default OrderDetails;
