import { Segmented } from "antd";
import React from "react";
import _ from "lodash";

export const TabSelection = ({ options, onChange }) => {
  return (
    <Segmented
    className="left-3"
      onChange={onChange}
      options={options.map((option) => ({
        label: (
          <div
            style={{
              padding: 4,
            }}
          >
            <div className="text-capitalize">{option}</div>
          </div>
        ),
        value: option,
      }))}
    />
  );
};

export default TabSelection;
