import React from "react";
import OnlyHeader from "components/Headers/OnlyHeader";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";

const Returns = () => {
  return (
    <>
      <OnlyHeader />
      <Container fluid className="mt--4">
        <Row>
          <Col>
            <Card className="shadow-sm">
              <CardHeader className="border-0 d-flex justify-content-between align-items-center">
                <h3 className="mb-0">Returns</h3>
              </CardHeader>

              <CardBody>
                <Row className="justify-content-center my-3">
                 <p>No Returns Yet. Looks like you're new here!</p> 
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Returns;
