const initialData = {
  getLoading: false,
  allOrders: [],
  lastDocument: {},
  active: true,
  allOrderItems: {},
  updateLoader: false,
};
const orderReducer = (state = initialData, action) => {
  switch (action.type) {
    case "GET_LOADER":
      return {
        ...state,
        getLoading: action.payload,
      };
    case "GET_ALL_ORDERS":
      return {
        ...state,
        allOrders: action.payload,
        active: action.active,
        lastDocument: action.lastDocument,
      };
    case "GET_ORDER_ITEMS":
      return {
        ...state,
        allOrderItems: action.payload,
      };
    case "UPDATE_LOADER":
      return {
        ...state,
        updateLoader: action.payload,
      };
    default:
      return state;
  }
};

export default orderReducer;
