import firebase from "../firebase";
import { RepositoryFactory } from "../../repository/RepositoryFactory";
var Tickets = RepositoryFactory.get("tickets");

export const getTickets =
  (sellerId, limit, clearState, lastDocument, category) =>
  async (dispatch, getState) => {
    dispatch(ticketsLoader(true));

    try {
      if (clearState) {
        dispatch({
          type: "TICKETS",
          payload: [],
          hasMoreData: true,
        });
      }
      const apiPayload = { sellerId, limit, lastDocument, category };
      const { data } = await Tickets.getAllTickets(apiPayload);

      if (data.success) {
        const previousData = getState().ticket.tickets || []; // Existing tickets in the store
        const newData = data.data || []; // New data from the API

        let payload = [];
        let hasMoreData = true;

        // Merge the new data with previous data
        payload = [...previousData, ...newData];

        // Determine if there's more data to load
        if (newData.length < limit) {
          hasMoreData = false;
        }

        dispatch(ticketsLoader(false));

        // Dispatch action to update tickets in the store
        dispatch({
          type: "TICKETS",
          payload: payload,
          hasMoreData: hasMoreData,
        });
      }
    } catch (error) {
      dispatch(ticketsLoader(false));
      console.log(error.message);
    }
  };

export const updateTicket =
  (payload, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      const { data } = await Tickets.UpdateTicket(payload);
      if (data?.success) {
        dispatch({
          type: "UDPATE_TICKET",
          payload: { id: payload?.ticketId, status: payload?.status },
        });
        onSuccess();
      }
    } catch (error) {
      console.log(error.message);
    }
  };
export const assignTicket = (payload) => async (dispatch) => {
  try {
    const { data } = await Tickets.UpdateTicket(payload);
    if (data?.success) {
      dispatch({
        type: "ASSIGN_TICKET",
        payload: { ID: payload?.ticketId, userID: payload?.assignTo },
      });
    }
  } catch (error) {
    console.log(error.message);
  }
};
export const getSingleTicket = (ID) => async (dispatch) => {
  dispatch(ticketsLoader(true));
  try {
    const { data } = await Tickets.getSingleTicket(ID);
    if (data?.success) {
      dispatch({
        type: "SINGLE_TICKET",
        payload: data.data,
      });
      dispatch(ticketsLoader(false));
    }
  } catch (error) {
    dispatch(ticketsLoader(false));
    console.log(error.message);
  }
};

export const ticketsLoader = (val) => async (dispatch) => {
  dispatch({
    type: "TICKETS_LOADING",
    payload: val,
  });
};

export const getTicketsCategories = (category) => async (dispatch) => {
  try {
    const categories = await Tickets.getAllTicketsCategories(category);
    // console.log("Categories", categories);

    if (category === "vendorTickets") {
      dispatch({
        type: "SET_VENDOR_TICKETS_CATEGORIES",
        payload: categories.data.data,
      });
    } else {
      dispatch({
        type: "SET_PLATFORM_TICKETS_CATEGORIES",
        payload: categories.data.data,
      });
    }
  } catch (error) {
    console.error("Error: No category found!", error);
  }
};

export const getAllTicketsIssue = (uid) => async (dispatch) => {
  try {
    const isuuedTickets = await Tickets.getAllTicketsIssued(uid);
    dispatch({
      type:"ISSUED_TICKETS",
      payload: isuuedTickets.data.data,
    })

  } catch (error) {
    console.error("Error: No category found!", error);
  }
};
