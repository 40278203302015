import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import sampleCSV from "../assets/CSV/multivendorSample.csv";
import csvtojson from "csvtojson";
import { useDispatch, useSelector } from "react-redux";
import { getCategories } from "store/actions/categoryAction";
import { createItem } from "store/actions/itemAction";
import { getColors } from "store/actions/itemAction";

const CSVModal = ({ modal, toggle }) => {
  const dispatch = useDispatch();
  const { uid } = useSelector((state) => state.authUser);
  const { allCategories } = useSelector((state) => state.category);
  const { colorList } = useSelector((state) => state.item);
  const seletionTypes = ["Upload File", "Download Sample File"];
  const [selectedItem, setSelectedItem] = useState("Upload File");
  const [isDownloading, setIsDownloading] = useState(false);
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    // Trigger click on the file input when the button is clicked
    fileInputRef.current.click();
  };
  useEffect(() => {
    dispatch(getCategories());
    dispatch(getColors())
  }, [dispatch]);

  // const handleFileChange = (event) => {
  //   // Handle the selected file here
  //   const selectedFile = event.target.files[0];
  //   console.log("Selected file:", selectedFile);
  // };
  const downloadSampleCSV = () => {
    setIsDownloading(true);
    const downloadLink = document.createElement("a");
    downloadLink.href = sampleCSV;
    downloadLink.download = "multivendorSample.csv";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    setIsDownloading(false);
  };
  const handleImport = (event) => {
    setIsDownloading(true);
    const file = event.target.files[0];
    const fileName = file.name;
    const fileExtension = fileName.split('.').pop().toLowerCase();

    if (fileExtension !== 'csv') {
      setIsDownloading(false);
      alert('Invalid file format. Please select a CSV file.');
      return;
    }
  
    if (fileExtension === "csv") {
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = async () => {
        try {
          const importedData = await csvtojson().fromString(reader.result);
          if (importedData?.length > 0) {
            const successfulItems = [];
            const unsuccessfulItems = [];
  
            const promises = importedData.map(async (data) => {

              const category = allCategories?.find(
                (cat) => cat.name.toLowerCase() === data?.category.toLowerCase()
              );
              const colorsArray = data?.colorName
              ? data.colorName
                  .split(',')
                  .map((colorName) => colorName.trim()) 
                  .filter((colorName) =>
                    colorList?.some((col) => col.label.toLowerCase() === colorName.toLowerCase())
                  )
                  .map((colorName) =>
                    colorList.find((col) => col.label.toLowerCase() === colorName.toLowerCase())
                  )
                  .filter((color) => color)
              : [];
            
  
              const ecoSystems = ['zigbee', 'z-wave', 'wi-fi'];
              const ecosystem = ecoSystems.includes(data?.ecosystem?.toLowerCase());
  
              const powers = ['plug', 'battery', 'poe'];
              const power = powers.includes(data?.power?.toLowerCase());
              const name = data?.name
              const description = data?.description

              if (!name || !description || !category || !ecosystem || !power || isNaN(data?.price) || colorsArray.length === 0) {
                unsuccessfulItems.push(data.name);
                return Promise.resolve();
              }

              let detail = {
                color: colorsArray,
                name: data?.name,
                price: Number(data?.price),
                description: data?.description,
                power: data?.power,
                ecoSystem: data?.ecosystem,
                categoryId: category?.id,
              };
  
              let item = {
                detail,
                creatorId: uid,
                isDeleted:false,
              };
  
              return new Promise((resolve) => {
                dispatch(
                  createItem(item, () => {
                    successfulItems.push(data.name);
                    resolve();
                  })
                );
              });
            });
            
            await Promise.all(promises);
            
            if (successfulItems.length > 0) {
              alert(`Item ${successfulItems.join(', ')} added successfully.`);
            }
            
            if (unsuccessfulItems.length > 0) {
              alert(`Item ${unsuccessfulItems.join(', ')} not added. Add valid data`);
            }
            setIsDownloading(false);
            toggle();
          } else {
            setIsDownloading(false);
            alert("Nothing to import");
          }
        } catch (error) {
          setIsDownloading(false);
          alert(error);
        }
      };
    }
  };

  return (
    <div>
      {" "}
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Upload Items</ModalHeader>
        <ModalBody className="py-0">
          <Row>
            {seletionTypes?.map((item, index) => {
              return (
                <Col
                  className={`text-center p-4 mr-2 ${
                    item == selectedItem ? "csv_modal-selected" : "csv_modal"
                  }`}
                  onClick={() => setSelectedItem(item)}
                >
                  <p>{item}</p>
                  {item == "Download Sample File" && (
                    <span
                      onClick={(e) => {
                        e.preventDefault();
                        downloadSampleCSV();
                      }}
                    >
                      {" "}
                      Download Sample
                    </span>
                   
                  )}
                </Col>
              );
            })}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" size="sm" onClick={toggle}>
            Cancel
          </Button>

          <Button
            color="primary"
            size="sm"
            onClick={handleButtonClick}
            disabled={selectedItem !== "Upload File"}
          >
            {isDownloading ? <Spinner size="sm" className="mx-3" /> : "Upload"}
          </Button>
          <input
            type="file"
            ref={fileInputRef}
            accept=".csv"
            style={{ display: "none" }}
            onChange={handleImport}
          />
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default CSVModal;
