
import { RepositoryFactory } from "../../repository/RepositoryFactory";
var Tickets = RepositoryFactory.get("tickets");

export const getSupportTicket = (uid, ticketId) => async (dispatch) => {
    // dispatch(ticketsLoader(true));
    try {
      const { data } = await Tickets.getAllTicketsIssued(uid);
      if (data?.success) {
          const supportTicket = data.data.filter(ticket => ticket.id === ticketId);
        dispatch({
          type: "SUPPORT_TICKET",
          payload: {...supportTicket[0]},
        });
        // dispatch(ticketsLoader(false));
      }
    } catch (error) {
    //   dispatch(ticketsLoader(false));
      console.log(error.message);
    }
  };
  