import firebase from "../firebase";
// export const askQuestions = (uid) => async (dispatch) => {
//   await dispatch(Loader(true));
//   try {
//     await firebase
//       .firestore()
//       .collection("askQuestions")
//       .where("sellerId", "==", uid)
//       .onSnapshot(async (data) => {
//         let tempData = [];
//         for (let doc of data.docs) {
//           let id = doc.id;
//           let data1 = doc.data();
//           tempData.push({ id: id, ...data1 });
//         }
//         dispatch({ type: "ALL_ASKQUESTIONS", payload: tempData });
//         dispatch(Loader(false));
//       });
//   } catch (error) {
//     console.log("", error);
//     dispatch(Loader(false));
//   }
// };
export const askQuestions = (uid) => async (dispatch) => {
  await dispatch(Loader(true));
  try {
    // Query the "askQuestions" collection
    await firebase
      .firestore()
      .collection("askQuestions")
      .where("sellerId", "==", uid)
      .onSnapshot(async (data) => {
        let tempData = [];

        // Define a function to fetch item data based on productId
        const fetchItemData = async (productId) => {
          const itemSnapshot = await firebase
            .firestore()
            .collection("items")
            .doc(productId)
            .get();

          if (itemSnapshot.exists) {
            return itemSnapshot.data();
          }
          return null; // Return null if the item is not found
        };

        for (let doc of data.docs) {
          let id = doc.id;
          let data1 = doc.data();

          // Fetch the item data for the productId
          const itemData = await fetchItemData(data1.productId);
          console.log(itemData,'ítemData')
          if (itemData) {
            // Add the item name to the data
            data1.itemName = itemData?.detail?.name;
          }

          tempData.push({ id: id, ...data1 });
        }

        dispatch({ type: "ALL_ASKQUESTIONS", payload: tempData });
        dispatch(Loader(false));
      });
  } catch (error) {
    console.log("", error);
    dispatch(Loader(false));
  }
};

export const addAnswer = (answer, id, onSuccess) => async (dispatch) => {
  await dispatch(addLoader(true));
  try {
    await firebase
      .firestore()
      .collection("askQuestions")
      .doc(id)
      .update({ status: true, answer: answer });
    alert("Answer Added Successfully!");
    dispatch(addLoader(false));
    onSuccess();
  } catch (error) {
    console.log("", error);
    dispatch(addLoader(false));
  }
};
const Loader = (data) => async (dispatch) => {
  dispatch({
    type: "LOADER",
    payload: data,
  });
};
const addLoader = (data) => async (dispatch) => {
  dispatch({
    type: "ADD_LOADER",
    payload: data,
  });
};
