/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "routes.js";
// import { useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
// import { addUserAction } from "store/actions/authAction";
// import { logout } from "store/actions/authAction";
// import jwt from "jsonwebtoken";
const Admin = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();
  // const dispatch = useDispatch();
  // const history = useHistory();
  // const { hash, inviteVerification, logAuth } = useSelector(
  //   (state) => state.authUser
  // );
  // useEffect(() => {
  //   async function getCookieValue(cookieName) {
  //     const cookies = document.cookie.split("; ");
  //     for (let i = 0; i < cookies.length; i++) {
  //       const cookie = cookies[i];
  //       const [name, value] = cookie.split("=");
  //       if (name === cookieName) {
  //         return decodeURIComponent(value);
  //       }
  //     }
  //     return null;
  //   }
  //   const decodeToken = async () => {
  //     try {
  //       await getCookieValue("cbo_short_session").then(
  //         async (cboShortSessionValue) => {
  //           if (cboShortSessionValue) {
  //             const decoded = jwt.decode(cboShortSessionValue);
  //             if (decoded) {
  //               let userDetail = {};
  //               if (hash === "seller") {
  //                 userDetail = {
  //                   email: decoded?.email,
  //                   username: decoded.name,
  //                   role: "seller",
  //                   organization: "",
  //                   active: true,
  //                 };
  //               } else if (hash === "distributor") {
  //                 userDetail = {
  //                   email: decoded?.email,
  //                   username: decoded.name,
  //                   role: "distributor",
  //                   active: true,
  //                 };
  //               } else if (hash === "distributor-member") {
  //                 userDetail = {
  //                   email: decoded?.email,
  //                   username: decoded.name,
  //                   role: "distributor-member",
  //                   active: true,
  //                 };
  //               }
  //               if (!logAuth) {
  //                 console.log("userDetail: ", JSON.stringify(userDetail));
  //                 await dispatch(
  //                   addUserAction(userDetail, inviteVerification, history)
  //                 );
  //               }
  //             }
  //           } else {
  //             dispatch(logout(history));
  //           }
  //         }
  //       );
  //     } catch (error) {
  //       console.error("JWT decoding failed:", error);
  //     }
  //   };

  //   decodeToken();
  // }, []);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path) => {
    const matchingRoute = routes.find((route) => {
      // Check if the route has "/:id" in its path
      if (route.path.includes("/:id")) {
        return true;
      }

      // Check if the combination of layout and path exists in the URL
      if (props.location.pathname.indexOf(route.layout + route.path) !== -1) {
        return true;
      }
      return false;
    });

    // If a matching route is found, return its name; otherwise, return "Brand"
    return matchingRoute ? matchingRoute.name : "Brand";
  };

  return (
    <>
      <Sidebar
        {...props}
        routes={routes}
        logo={{
          innerLink: "/admin/index",
          imgSrc: require("../assets/img/brand/argon-react.png").default,
          imgAlt: "...",
        }}
      />
      <div className="main-content" ref={mainContent}>
        <AdminNavbar
          {...props}
          brandText={getBrandText(props.location.pathname)}
        />
        <Switch>
          {getRoutes(routes)}
          <Redirect from="*" to="/admin/index" />
        </Switch>
      </div>
    </>
  );
};

export default Admin;
