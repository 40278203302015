import OnlyHeader from "components/Headers/OnlyHeader";
import DistributorModel from "../components/Models/DistributorModel";
import ImageModel from "components/Models/ImageModel";
import React, { useState } from "react";
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getUsersWithRole,
  suspendUser,
} from "../store/actions/distributorAction";
import LoadingOverlay from "react-loading-overlay";
import { useLocation } from "react-router-dom";
import { Switch } from "@mui/material";
import { getCountries } from "../store/actions/CountryAction";
import { role } from "constant/teamRoles";
const Distributors = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { uid, user } = useSelector((state) => state.authUser);
  const { usersWithRole, getLoading, addLoading } = useSelector(
    (state) => state.distributor
  );
  const [suspendStatus, setSuspendStatus] = useState(false);
  const [modal, setModal] = useState(false);
  const [imgModal, setImgModal] = useState(false);
  const [type, setType] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [distributorDetail, setDistributorDetail] = useState({
    name: "",
    email: "",
    organization: "",
    country: { value: "", label: "" },
  });
  const toggle = () => setModal(!modal);
  const imagesToggle = () => setImgModal(!imgModal);
  const handleSuspendStatusUpdate = (e, id) => {
    setSuspendStatus(e.target.checked);
    dispatch(suspendUser(e.target.checked, id));
  };

  const handleSearch = () => {
    const filteredData = usersWithRole.filter((user) => {
      const searchTermLower = searchTerm.toLowerCase();

      const anyMatch =
        Object.values(user).some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase().includes(searchTermLower)
        ) ||
        (user.country.label &&
          user.country.label.toLowerCase().includes(searchTermLower));

      return anyMatch;
    });

    setFilteredUsers(filteredData);
  };

  useEffect(() => {
    let id = user.role === role.LOGISTICS ? user.creatorId : uid;
    dispatch(
      getUsersWithRole(
        id,
        location?.pathname == "/admin/distributors"
          ? "distributor"
          : "distributor-member"
      )
    );
  }, [user]);

  useEffect(() => {
    handleSearch();
  }, [searchTerm, usersWithRole]);
  return (
    <>
      <OnlyHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <LoadingOverlay
              active={getLoading}
              spinner
              text={
                location?.pathname == "/admin/distributors"
                  ? "Distributor loading..."
                  : "Team member loading..."
              }
            >
              <Card className="shadow-sm">
                <CardHeader className="border-0 d-lg-flex d-md-flex d-sm-flex justify-content-between  align-items-center">
                  <h3 className="mb-2">
                    {location?.pathname == "/admin/distributors"
                      ? "Distributors"
                      : "Team member"}
                  </h3>
                  <div
                    className="position-relative"
                    style={{ maxWidth: "200px" }}
                  >
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-search" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        type="text"
                        placeholder="Search"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </InputGroup>
                  </div>
                  <Button
                    color="primary"
                    type="button"
                    onClick={() => {
                      toggle();
                      dispatch(getCountries());
                      setType(
                        location?.pathname == "/admin/distributors"
                          ? "Distributor"
                          : "Team member"
                      );
                    }}
                  >
                    Add{" "}
                    {location?.pathname == "/admin/distributors"
                      ? "Distributor"
                      : "Team member"}
                  </Button>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead">
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Organization</th>
                      <th scope="col">Country</th>
                      <th scope="col">Status</th>
                      <th scope="col" className="text-right">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  {usersWithRole?.length == 0 ? (
                    <div className=" mt-2">
                      <p className="mx-4">Yet Data not found!</p>
                    </div>
                  ) : (
                    <tbody>
                      {(searchTerm === "" ? usersWithRole : filteredUsers).map(
                        (item) => (
                          <tr key={item.id}>
                            <th scope="row">
                              <span className="mb-0 text-sm">
                                {item?.username}
                              </span>
                            </th>
                            <td>{item?.email}</td>
                            <td>{item?.organization}</td>
                            <td>{item?.country?.label}</td>
                            <td>
                              {item?.active == true ? "Active" : "Suspended"}
                            </td>
                            <td className="text-right">
                              {/* <Button
color="danger"
onClick={() => {
confirmtoggle();
setSelectedId(item?.id);
}}
className="cursor-pointer"
size="sm"
disabled={
item?.active == false ? true : false
}
>
Suspend
</Button> */}
                              <Switch
                                value={suspendStatus}
                                defaultChecked={item?.active}
                                onChange={(e) => {
                                  handleSuspendStatusUpdate(e, item?.id);
                                }}
                              />
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  )}
                </Table>
              </Card>
            </LoadingOverlay>
          </div>
        </Row>
      </Container>
      {/* <Modal isOpen={confirmModal} toggle={confirmtoggle}>
        <ModalHeader toggle={confirmtoggle}>Confirmation Box</ModalHeader>
        <ModalBody>
          <h5 className="text-center ">
            Are you sure you want to suspend this{" "}
            {location?.pathname == "distributors"
              ? "distributor"
              : "team member"}
            ?
          </h5>
          <div className="d-flex justify-content-center align-items-center my-3">
            <Button
              className="bg-primary text-white w-25 mx-2"
              disabled={addLoading}
              onClick={() => {
                dispatch(
                  suspendUser(selectedId, () => {
                    confirmtoggle();
                  })
                );
              }}
            >
              {addLoading ? <Spinner size="md" /> : "Yes"}
            </Button>
            <Button
              className="bg-danger text-white w-25"
              onClick={confirmtoggle}
            >
              No
            </Button>
          </div>
        </ModalBody>
      </Modal> */}
      <DistributorModel
        isOpen={modal}
        toggle={toggle}
        type={type}
        distributorDetail={distributorDetail}
        setDistributorDetail={setDistributorDetail}
      />
      <ImageModel isOpen={imgModal} toggle={imagesToggle} />
    </>
  );
};

export default Distributors;
