const initialData = {
  stats: {},
  monthlyPerformance: [],
  logisticsPerfomance: [],
  dashboardLoading: false,
  logisticsLoading: false,
  sellerYears: -1,
};
const itemReducer = (state = initialData, action) => {
  switch (action.type) {
    case "DASHBOARD_STATS":
      return {
        ...state,
        stats: action.payload,
      };
    case "EARNINGS":
      return {
        ...state,
        monthlyPerformance: action.payload,
      };
    case "LOGISTICS":
      return {
        ...state,
        logisticsPerfomance: action.payload,
      };
    case "SELLER_YEARS":
      return {
        ...state,
        sellerYears: action.payload,
      };
    case "DASHBOARD_LOADING":
      return {
        ...state,
        dashboardLoading: action.payload,
      };
    case "LOGISTICS_LOADING":
      return {
        ...state,
        logisticsLoading: action.payload,
      };
    default:
      return state;
  }
};

export default itemReducer;
