import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  FormGroup,
  Form,
  Input,
  Spinner,
} from "reactstrap";
import { useEffect } from "react";
import { getPromotionItems } from "../../store/actions/itemAction";
import { useDispatch, useSelector } from "react-redux";
import {
  addPromotion,
  updatePromotion,
} from "../../store/actions/promotionAction";

const PromotionModel = ({
  isOpen,
  toggle,
  type,
  promotionDetail,
  setPromotionDetail,
  initialState,
}) => {
  const { uid } = useSelector((state) => state.authUser);
  const dispatch = useDispatch();
  const { promotionItems } = useSelector((state) => state.item);
  const { allPromotions } = useSelector((state) => state.promotion);
  const { addLoading } = useSelector((state) => state.promotion);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "startDate" || name === "endDate") {
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      const selectedDate = new Date(value);

      if (name === "startDate") {
        selectedDate.setHours(0, 0, 0, 1);
      } else if (name === "endDate") {
        selectedDate.setHours(23, 59, 59, 999);
      }

      if (selectedDate < currentDate) {
        alert("Date should be today or a future date.");
        return;
      }

      setPromotionDetail({ ...promotionDetail, [name]: selectedDate });
    } else if (name === "type") {
      setPromotionDetail({
        ...promotionDetail,
        [name]: value,
      });
    } else if (name === "amount") {
      if (promotionDetail.type === "Fixed") {
        const itemPrice =
          promotionItems.find((item) => item.id === promotionDetail.item)
            ?.detail.price || 0;
        const maxAllowedAmount = itemPrice * 0.95;
        const minAllowedAmount = 1;

        if (parseFloat(value) > maxAllowedAmount) {
          alert("Amount should not be greater than 95% of the item price ");
          return;
        }
        if (parseFloat(value) < minAllowedAmount) {
          alert("Amount should be atleast 1% of the item price");
          return;
        }
      }

      if (promotionDetail.type === "Percentage") {
        const maxAllowedPercentage = 95;
        const minAllowedPercentage = 1;

        if (parseFloat(value) > maxAllowedPercentage) {
          alert("Percentage should not be greater than 95%.");
          return;
        }
        if (parseFloat(value) < minAllowedPercentage) {
          alert("Percentage should be atleast 1%");
          return;
        }
      }
      setPromotionDetail({ ...promotionDetail, [name]: value });
    } else {
      setPromotionDetail({ ...promotionDetail, [name]: value });
    }
  };

  const handleItemChange = (e) => {
    const selectedItemId = e.target.value;
    const selectedItem = promotionItems.find(
      (item) => item.id === selectedItemId
    );
    if (selectedItem) {
      setPromotionDetail({
        ...promotionDetail,
        item: selectedItem.id,
      });
    }
  };

  const availableItems = promotionItems.filter(
    (item) => !allPromotions.some((promotion) => promotion?.item === item?.id)
  );

  useEffect(() => {
    dispatch(getPromotionItems(uid));
  }, []);

  return (
    <>
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle} className="border-bottom">
          <h3 className="mb-0">{type} Promotion</h3>
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            if (type == "Add") {
              dispatch(
                addPromotion(promotionDetail, () => {
                  toggle();
                  setPromotionDetail(initialState);
                })
              );
            } else {
              const { createdAt, id, ...updatedPromotions } = promotionDetail;
              dispatch(
                updatePromotion(updatedPromotions, id, () => {
                  toggle();
                })
              );
            }
          }}
        >
          <ModalBody>
            <FormGroup className="mb-2">
              <Label for="exampleName">Title</Label>
              <Input
                id="exampleName"
                name="title"
                placeholder="Enter Item name"
                type="text"
                value={promotionDetail.title}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup className="mb-2">
              <Label for="selectType">Type</Label>
              <Input
                id="selectType"
                name="type"
                type="select"
                value={promotionDetail.type}
                onChange={handleInputChange}
                required
              >
                <option disabled>Type of promotion</option>
                <option>Fixed</option>
                <option>Percentage</option>
              </Input>
            </FormGroup>
            <FormGroup className="mb-2">
              <Label for="exampleItem">Item</Label>
              <Input
                id="exampleItem"
                name="item"
                type="select"
                value={promotionDetail.item}
                onChange={handleItemChange}
                required
              >
                <option value={""} selected disabled>
                  Select item...
                </option>
                {availableItems.map((item) => (
                  <option key={item?.id} value={item?.id}>
                    {item?.detail?.name.length > 50
                      ? `${item?.detail?.name.substring(0, 50)}...`
                      : item?.detail?.name}
                  </option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup className="mb-2">
              <Label for="exampleAmount">Amount</Label>
              <Input
                id="exampleAmount"
                name="amount"
                placeholder="Enter Item Amount"
                type="number"
                value={promotionDetail.amount}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup className="mb-2">
              <Label for="startDate">Start Date</Label>
              <Input
                id="startDate"
                name="startDate"
                placeholder="Start promotion"
                type="date"
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup className="mb-2">
              <Label for="EndDate">End Date</Label>
              <Input
                id="EndDate"
                name="endDate"
                placeholder="End promotion"
                type="date"
                onChange={handleInputChange}
                required
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              className="px-5 d-flex mx-auto"
              type="submit"
            >
              {addLoading ? <Spinner size="md" /> : "Submit"}
            </Button>{" "}
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default PromotionModel;
