const initialState = {
  user: null,
  uid: null,
  hash: "seller",
  inviteVerification: "",
  loading: false,
  updateLoader: false,
  logAuth: false,
  hashGaurd: false,
  fileUploadingProgress: -1,
  newOrgName: "",
  reasonForModif: "",
  anyUpdateRequest: false,
};

const authUser = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        user: action.payload,
        uid: action.payload.docId,
      };
    case "UPDATE_USER_WEEKEND":
      return {
        ...state,
        user: { ...state.user, weekends: action.payload },
      };
    case "LOG_AUTH":
      return {
        ...state,
        logAuth: action.payload,
      };
    case "LOG_AUTH_CLEAR":
      return {
        ...state,
        logAuth: action.payload,
      };
    case "UPDATEUSER":
      return {
        ...state,
        user: action.payload,
      };
    case "UPLOADING_PROGRESS":
      return {
        ...state,
        fileUploadingProgress: action.payload,
      };
    case "UPDATE_LOADER":
      return {
        ...state,
        updateLoader: action.payload,
      };
    case "LOGOUT":
      localStorage.removeItem("auth");
      return {
        ...state,
        user: null,
        uid: null,
        inviteVerification: "",
        hash: "seller",
        logAuth: false,
        hashGaurd: false,
      };
    case "HASH":
      return {
        ...state,
        hash: action.payload,
      };
    case "HASH_GAURD":
      return {
        ...state,
        hashGaurd: action.payload,
      };
    case "ANY_UPDATE_REQUEST":
      return {
        ...state,
        anyUpdateRequest: action.payload,
      };

      case "GET_NEW_WEEK_DATA":
        return {
          ...state,
          anyUpdateRequest: action.payload,
        };

    case "INVITE_VERIFIED":
      return {
        ...state,
        inviteVerification: action.payload,
      };
    case "LOADER":
      return {
        ...state,
        loading: action.payload,
      };
    case "ADD_NEW_ORG_NAME":
      return {
        ...state,
        newOrgName: action.payload,
      };

    case "GET_NEW_ORG_DATA":
      return {
        ...state,
        orgData: action.payload,
      };

    case "ADD_NEW_WEEK_NAME":
      return {
        ...state,
        newOrgName: action.payload,
      };

    case "GET_NEW_WEEK_DATA":
      return {
        ...state,
        orgData: action.payload,
      };

    case "UPDATE_ORG":
      return {
        ...state,
        reasonForModif: action.payload,
      };
    case "RESET_MODAL_FIELDS":
      return {
        ...state,
        newOrgName: "",
        reasonForModif: "",
      };
    default:
      return state;
  }
};

export default authUser;
