import { Avatar } from "@mui/material";
import ChatBody from "components/Chat/ChatBody";
import ChatFooter from "components/Chat/ChatFooter";
import ChatHeader from "components/Chat/ChatHeader";
import Conservations from "components/Chat/Conservations";
import SmallHeader from "components/Headers/SmallHeader";
import useArrayState from "hooks/useArrayState";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Col, Container, Input, Row } from "reactstrap";

const CustomerSupport = () => {
  const { user } = useSelector((state) => state.authUser);

  let { array: chats, addItem: addChats } = useArrayState(initialChats);

  const [activeChat, setActiveChat] = useState(0);

  const handleSendClick = (newMessage) => {
    if (newMessage.trim() === "") return;
    addChats({ text: newMessage, position: "right" });
  };

  let leftAvatar =
    "https://yt3.googleusercontent.com/-CFTJHU7fEWb7BYEb6Jh9gm1EpetvVGQqtof0Rbh-VQRIznYYKJxCaqv_9HeBcmJmIsp2vOO9JU=s900-c-k-c0x00ffffff-no-rj";
  return (
    <>
      <SmallHeader />
      <Container fluid className="mt-4">
        <div className="d-flex w-100">
          <Conservations
            conservations={conservations}
            activeChatIndex={activeChat}
            onChangeActive={(newIndex) => setActiveChat(newIndex)}
          />
          <div className="w-100 overflow-hidden">
            {" "}
            <ChatHeader profileImage="" name="John Doe" />
            <ChatBody
              messages={chats}
              leftAvatar={leftAvatar}
              rightAvatar={user?.logo}
            />
            <ChatFooter
              onSendClick={(newMessage) => handleSendClick(newMessage)}
            />
          </div>
        </div>
      </Container>
    </>
  );
};

export default CustomerSupport;
const conservations = [
  {
    profilePic:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c2/Nina_Dobrev_cropped_2010.jpg/205px-Nina_Dobrev_cropped_2010.jpg",
    name: "Elena Gilbert",
    isUnread: true,
    text: "Thanks for Contacting Us!.",
  },
  {
    profilePic:
      "https://rukminim2.flixcart.com/image/850/1000/ky90scw0/poster/2/v/e/medium-ian-somerhalder-damon-salvatore-the-vampire-diaries-matte-original-imagagtxdq8vxpph.jpeg?q=20",
    name: "Demon Salvatore",
    isUnread: true,
    text: "We will Back to you soon.",
  },
  {
    profilePic:
      "https://i1.sndcdn.com/avatars-000431353920-112uj9-t500x500.jpg",
    name: "Raheel Afzal",
    isUnread: true,
    text: "Hey John, What is the status of my Order",
  },
];
const initialChats = [
  {
    text: "Hi there! I'm having trouble accessing my account. It's showing an error when I try to log in.",
    position: "left",
  },
  {
    text: "Hello! I'm sorry to hear that you're having issues logging in. Let me assist you with that. Could you please provide me with your username or email associated with your account?",
    position: "right",
  },
  { text: "Sure, my username is 'example_user123'.", position: "left" },
  {
    text: "Thank you for providing that information. Let me check on this for you. Please bear with me for a moment while I investigate the issue.",
    position: "right",
  },
  {
    text: "It appears that there might be a temporary technical glitch on our end causing this issue. I'll take immediate action to resolve this for you. Could you try clearing your browser cache and cookies or attempt to log in using a different browser in the meantime?",
    position: "right",
  },
  { text: "Okay, I'll try that now.", position: "left" },
  { text: "it will take just some minutes", position: "left" },
  {
    text: "It worked! I'm able to access my account now. Thank you for your help!",
    position: "left",
  },
  {
    text: "Great to hear that the issue is resolved! If you encounter any further problems or have any other questions, feel free to reach out. Is there anything else I can assist you with today?",
    position: "right",
  },
  {
    text: "No, that's all for now. Thank you again for your prompt assistance!",
    position: "left",
  },
  {
    text: "You're welcome! Have a wonderful day ahead, and thank you for contacting us. If you need any help in the future, don't hesitate to contact us again.",
    position: "right",
  },
];
