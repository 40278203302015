import OnlyHeader from "components/Headers/OnlyHeader";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import _ from "lodash";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Button,
  Badge,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  CardBody,
  Spinner,
} from "reactstrap";
import moment from "moment";
import { getOrders, refundOrderAction } from "../store/actions/orderAction";
import LoadingOverlay from "react-loading-overlay";

const Orders = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { allOrders, getLoading, active, lastDocument, updateLoader } =
    useSelector((state) => state.order);
  const { user, uid } = useSelector((state) => state.authUser);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedOrderDropdown, setSelectedOrderDropdown] =
    useState("All orders");
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [refundedModal, setRefundedModal] = useState(false);
  const [lastDocuments, setLastDocuments] = useState("");
  const [paymentId, setPaymentId] = useState("");
  const refundedToggle = () => setRefundedModal(!refundedModal);
  const toggle = () => {
    setDropdownOpen((prevState) => !prevState);
  };
  const handleDropdownSelect = (value) => {
    setSelectedOrderDropdown(value);
  };
  // const ordersDetail = [
  //   {
  //     orderId: 1001,
  //     date: "16-09-2023",
  //     customerName: "David",
  //     status: "completed",
  //   },
  //   {
  //     orderId: 1002,
  //     date: "16-09-2023",
  //     customerName: "David",
  //     status: "completed",
  //   },
  //   {
  //     orderId: 1003,
  //     date: "16-09-2023",
  //     customerName: "David",
  //     status: "refunded",
  //   },
  //   {
  //     orderId: 1004,
  //     date: "16-09-2023",
  //     customerName: "David",
  //     status: "refunded",
  //   },
  //   {
  //     orderId: 1005,
  //     date: "16-09-2023",
  //     customerName: "David",
  //     status: "completed",
  //   },
  // ];
  useEffect(() => {
    setLastDocuments(lastDocument);
  }, [lastDocument]);

  const handleLoadMore = () => {
    let sellerId = "";
    if (user?.role == "seller") {
      sellerId = uid;
    } else if (user?.role == "distributor-member") {
      sellerId = user?.sellerId;
    } else {
      sellerId = user?.creatorId;
    }
    dispatch(getOrders(lastDocuments, sellerId, user.role, user.country.label));
  };

  useEffect(() => {
    const filteredOrders = allOrders.filter((item) => {
      return (
        selectedOrderDropdown === "All orders" ||
        item.status === selectedOrderDropdown
      );
    });
    setSelectedOrders(filteredOrders);
  }, [allOrders, selectedOrderDropdown]);

  useEffect(() => {
    let sellerId = "";
    if (user?.role == "seller") {
      sellerId = uid;
    } else if (user?.role == "distributor-member") {
      sellerId = user?.sellerId;
    } else {
      sellerId = user?.creatorId;
    }
    dispatch(getOrders(null, sellerId, user.role, user.country.label));
  }, []);
  return (
    <>
      <OnlyHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <LoadingOverlay
              active={getLoading}
              spinner
              text="Orders Loading...."
            >
              <Card className="shadow-sm">
                <CardHeader className="border-0 d-flex justify-content-between align-items-center">
                  <h3 className="mb-0">Orders</h3>
                  <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                    <DropdownToggle color="primary" caret>
                      {_.capitalize(selectedOrderDropdown)}
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        onClick={() => handleDropdownSelect("All orders")}
                      >
                        All Orders
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => handleDropdownSelect("confirmed")}
                      >
                        Confirmed
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => handleDropdownSelect("dispatched")}
                      >
                        Dispatched
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => handleDropdownSelect("delivered")}
                      >
                        Delivered
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => handleDropdownSelect("completed")}
                      >
                        Completed
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </CardHeader>
                <CardBody>
                  <Table className="align-items-center table-flush " responsive>
                    <thead className="thead">
                      <tr>
                        <th scope="col">Order id</th>
                        <th scope="col">Order date</th>
                        <th scope="col">Customer name</th>
                        <th scope="col">Status</th>
                        <th scope="col" className="text-right">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedOrders &&
                        selectedOrders?.map((item) => {
                          return (
                            <>
                              <tr>
                                {" "}
                                <td>{item?.orderId}</td>
                                <td>
                                  {moment
                                    .unix(item?.createdAt?.seconds)
                                    .format("DD-MM-YYYY")}
                                </td>
                                <td>{item?.customerName}</td>
                                <td>
                                  <Badge color="" className="badge-dot mr-4">
                                    <i className="bg-success" />
                                    {item?.status}
                                  </Badge>
                                </td>{" "}
                                <td className="text-right">
                                  {item?.status == "confirmed" ? (
                                    <Button
                                      color="danger"
                                      size="sm"
                                      disabled={item?.isRefunded ? true : false}
                                      onClick={() => {
                                        refundedToggle();
                                        setPaymentId(item?.paymentId);
                                      }}
                                    >
                                      {item?.isRefunded ? "Refunded" : "Refund"}
                                    </Button>
                                  ) : (
                                    ""
                                  )}
                                  <Button
                                    color="info"
                                    size="sm"
                                    onClick={() => {
                                      history.push(
                                        `/admin/order-details/${item?.orderId}`
                                      );
                                    }}
                                  >
                                    <i class="fas fa-eye"></i>{" "}
                                  </Button>{" "}
                                </td>
                              </tr>
                            </>
                          );
                        })}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
              {getLoading == false ? (
                <>
                  <p className="text-center mt-3">
                    {active == false
                      ? "No more Orders"
                      : allOrders.length == 0
                      ? "Order not found"
                      : ""}
                  </p>
                  {allOrders.length != 0 ? (
                    <div className="text-center">
                      <Button
                        onClick={handleLoadMore}
                        className="mx-3 text-secondary  mb-2"
                        color="success"
                        disabled={active ? false : true}
                      >
                        Load more
                      </Button>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}
            </LoadingOverlay>
          </div>
        </Row>
        <Modal isOpen={refundedModal} toggle={refundedToggle}>
          <ModalHeader toggle={refundedToggle}>
            Refund Confirmation Box
          </ModalHeader>
          <ModalBody>
            <h5 className="text-center ">
              Are you Sure you want to refund this order?
            </h5>
            <div className="d-flex justify-content-center align-items-center my-3">
              <Button
                className="bg-primary text-white w-25 mx-2"
                disabled={updateLoader}
                onClick={() => {
                  dispatch(
                    refundOrderAction(paymentId, () => {
                      refundedToggle();
                    })
                  );
                }}
              >
                {updateLoader ? <Spinner size="md" /> : "Yes"}
              </Button>

              <Button
                className="bg-danger text-white w-25"
                onClick={refundedToggle}
              >
                No
              </Button>
            </div>
          </ModalBody>
        </Modal>
      </Container>
    </>
  );
};

export default Orders;
