import React, { useState } from "react";
import { Button, Collapse, Form } from "reactstrap";
import { DropzoneArea } from "material-ui-dropzone";
import { makeStyles } from "@material-ui/core/styles";
import { Viewer } from "react-3d-viewer";

export default function CADFile({ cadFile, setCadFile }) {
  const [IsOpen, setIsOpen] = useState(false);
  const [cadFileUrl, setCadFileUrl] = useState("");
  const toggle = () => {
    setIsOpen(!IsOpen);
  };
  const handleFileChange = (cadfiles) => {
    if (cadfiles.length > 0) {
      setCadFile(cadfiles[0]);
      const cadfile = cadfiles[0];
      setCadFileUrl(URL.createObjectURL(cadfile));
    }
  };

  const useStyles = makeStyles((theme) => ({
    dropzone: {
      width: "100%",
      margin: "0 auto",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
  }));
  const classes = useStyles();
  const downloadFile = () => {
    if (cadFile) {
      const a = document.createElement("a");
      a.href = cadFile;
      a.download = "cadFile";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };
  return (
    <div className="my-3 border border-2 p-2 shadow-sm">
      <Form className="my-2">
        <div
          className="d-flex justify-content-between align-items-center cursor"
          onClick={() => {
            toggle();
            setIsOpen(!IsOpen);
          }}
        >
          <h4 className="mb-0 pb-0 font-weight-bold">3D CAD File</h4>
          <i
            className={`fas ${IsOpen ? "fa-chevron-up" : "fa-chevron-down"}`}
          ></i>
        </div>
        <Collapse isOpen={IsOpen}>
          <div>
            <h2>Upload CAD File</h2>
            {typeof cadFile === "string" && cadFile !== "" ? (
              <Button
                className="mb-2"
                color="primary"
                onClick={() => {
                  downloadFile();
                }}
              >
                Download Uploaded File
              </Button>
            ) : null}
            <DropzoneArea
              required
              name="cadFile"
              acceptedFiles={[
                "application/sla",
                "application/step",
                "application/step-xml",
                "application/sat",
                "model/vnd.usdz+zip",
                "model/stl",
                "model/obj",
                ".dwg",
                ".stl",
              ]}
              classes={{
                root: classes.dropzone,
              }}
              maxFiles={1}
              onChange={handleFileChange} // Pass the function directly without ()
            />

            {/* {cadFile && (
              <Viewer
                file={cadFileUrl}
                width={400}
                height={400}
                modelColor="#B92C2C"
                backgroundColor="#EAEAEA"
              />
            )} */}
          </div>
        </Collapse>
      </Form>
    </div>
  );
}
