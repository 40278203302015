// /*!

// =========================================================
// * Argon Dashboard React - v1.2.1
// =========================================================

// * Product Page: https://www.creative-tim.com/product/argon-dashboard-react
// * Copyright 2021 Creative Tim (https://www.creative-tim.com)
// * Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

// * Coded by Creative Tim

// =========================================================

// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
import { useEffect } from "react";
import ReactDOM from "react-dom";
import { Provider, useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import store from "store/store";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/plugins/nucleo/css/nucleo.css";
import "assets/scss/argon-dashboard-react.scss";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import { logout } from "store/actions/authAction";

const App = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { logAuth } = useSelector((state) => state.authUser);
  useEffect(() => {
    const handleLogout = () => {
      dispatch(logout(history, window.location.search));
    };
    const handleCancel = () => {
      history.push("/admin/index");
    };
    if (logAuth) {
      console.log(window.location.search, "search");
      const searchParams = new URLSearchParams(window.location.search);

      if (
        searchParams.has("type") &&
        searchParams.has("id") &&
        searchParams.has("email")
      ) {
        const isConfirmed = window.confirm(
          "You are already logged in with another role. Press OK to sign out from the other role and Sign up with the new role. Press Cancel to continue with the current login role."
        );
        if (isConfirmed) {
          handleLogout();
        } else {
          handleCancel();
        }
      } else {
        history.push("/admin/index");
      }
    }
  }, [logAuth, history]);

  return (
    <Switch>
      <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
      <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
      <Redirect from="/" to="/auth/login" />
    </Switch>
  );
};

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
