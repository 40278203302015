import PictureWall from "../../components/PictureWall/PictureWall";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  Col,
  Collapse,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { getCategories } from "store/actions/categoryAction";
import {
  getColors,
  getSelectedCategoryItems,
} from "../../store/actions/itemAction";
import { deleteVideoFile } from "utils/videoUpload";

export default function Details({ detail, setDetail }) {
  const dispatch = useDispatch();
  const { allCategories } = useSelector((state) => state.category);
  const { colorList } = useSelector((state) => state.item);
  const [IsOpen, setIsOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [ecodropdownOpen, setEcoDropdownOpen] = useState(false);
  const [powerdropdownOpen, setPowerDropdownOpen] = useState(false);
  const [localdropdownOpen, setlocalDropdownOpen] = useState(false);
  const [internationaldropdownOpen, setInternationalDropdownOpen] =
    useState(false);
  const videoRef = useRef(null);

  const handleVideoChange = (e) => {
    const file = e.target.files[0];
    if (!file || file.type !== "video/mp4") {
      alert("Invalid file format. Please upload an MP4 video.");
      return;
    }

    setSelectedVideo(URL.createObjectURL(file));

    setDetail({
      ...detail,
      videoFile: file,
    });
  };

  const toggle = () => {
    setIsOpen(!IsOpen);
  };
  const ecotoggle = () => {
    setEcoDropdownOpen((prevState) => !prevState);
  };
  const powertoggle = () => {
    setPowerDropdownOpen((prevState) => !prevState);
  };
  const localtoggle = () => {
    setlocalDropdownOpen((prevState) => !prevState);
  };

  const internationaltoggle = () => {
    setInternationalDropdownOpen((prevState) => !prevState);
  };
  const toggleDropDown = () => {
    setDropdownOpen((prevState) => !prevState);
  };
  const handleDropdownSelect = (value) => {
    setDetail({ ...detail, categoryId: value });
    dispatch(getSelectedCategoryItems(value));
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const parsedValue = name === "price" ? parseFloat(value, 10) : value;
    const minPrice = 0;
    const maxPrice = 10000;
    const updatedPrice =
      name === "price"
        ? Math.min(maxPrice, Math.max(minPrice, parsedValue))
        : parsedValue;
    const updatedAboutDetails = {
      ...detail,
      [name]: updatedPrice,
    };
    setDetail(updatedAboutDetails);
  };

  const handleDropdownChange = (name, value) => {
    const updatedAboutDetails = {
      ...detail,
      [name]: value,
    };
    setDetail(updatedAboutDetails);
  };

  const handleSelect = (opt) => {
    setDetail((prevDetail) => {
      return {
        ...prevDetail,
        color: opt,
      };
    });
  };

  useEffect(() => {
    if (allCategories.length > 0 && detail.categoryId === "") {
      setDetail((prevDetail) => ({
        ...prevDetail,
        categoryId: allCategories[0]?.id,
      }));
      dispatch(getSelectedCategoryItems(allCategories[0]?.id));
    }
  }, [allCategories]);

  useEffect(() => {
    if (detail?.categoryId != "") {
      dispatch(getSelectedCategoryItems(detail?.categoryId));
    }
  }, [detail.categoryId]);

  useEffect(() => {
    dispatch(getCategories());
    dispatch(getColors());
  }, []);

  return (
    <div className="my-3 border border-2 p-2 shadow-sm">
      <Form className="my-2 form-2">
        <div
          className="d-flex justify-content-between align-items-center cursor"
          onClick={() => {
            toggle();
            setIsOpen(!IsOpen);
          }}
        >
          <h4 className="mb-0 pb-0 font-weight-bold">Details</h4>
          <i
            className={`fas ${IsOpen ? "fa-chevron-up" : "fa-chevron-down"}`}
          ></i>
        </div>
        <Collapse isOpen={IsOpen}>
          <Row className="mt-3">
            <Col md={6} sm={12}>
              <FormGroup>
                <Label>Name</Label>
                <Input
                  type="text"
                  placeholder="Full Name..."
                  name="name"
                  value={detail?.name}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
              </FormGroup>
            </Col>
            <Col md={6} sm={12}>
              <FormGroup>
                <Label>Price</Label>
                <Input
                  type="number"
                  placeholder="price..."
                  name="price"
                  value={detail?.price}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Label>Colors</Label>
              <Select
                isMulti
                className="w-50"
                options={colorList}
                value={detail?.color}
                onChange={handleSelect}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>Description</Label>
                <Input
                  type="textarea"
                  placeholder="Description..."
                  name="description"
                  value={detail?.description}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm={4}>
              <Label className="d-block">Select Category:</Label>
              <Dropdown
                className="mb-2"
                isOpen={dropdownOpen}
                toggle={toggleDropDown}
              >
                <DropdownToggle color="primary" caret>
                  {(allCategories &&
                    allCategories.find(
                      (category) => category?.id === detail?.categoryId
                    )?.name) ||
                    detail?.categoryId}
                </DropdownToggle>
                <DropdownMenu>
                  {allCategories &&
                    allCategories?.map((item) => {
                      return (
                        <DropdownItem
                          onClick={() => handleDropdownSelect(item?.id)}
                        >
                          {item?.name}
                        </DropdownItem>
                      );
                    })}
                </DropdownMenu>
              </Dropdown>
            </Col>
            <Col sm={4}>
              <Label className="d-block">EcoSystem:</Label>
              <Dropdown
                className="mb-2"
                isOpen={ecodropdownOpen}
                toggle={ecotoggle}
              >
                <DropdownToggle color="primary" caret>
                  {detail?.ecoSystem}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={() => handleDropdownChange("ecoSystem", "Wi-Fi")}
                  >
                    Wi-Fi
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => handleDropdownChange("ecoSystem", "Zigbee")}
                  >
                    Zigbee
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => handleDropdownChange("ecoSystem", "Z-Wave")}
                  >
                    Z-Wave
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </Col>
            <Col sm={4}>
              {" "}
              <Label className="d-block">Power:</Label>
              <Dropdown
                className="mb-2 "
                isOpen={powerdropdownOpen}
                toggle={powertoggle}
              >
                <DropdownToggle color="primary" caret>
                  {detail?.power}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={() => handleDropdownChange("power", "Plug")}
                  >
                    Plug
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => handleDropdownChange("power", "Battery")}
                  >
                    Battery
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => handleDropdownChange("power", "POE")}
                  >
                    POE
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </Col>
          </Row>
          <div>
            <Label className="d-block">Shipping Time</Label>
          </div>
          <Row>
            <Col sm={4}>
              <Label className="d-block">Local:</Label>
              <Dropdown
                className="mb-2 "
                isOpen={localdropdownOpen}
                toggle={localtoggle}
              >
                <DropdownToggle color="primary" caret>
                  {detail?.shippingTimeLocal}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={() =>
                      handleDropdownChange("shippingTimeLocal", "Days")
                    }
                  >
                    Days
                  </DropdownItem>
                  <DropdownItem
                    onClick={() =>
                      handleDropdownChange("shippingTimeLocal", "Weeks")
                    }
                  >
                    Weeks
                  </DropdownItem>
                  <DropdownItem
                    onClick={() =>
                      handleDropdownChange("shippingTimeLocal", "Months")
                    }
                  >
                    Months
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </Col>
            <Col sm={4}>
              <Label className="d-block">International:</Label>
              <Dropdown
                className="mb-2 "
                isOpen={internationaldropdownOpen}
                toggle={internationaltoggle}
              >
                <DropdownToggle color="primary" caret>
                  {detail?.shippingTimeInternational}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={() =>
                      handleDropdownChange("shippingTimeInternational", "Days")
                    }
                  >
                    Days
                  </DropdownItem>
                  <DropdownItem
                    onClick={() =>
                      handleDropdownChange("shippingTimeInternational", "Weeks")
                    }
                  >
                    Weeks
                  </DropdownItem>
                  <DropdownItem
                    onClick={() =>
                      handleDropdownChange(
                        "shippingTimeInternational",
                        "Months"
                      )
                    }
                  >
                    Months
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <PictureWall detail={detail} setDetail={setDetail} />
            </Col>
          </Row>

          {/* --------------- VIDEO UPLOAD --------------------- */}

          <Row>
            <Col md={3}>
              {!selectedVideo && !detail.videoFile ? (
                <FormGroup
                  className="m-3 p-3 rounded d-flex justify-content-center align-items-center video-container"
                  style={{
                    border: "1px dashed lightgray",
                    backgroundColor: "#fafafa",
                    width: "100px",
                    height: "100px",
                    cursor: "pointer",
                  }}
                >
                  <span onClick={() => videoRef?.current.click()}>
                    <i className="fas fa-solid fa-video fa-2x"></i>
                  </span>
                  <input
                    ref={videoRef}
                    onChange={handleVideoChange}
                    type="file"
                    placeholder=""
                    name="video"
                    accept=".mp4"
                    className="d-none"
                  />
                </FormGroup>
              ) : (
                <div className="d-flex position-relative">
                  <video ref={videoRef} width="100%" controls className="p-3">
                    <source
                      src={selectedVideo || detail?.videoFile}
                      type="video/mp4"
                    />
                  </video>
                  {(selectedVideo || detail?.videoFile) && (
                    <>
                      <button
                        color="tranparent"
                        className="my-5 position-absolute border-0 bg-transparent"
                        style={{ top: "-41px", right: "3px" }}
                        onClick={(e) => {
                          e.preventDefault();
                          videoRef.current.pause();
                          if (selectedVideo) {
                            setSelectedVideo(null);
                          } else {
                            setDetail({ ...detail, videoFile: null });
                            if (detail?.videoFile) {
                              deleteVideoFile(detail?.videoFile);
                            }
                          }
                        }}
                      >
                        <i
                          className="fas fa-times text-white bg-danger rounded-circle"
                          style={{
                            fontSize: "10px",
                            paddingLeft: "8px",
                            paddingRight: "8px",
                            paddingTop: "6px",
                            paddingBottom: "6px",
                          }}
                        ></i>
                      </button>
                    </>
                  )}
                </div>
              )}
            </Col>
          </Row>
        </Collapse>
      </Form>
    </div>
  );
}
