const initialState = {
    timerStopped: false,
    // weekend: "",
  };
  
  const weekendReducer = (state = initialState, action) => {
    switch (action.type) {
      case "STOP_TIMER":
        return {
          ...state,
          timerStopped: action.payload,
        };
        default:
          return state;
    }
  };
  
  export default weekendReducer;
  