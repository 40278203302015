import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import item1 from "../../assets/img/items/bulb.jpg";
import item2 from "../../assets/img/items/doorBell.jpg";
import item3 from "../../assets/img/items/camera.webp";
import item4 from "../../assets/img/items/ac.jpg";
import item5 from "../../assets/img/items/fridge.png";
const AddItem = ({ isOpen, toggle, selectedItemImages }) => {
  return (
    <>
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle} className="border-bottom">
          <h3 className="mb-0">Items Images</h3>
        </ModalHeader>
        <ModalBody>
          <Row md={2} lg={3}>
            {selectedItemImages &&
              selectedItemImages?.map((item) => {
                return (
                  <Col>
                    <img
                      src={item}
                      width="100%"
                      className="mb-3 border shadow-lg"
                    />
                  </Col>
                );
              })}
            {/* <Col>
              <img src={item2} width="100%" className="mb-3 border shadow-lg" />
            </Col>
            <Col>
              <img src={item3} width="100%" className="mb-3 border shadow-lg" />
            </Col>
            <Col>
              <img src={item4} width="100%" className="mb-3 border shadow-lg" />
            </Col>
            <Col>
              <img src={item5} width="100%" className="mb-3 border shadow-lg" />
            </Col> */}
          </Row>
        </ModalBody>
        {/* <ModalFooter>
          <Button
            color="primary"
            onClick={props.toggle}
            className="px-5 d-flex mx-auto"
          >
            Ok
          </Button>{" "}
        </ModalFooter> */}
      </Modal>
    </>
  );
};

export default AddItem;
