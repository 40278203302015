const initialData = {
  addLoading: false,
  getLoading: false,
  isEmailNotExists: false,
  usersWithRole: [],
};
const distributorReducer = (state = initialData, action) => {
  switch (action.type) {
    case "ADD_LOADER":
      return {
        ...state,
        addLoading: action.payload,
      };
    case "USER_WITH_ROLE":
      return {
        ...state,
        usersWithRole: action.payload,
      };
    case "GET_LOADER":
      return {
        ...state,
        getLoading: action.payload,
      };
    case "IS_EMAIL_EXISTS":
      return {
        ...state,
        isEmailNotExists: action.payload,
      };
    default:
      return state;
  }
};

export default distributorReducer;
