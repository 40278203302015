import React, { useEffect, useState } from "react";
import { Table, Button, Popover, PopoverHeader, PopoverBody } from "reactstrap";
import moment from "moment";
import DisputeBadge from "./DisputeBadge";
import DisputeButton from "components/Models/DisputeModel";
import { useDispatch, useSelector } from "react-redux";
import RadicalTimer from "./RadicalTimer";
import { GiBrainFreeze } from "react-icons/gi";
import { weekendAction } from "store/actions/weekendAction";

export default function RecentsReviewsTable({
  allReviews,
  reviewType,
  selectedReviews,
}) {
  const dispatch = useDispatch();
  const [popoverOpen, setPopoverOpen] = useState(false);
  const { user } = useSelector((state) => state.authUser);
  const { timerStopped } = useSelector((state) => state.timerStopped);

  useEffect(() => {
    const currentDay = new Date().getDay();
    const weekend = user?.weekends ? user?.weekends.join("/"): null;

    const shouldStopTimer =
      (currentDay === 5 && weekend === "Friday/Saturday") ||
      (currentDay === 6 && weekend === "Friday/Saturday") ||
      (currentDay === 6 && weekend === "Saturday/Sunday") ||
      (currentDay === 0 && weekend === "Saturday/Sunday");

    dispatch(weekendAction(shouldStopTimer));
  }, [user, dispatch]);

  useEffect(() => {
    if (timerStopped) {
    }
  }, [timerStopped]);

  return (
    <Table className="align-items-center table-flush" responsive>
      <thead className="thead">
        <tr>
          <th scope="col">Date</th>
          <th scope="col">Order Id</th>
          <th scope="col">Buyer Name</th>
          <th>Rating</th>
          <th>Review</th>
          {reviewType === "disputes" && (
            <>
              <th scope="col">Dispute Description</th>
              <th scope="col">Dispute Status</th>
            </>
          )}
          <th scope="col" className="text-right">
            {reviewType === "recents" ? "Action" : "Status"}
          </th>
          {reviewType === "recents" && (
            <th scope="col" className="text-right">
              Time Left
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        {allReviews &&
          allReviews.map((item) => (
            <React.Fragment key={item?.id}>
              <tr>
                <td style={{ letterSpacing: "1px" }}>
                  {moment.unix(item?.createdAt.seconds).format("DD/MM/YYYY")}
                </td>
                <th scope="row">
                  <span className="mb-0 text-sm">{item?.orderId}</span>
                </th>
                <td>{item?.buyerName}</td>
                <td>{item?.rating}/5</td>
                <td>
                  <span className="table-description ">
                    {item?.description}
                  </span>
                </td>
                {reviewType === "disputes" && (
                  <>
                    <td>
                      <span className="table-description ">
                        {item?.disputeDescription}
                      </span>
                    </td>
                    <td>
                      <DisputeBadge status={item?.disputeStatus} />
                    </td>
                  </>
                )}
                <td className="text-right">
                  {reviewType !== "disputes" && (
                    <DisputeButton
                      item={item.id}
                      selectedReviews={selectedReviews}
                    />
                  )}
                </td>
                {timerStopped ? (
                  <td className="text-center align-middle">
                    <Button
                      id="Popover1"
                      type="button"
                      onClick={() => setPopoverOpen(!popoverOpen)}
                    >
                      <GiBrainFreeze
                        style={{ color: "#379cbf", fontSize: "30px" }}
                      />
                    </Button>
                    <Popover
                      flip
                      placement="top"
                      target="Popover1"
                      isOpen={popoverOpen}
                      toggle={() => setPopoverOpen(!popoverOpen)}
                    >
                      <PopoverBody>Weekend Days</PopoverBody>
                    </Popover>
                  </td>
                ) : (
                  reviewType === "recents" && (
                    <td>
                      <RadicalTimer createdAt={item?.createdAt} />
                    </td>
                  )
                )}
              </tr>
            </React.Fragment>
          ))}
      </tbody>
    </Table>
  );
}
