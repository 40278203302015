export const FileInput = ({ id, onChange, stripeObj }) => {
  const fileName = stripeObj?.file?.name || "Government Document";

  return (
    <>
      <label className="form-control-label" htmlFor={`input-${id}`}>
        Government Document
      </label>
      <div className="px-2 pt-2 form-control-alternative bg-white d-flex justify-content-between align-items-center w-100 rounded">
        <label htmlFor={id}>
          <span title={stripeObj?.file?.name}>{fileName}</span>
          <input
            type="file"
            accept=".pdf"
            id={id}
            onChange={onChange}
            className="d-none"
          />
        </label>
        <i
          role="button"
          onClick={() => document.getElementById(id).click()}
          className="fas fa-file-contract text-orange mb-2 ml-1 mr-2"
        ></i>
      </div>
    </>
  );
};
