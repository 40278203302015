import { DotPulse } from "@uiball/loaders";
import { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Spinner,
  Table,
  UncontrolledDropdown,
} from "reactstrap";
import { getUsersWithRole } from "store/actions/distributorAction";
import {
  assignTicket,
  getTickets,
  updateTicket,
} from "store/actions/ticketsActions";
import OnlyHeader from "../components/Headers/OnlyHeader";
import { getItems } from "../store/actions/itemAction";
import { role } from "constant/teamRoles";
import { getTicketsCategories } from "store/actions/ticketsActions";

const Tickets = () => {
  const dispatch = useDispatch();

  //state selectors
  const { uid, user } = useSelector((state) => state.authUser);
  const { tickets, ticketsLoading, hasMoreData, vendorTicketCategories } =
    useSelector((state) => state.ticket);
  const { usersWithRole } = useSelector((state) => state.distributor);

  // states
  const [allTickets, setAllTickets] = useState([]);
  const [filterTicket, setFilterTicket] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [categoryDropdownOpen, setCategoryDropdownOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("all categories");
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const limit = 8;

  //functions
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const toggleCategory = () =>
    setCategoryDropdownOpen((prevState) => !prevState);

  function formatDate(timestamp) {
    const date = new Date(
      timestamp._seconds * 1000 + timestamp._nanoseconds / 1e6
    );
    return date.toLocaleString();
  }

  function getAssignedTo(id) {
    const userFound = usersWithRole?.find((user) => user?.id === id);
    const name = userFound?.username?.split(" ");

    if (name && name.length >= 2) {
      return name[0].charAt(0) + name[1].charAt(0);
    } else if (name && name.length === 1) {
      return name[0].charAt(0);
    } else {
      return "";
    }
  }

  const handleUpdateTicket = (ticket, status) => {
    let payload = {
      ticketId: ticket?.id,
      updatedById: uid,
      status: status,
    };
    dispatch(updateTicket(payload));
  };

  const handleLoadMore = () => {
    const lastIndex = allTickets.length - 1;
    const lastVisibleDocument = allTickets[lastIndex].id;
    let clearState = false;
    let category =
      selectedCategory === "all categories" ? null : selectedCategory;
    dispatch(getTickets(uid, limit, clearState, lastVisibleDocument, category));
  };

  const handleSearch = (e) => {
    let searchValue = e.target.value;
    setSearch(searchValue);
    const filteredResults = tickets.filter(
      (ticket) =>
        ticket?.buyerEmail.toLowerCase().includes(searchValue) ||
        ticket?.description.toLowerCase().includes(searchValue) ||
        ticket?.category.toLowerCase().includes(searchValue)
    );
    setAllTickets(filteredResults);
  };

  const handleDropdownSelect = (dropdownValue) => {
    setSelectedCategory(dropdownValue);
  };

  //Side Effects
  useEffect(() => {
    let clearState = true;
    let category =
      selectedCategory === "all categories" ? null : selectedCategory;

    dispatch(getTickets(uid, limit, clearState, null, category));
    dispatch(getUsersWithRole(uid, role.CUSTOMER_SUPPORT));
  }, [dispatch, uid, selectedCategory]);

  useEffect(() => {
    dispatch(getTicketsCategories("vendorTickets"));
  }, []);

  useEffect(() => {
    if (tickets?.length > 0) {
      setAllTickets(tickets);
    }
  }, [tickets]);

  useEffect(() => {
    if (filterTicket !== "") {
      const filteredResults = tickets.filter(
        (ticket) => ticket.status.toLowerCase() === filterTicket.toLowerCase()
      );
      setAllTickets(filteredResults);
    } else {
      setAllTickets(tickets);
    }
  }, [filterTicket, tickets]);

  // useEffect(() => {
  //   const filteredResults =
  //     selectedCategory !== "all categories"
  //       ? tickets.filter(
  //           (ticket) =>
  //             ticket.category.toLowerCase() === selectedCategory.toLowerCase()
  //         )
  //       : tickets;

  //   setAllTickets(filteredResults);
  // }, [tickets, selectedCategory]);

  return (
    <>
      <OnlyHeader />
      <Container className="mt--9 position-absolute " fluid>
        <div className="mb-2">
          <Dropdown isOpen={dropdownOpen} toggle={toggle} direction={"down"}>
            <DropdownToggle className="text-capitalize">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-filter"
                viewBox="0 0 16 16"
              >
                <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5m-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5" />
              </svg>{" "}
              {filterTicket || "Filter"}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => setFilterTicket("")}>
                All Tickets
              </DropdownItem>
              <DropdownItem onClick={() => setFilterTicket("open")}>
                Open
              </DropdownItem>
              <DropdownItem onClick={() => setFilterTicket("close")}>
                Closed
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </Container>
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow-sm">
              <CardHeader className="border-0 d-lg-flex d-md-flex d-sm-flex w-100 justify-content-between align-items-center">
                <h3>Ticket Details</h3>
                <div className="mb-2">
                  <Dropdown
                    isOpen={categoryDropdownOpen}
                    toggle={toggleCategory}
                  >
                    <DropdownToggle color="primary text-capitalize" caret>
                      {selectedCategory}
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        onClick={() =>
                          handleDropdownSelect("All categories".toLowerCase())
                        }
                      >
                        All Categories
                      </DropdownItem>
                      {vendorTicketCategories &&
                        vendorTicketCategories.map((category) => {
                          return (
                            <DropdownItem
                              onClick={() => handleDropdownSelect(category)}
                            >
                              {category}
                            </DropdownItem>
                          );
                        })}
                    </DropdownMenu>
                  </Dropdown>
                </div>
                <div
                  className="mr-3"
                  style={{ maxWidth: "200px" }}
                >
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-search" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="text"
                      placeholder="Search"
                      value={search}
                      onChange={(e) => {
                        handleSearch(e);
                      }}
                    />
                  </InputGroup>
                </div>
              </CardHeader>
              <CardBody className="p-0">
                {" "}
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead">
                    <tr>
                      <th scope="col">Email</th>
                      <th scope="col">Ticket No.</th>
                      <th scope="col">Category</th>
                      {user?.role === "seller" && <th scope="col">Assignee</th>}

                      <th>Status</th>
                      <th scope="col">Updated</th>
                      <th scope="col">Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allTickets?.map((ticket) => {
                      return (
                        <>
                          <tr key={ticket?.ticketNo}>
                            <td>{ticket?.buyerEmail}</td>
                            <td className="text-info">
                              <Col>
                                <Row>#{ticket.ticketNo}</Row>

                                <Row>
                                  <span className="mb-0 fs--12 text-muted">
                                    {ticket?.description.length > 30
                                      ? ticket?.description.slice(0, 30) + "..."
                                      : ticket?.description}
                                  </span>
                                </Row>
                              </Col>
                            </td>
                            <td>{ticket?.category}</td>
                            {user?.role === "seller" && (
                              <th scope="row">
                                <UncontrolledDropdown
                                  group
                                  size="sm"
                                  direction="down"
                                >
                                  <Button
                                    color="info"
                                    size="sm"
                                    className="text-uppercase"
                                  >
                                    {ticket?.assignTo == null ||
                                    ticket?.assignTo === ""
                                      ? "Unassigned"
                                      : getAssignedTo(ticket?.assignTo)}
                                  </Button>
                                  <DropdownToggle caret color="info" />
                                  <DropdownMenu>
                                    {usersWithRole?.map((person) => {
                                      return (
                                        <DropdownItem
                                          onClick={() => {
                                            let payload = {
                                              ticketId: ticket?.id,
                                              updatedById: uid,
                                              assignTo: person.id,
                                            };
                                            dispatch(assignTicket(payload));
                                          }}
                                        >
                                          {person?.username}
                                        </DropdownItem>
                                      );
                                    })}
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </th>
                            )}
                            <td className="text-capitalize">
                              <UncontrolledDropdown group size="sm">
                                <Button
                                  color={`${
                                    ticket?.status === "close"
                                      ? "secondary"
                                      : "warning"
                                  }`}
                                  size="sm"
                                  className="text-capitalize"
                                >
                                  {ticket?.status}
                                </Button>
                                <DropdownToggle
                                  caret
                                  color={`${
                                    ticket?.status === "close"
                                      ? "secondary"
                                      : "warning"
                                  }`}
                                />
                                <DropdownMenu>
                                  <DropdownItem
                                    onClick={() =>
                                      handleUpdateTicket(ticket, "close")
                                    }
                                  >
                                    Close
                                  </DropdownItem>
                                  <DropdownItem
                                    onClick={() =>
                                      handleUpdateTicket(ticket, "open")
                                    }
                                  >
                                    Re-Open
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>

                            <td>
                              {ticket?.updatedAt
                                ? formatDate(ticket?.updatedAt)
                                : formatDate(ticket?.createdAt)}
                            </td>
                            <td>
                              <Button
                                color="info"
                                size="sm"
                                onClick={() => {
                                  history.push(
                                    `/admin/ticket-detail/${ticket?.id}`
                                  );
                                }}
                              >
                                <i class="fas fa-eye"></i>{" "}
                              </Button>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </Table>
                {allTickets.length ? (
                  hasMoreData ? (
                    <div className="w-100 d-flex justify-content-center my-2">
                      {ticketsLoading ? (
                        <Spinner size={"sm"} className="my-2" />
                      ) : (
                        <Button
                          onClick={handleLoadMore}
                          className="bg-success mx-4 text-secondary  "
                        >
                          Load more
                        </Button>
                      )}
                    </div>
                  ) : (
                    <div className="w-100 d-flex justify-content-center my-2">
                      <p>
                        Looks like you've seen it all! Nothing more to load.
                      </p>
                    </div>
                  )
                ) : ticketsLoading ? (
                  <div className="w-100 d-flex justify-content-center my-2">
                    <Spinner size={"sm"} className="my-2" />
                  </div>
                ) : (
                  <p className="text-center my-3">
                    Great news! No Ticket found. Keep up the excellent work!
                  </p>
                )}
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Tickets;
