import React, { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Upload } from "antd";
import { Col, Container, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
const PictureWall = ({ detail, setDetail }) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };
  const checkFileType = (file) => {
    if (file && /\.(png|jpe?g)$/i.test(file.name)) {
      return true;
    } else {
      alert("You can only upload PNG/JPG/JPEG file!");
      return false;
    }
  };
  const handleChange = (newFileList) => {
    const filteredList = newFileList.filter((file) => checkFileType(file));
    const temp = filteredList.map((file)=>{
      return {...file,status:"done"}
    })
    setDetail((prevDetail) => ({
      ...prevDetail,
      images: temp,
    }));
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );
  return (
    <>
      <Container>
        <Row>
          <Col>
            <Upload
              listType="picture-card"
              fileList={detail?.images}
              onPreview={handlePreview}
              onChange={(info) => handleChange(info.fileList)}
              name=""
              accept="image/PNG, image/jpeg, image/jpg"
              showUploadList={{ showTooltip: false }}
            >
              {uploadButton}
            </Upload>
          </Col>
        </Row>
      </Container>

      <Modal isOpen={previewOpen} toggle={handleCancel}>
        <ModalHeader toggle={handleCancel}>{previewTitle}</ModalHeader>
        <ModalBody>
          <img
            alt="example"
            style={{
              width: "100%",
            }}
            src={previewImage}
          />
        </ModalBody>
      </Modal>
    </>
  );
};
export default PictureWall;
