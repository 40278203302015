import OnlyHeader from "components/Headers/OnlyHeader";
import PromotionModel from "../components/Models/PromotionModel";
import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Spinner,
} from "reactstrap";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getPromotions } from "../store/actions/promotionAction";
import LoadingOverlay from "react-loading-overlay";
import { deletePromotion } from "store/actions/promotionAction";

const Promotion = () => {
  const dispatch = useDispatch();
  const { getLoading, allPromotions, addLoading } = useSelector(
    (state) => state.promotion
  );
  const { allItems } = useSelector((state) => state.item);
  const { uid } = useSelector((state) => state.authUser);

  const [selectedId, setSelectedId] = useState("");
  const initialState = {
    title: "",
    type: "Fixed",
    item: "",
    amount: "",
    startDate: "",
    endDate: "",
    creatorId: uid,
  };
  const [promotionDetail, setPromotionDetail] = useState(initialState);
  const [confirmModal, setConfirmModal] = useState(false);
  const confirmtoggle = () => setConfirmModal(!confirmModal);
  const [modal, setModal] = useState(false);
  const [type, setType] = useState("");
  const toggle = () => setModal(!modal);

  useEffect(() => {
    dispatch(getPromotions(uid));
  }, []);
  return (
    <>
      <OnlyHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <LoadingOverlay
              active={getLoading}
              spinner
              text="Promotions Loading...."
            >
              <Card className="shadow-sm">
                <CardHeader className="border-0 d-flex justify-content-between align-items-center">
                  <h3 className="mb-0">Promotions</h3>
                  <Button
                    className=""
                    color="primary"
                    type="button"
                    onClick={() => {
                      setPromotionDetail({
                        ...initialState,
                        item: allItems[0]?.id,
                      });
                      toggle();
                      setType("Add");
                    }}
                  >
                    Add Promotion
                  </Button>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead">
                    <tr>
                      <th scope="col">Title</th>
                      <th scope="col">Type</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Start Date</th>
                      <th scope="col">End Date</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  {allPromotions?.length == 0 ? (
                    <div className="d-flex align-items-center justify-content-center mt-2">
                      <p className="text-center">Yet Data not found!</p>
                    </div>
                  ) : (
                    <tbody>
                      {allPromotions &&
                        allPromotions.map((item) => {
                          return (
                            <>
                              <tr>
                                <th scope="row">
                                  <span className="mb-0 text-sm">
                                    {item?.title}
                                  </span>
                                </th>
                                <td>{item?.type}</td>
                                <td>
                                  {item?.type === "Percentage"
                                    ? `${item?.amount}%`
                                    : item?.amount}
                                </td>
                                <td>
                                  {moment
                                    .unix(item?.startDate?.seconds)
                                    .format("DD-MM-YYYY")}
                                </td>
                                <td>
                                  {moment
                                    .unix(item?.endDate?.seconds)
                                    .format("DD-MM-YYYY")}
                                </td>
                                <td>
                                  <Button
                                    color="primary"
                                    onClick={() => {
                                      toggle();
                                      setType("Edit");
                                      setPromotionDetail(item);
                                    }}
                                    size="sm"
                                  >
                                    Edit
                                  </Button>
                                  <Button
                                    color="danger"
                                    onClick={() => {
                                      confirmtoggle();
                                      setSelectedId(item?.id);
                                    }}
                                    size="sm"
                                  >
                                    Delete
                                  </Button>
                                </td>
                              </tr>
                            </>
                          );
                        })}
                    </tbody>
                  )}
                </Table>
              </Card>
            </LoadingOverlay>
          </div>
        </Row>
      </Container>
      <Modal isOpen={confirmModal} toggle={confirmtoggle}>
        <ModalHeader toggle={confirmtoggle}>Confirmation Box</ModalHeader>
        <ModalBody>
          <h5 className="text-center">
            Are you sure you want to delete this promotion?
          </h5>
          <div className="d-flex justify-content-center align-items-center my-3">
            <Button
              color="primary"
              className="w-25 mx-2"
              disabled={addLoading}
              onClick={() => {
                dispatch(
                  deletePromotion(selectedId, () => {
                    confirmtoggle();
                  })
                );
              }}
            >
              {addLoading ? <Spinner size="md" /> : "Yes"}
            </Button>
            <Button color="danger" className="w-25" onClick={confirmtoggle}>
              No
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <PromotionModel
        isOpen={modal}
        toggle={toggle}
        type={type}
        promotionDetail={promotionDetail}
        setPromotionDetail={setPromotionDetail}
        initialState={initialState}
      />
    </>
  );
};

export default Promotion;
