import { RepositoryFactory } from "repository/RepositoryFactory";
var Payments = RepositoryFactory.get("payments");
import firebase from "../firebase";

export const getCountrySpecs = (country) => async (dispatch) => {
  dispatch(setConnectAccountFieldsToReducer(-1));
  try {
    let { data } = await Payments.getCountrySpecs(country);
    if (data.success) {
      let connectAccountFields =
        data.data.verification_fields.individual.minimum;
      dispatch(setConnectAccountFieldsToReducer(connectAccountFields));
    }
  } catch (error) {
    dispatch(setConnectAccountFieldsToReducer([]));
    console.log("error: ", error);
  }
};

export const getConnectAccounts = (payload) => async (dispatch) => {
  dispatch(setConnectAccountsToReducer(-1));
  try {
    let { data } = await Payments.getConnectAccounts(payload);
    let connectAccounts = data.data.external_accounts.data;

    dispatch(setConnectAccountsToReducer(connectAccounts));
  } catch (error) {
    dispatch(setConnectAccountsToReducer([]));

    // alert(
    //   error?.response?.data?.message ||
    //     "Something went wrong while get Connect Account"
    // );
    console.error("Error", error?.response?.data?.message || error);
  }
};
export const connectAccount = (payload) => async (dispatch, getState) => {
  try {
    let { data } = await Payments.connectAccount(payload);
    const state = getState();
    let authUser = state.authUser.user;
    dispatch({
      type: "UPDATEUSER",
      payload: {
        ...authUser,
        accountId: data.data.id,
      },
    });
    alert("Account Connected Successfully");
  } catch (error) {
    alert(error?.response?.data?.message || "Something went wrong");
    console.error("Error", error?.response?.data?.message || error);
  }
};

export const verifyAccountStatus = (uid) => async (dispatch) => {
  dispatch(setVerifyConnectAccountsToReducer(-1));
  try {
    let { data } = await Payments.verifyAccountStatus(uid);

    dispatch(setVerifyConnectAccountsToReducer(data.data));
  } catch (error) {
    dispatch(setVerifyConnectAccountsToReducer(null));

    console.error("Error>>>", error?.response?.data?.message || error);
  }
}; 

export const getAccountBalance = (uid) => async (dispatch) => {
  dispatch(setAvailableBalanceToReducer(-1));
  try {
    let { data } = await Payments.getAvailableAmount(uid);

    dispatch(setAvailableBalanceToReducer(data.data));
  } catch (error) {
    dispatch(setAvailableBalanceToReducer(null));

    console.error("Error>>>", error?.response?.data?.message || error);
  }
};

export const withdraw = (uid) => async (dispatch) => {
  dispatch(setWithdrawAmountToReducer(-1));
  try {
    let { data } = await Payments.withdrawBalance(uid);

    dispatch(setWithdrawAmountToReducer(data.data));
    alert("Withdraw Successfully");

  } catch (error) {
    dispatch(setWithdrawAmountToReducer(null));
    alert("Withdraw unsuccessful, an error occoured");
  }
};

////-- set data to reducer

const setConnectAccountFieldsToReducer = (payload) => async (dispatch) => {
  dispatch({
    type: "GET_CONNECT_ACCOUNTS_FIELDS",
    payload: payload,
  });
};

const setConnectAccountsToReducer = (payload) => async (dispatch) => {
  dispatch({
    type: "GET_CONNECT_ACCOUNTS",
    payload: payload,
  });
};

const setVerifyConnectAccountsToReducer = (payload) => async (dispatch) => {
  dispatch({
    type: "VERIFY_CONNECT_ACCOUNTS",
    payload: payload,
  });
};

const setAvailableBalanceToReducer = (payload) => async (dispatch) => {
  dispatch({
    type: "GET_ACCOUNT_BALANCE",
    payload: payload,
  });
};

const setWithdrawAmountToReducer = (payload) => async (dispatch) => {
  dispatch({
    type: "WITHDRAW_BALANCE",
    payload: payload,
  });
};

//Payement Logs

export const getPaymentLogs =
  (lastLog, uid, batchSize = 2) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: "LOADING", payload: true });

      const paymentLogsRef = firebase
        .firestore()
        .collection("sellerPaymentLogs")
        .where("sellerId", "==", uid);
      let query = paymentLogsRef.orderBy("createdAt", "desc").limit(batchSize);

      if (lastLog && lastLog.lastDocument) {
        query = query.startAfter(lastLog.lastDocument);
      }

      const snapshot = await query.get();

      const tempData = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      const lastDocument =
        snapshot.docs.length > 0
          ? snapshot.docs[snapshot.docs.length - 1]
          : null;
      const newLog = { lastDocument };

      const previousLogs = getState().payments.allPaymentLogs;
      const newLogs = tempData.filter(
        (log) => !previousLogs.some((prevLog) => prevLog.id === log.id)
      );

      let updatedItems = [];

      if (lastLog === null) {
        dispatch({
          type: "GET_ALL_PAYMENT_LOGS",
          payload: tempData,
          lastDocument: newLog,
          active: true,
        });
      } else if (newLogs.length > 0) {
        updatedItems = lastLog ? [...previousLogs, ...tempData] : tempData;
        dispatch({
          type: "GET_ALL_PAYMENT_LOGS",
          payload: updatedItems,
          lastDocument: newLog,
          active: true,
        });
      } else {
        updatedItems = lastLog ? [...previousLogs, ...tempData] : tempData;
        dispatch({
          type: "GET_ALL_PAYMENT_LOGS",
          payload: updatedItems,
          lastDocument: newLog,
          active: false,
        });
      }

      dispatch({ type: "LOADING", payload: false });
    } catch (error) {
      alert(error.message);
      dispatch({ type: "LOADING", payload: false });
    }
  };

  export const withdrawAmount = (uid) => async (dispatch) => {
    try {
      let amountData;
      const querySnapshot = await firebase
        .firestore()
        .collection("sellerFinance")
        .where("sellerId", "==", uid)
        .get();
      querySnapshot.forEach((doc) => {
        amountData = doc.data();
      });
      dispatch({ type: "GET_AMOUNT_TO_WITHDRAW", payload: amountData });
    } catch (error) {
      console.log(error.message);
    }
  };
