import generateFileName from "utils/generateFileName";
import firebase from "../firebase";
import { v4 as uuidv4 } from "uuid";
import { RepositoryFactory } from "repository/RepositoryFactory";
var Payments = RepositoryFactory.get("payments");

export const addUserAction =
  (payload, inviteVerification) => async (dispatch) => {
    console.log(payload, "payload");
    await dispatch(Loader(true));
    await dispatch({
      type: "LOG_AUTH",
      payload: true,
    });
    try {
      if (payload.email != undefined) {
        const userQuerySnapshot = await firebase
          .firestore()
          .collection("users")
          .where("email", "==", payload?.email)
          .get();
        let docId = "";
        let creatorId = "";
        let updatedPayload = {};
        if (userQuerySnapshot?.empty == true) {
          if (inviteVerification != "") {
            const inviteDocRef = await firebase
              .firestore()
              .collection("invites")
              .doc(inviteVerification);
            const inviteDocSnapshot = await inviteDocRef.get();
            console.log("inviteDocSnapshot: ", inviteDocSnapshot);
            if (inviteDocSnapshot?.exists) {
              creatorId = inviteDocSnapshot.data()?.creatorId;
              let country = inviteDocSnapshot.data()?.country;
              if (payload.role == "seller") {
                updatedPayload = {
                  ...payload,
                  creatorId: creatorId,
                  country: country,
                  sellerId: inviteDocSnapshot.data()?.sellerId,
                };
              } else {
                updatedPayload = {
                  ...payload,
                  creatorId: creatorId,
                  country: country,
                };
              }
              const newUserDocRef = await firebase
                .firestore()
                .collection("users")
                .add({
                  ...updatedPayload,
                  createdAt: firebase.firestore.Timestamp.now(),
                });
              docId = newUserDocRef.id;
              updatedPayload = {
                ...payload,
                creatorId: creatorId,
                docId: docId,
                country: country,
              };
              await dispatch({
                type: "LOGIN",
                payload: updatedPayload,
              });
              await firebase
                .firestore()
                .collection("invites")
                .doc(inviteVerification)
                .delete();
            }
          } else {
            const newUserDocRef = await firebase
              .firestore()
              .collection("users")
              .add({
                ...payload,
                createdAt: firebase.firestore.Timestamp.now(),
              });
            docId = newUserDocRef.id;
            updatedPayload = {
              ...payload,
              docId: docId,
            };
            await dispatch({
              type: "LOGIN",
              payload: updatedPayload,
            });
          }
        } else {
          docId = userQuerySnapshot?.docs[0]?.id;
          const existingUserData = userQuerySnapshot?.docs[0].data();
          const updatedPayload = {
            ...payload,
            docId: docId,
            role: existingUserData?.role,
            creatorId: existingUserData?.creatorId
              ? existingUserData?.creatorId
              : "",
            organization: existingUserData?.organization
              ? existingUserData?.organization
              : "",
            country: existingUserData?.country ? existingUserData?.country : "",
            logo: existingUserData?.logo ? existingUserData?.logo : "",
            sellerId: existingUserData?.sellerId
              ? existingUserData?.sellerId
              : "",
            stats: existingUserData?.stats,
            weekends: existingUserData?.weekends
              ? existingUserData?.weekends
              : "",
            dob: existingUserData?.dob ? existingUserData?.dob : "",
            accountId: existingUserData.accountId
              ? existingUserData.accountId
              : "",
            fileUploadingProgress: -1,
          };
          await dispatch({
            type: "LOGIN",
            payload: updatedPayload,
          });
        }
        dispatch(Loader(false));
      }
    } catch (error) {
      console.log(error.message);
      dispatch(Loader(false));
    }
  };

export const uploadFile = (uid, file) => async (dispatch, getState) => {
  try {
    let fileName = generateFileName(file.name); //generate unique fileName
    let storageRef = await firebase.storage().ref("UserLogo/" + fileName); // hold the reference of storage

    let uploadTask = storageRef.put(file); // add the file in storage

    //check the progress of uploading
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        dispatch(uploadingProgress(parseInt(progress)));
      },
      (error) => {
        alert("Upload failed due to: " + error.message);
      }
    );

    if (uploadTask) {
      const snapshot = await uploadTask;
      let url = await snapshot.ref.getDownloadURL();

      const state = getState();
      let authUser = state.authUser.user;

      await firebase
        .firestore()
        .collection("users")
        .doc(uid)
        .update({ logo: url })
        .then(() => {
          dispatch({
            type: "UPDATEUSER",
            payload: {
              ...authUser,
              logo: url,
            },
          });
        });
    }
  } catch (error) {
    console.log(error);
  }
};

export const updateUser =
  (
    role,
    uid,
    username,
    organization,
    logo,
    country,
    dob,
    weekends,
    onSuccess
  ) =>
  async (dispatch, getState) => {
    if (typeof logo != "object") {
      await dispatch(updateLoader(true));
    }
    try {
      let url = logo;
      if (typeof logo === "object") {
        let filName1 = logo?.name;
        let filName2 = filName1.slice(filName1.lastIndexOf("."));
        let filName3 = uuidv4() + filName2.toLowerCase();
        let storageRef = await firebase.storage().ref("UserLogo/" + filName3);

        let uploadTask = storageRef.put(logo);
        if (uploadTask) {
          const snapshot = await uploadTask;
          url = await snapshot.ref.getDownloadURL();
        }
      }

      let updatedPayload = {};
      if (role != "seller" && role !== "distributor") {
        updatedPayload = {
          logo: url,
          username,
          country,
          dob,
          weekends,
        };
      } else {
        updatedPayload = {
          logo: url,
          username,
          country,
          dob,
          organization: organization,
          weekends,
        };
      }
      console.log("updatedPayload: ", updatedPayload);
      await firebase
        .firestore()
        .collection("users")
        .doc(uid)
        .update(updatedPayload)
        .then(() => {
          dispatch(Loader(false));
          const state = getState();
          let authUser = state.authUser.user;
          dispatch({
            type: "UPDATEUSER",
            payload: {
              ...authUser,
              organization: organization,
              country: country,
              username: username,
              logo: url,
              dob,
              weekends,
            },
          });
          setTimeout(() => {
            dispatch(updateLoader(false));
            alert("Profile updated successfully!");
            onSuccess();
          }, 0);
        });
    } catch (error) {
      console.log(error.message);
      dispatch(updateLoader(false));
    }
  };
export const logout = (history, search) => async (dispatch) => {
  try {
    localStorage.clear();
    document.cookie =
      "cbo_short_session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    dispatch({
      type: "LOGOUT",
    });
    if (search == "") {
      history.push("/auth/login");
    }
  } catch (error) {
    alert(error.message);
  }
};
export const hashAction = (payload) => async (dispatch) => {
  await dispatch({
    type: "HASH_GAURD",
    payload: true,
  });
  try {
    dispatch({
      type: "HASH",
      payload: payload,
    });
  } catch (error) {
    console.log(error.message);
  }
};

export const verifyInvite =
  (inviteDocId, email, history) => async (dispatch) => {
    await dispatch(Loader(true));
    try {
      const inviteDoc = await firebase
        .firestore()
        .collection("invites")
        .doc(inviteDocId)
        .get();
      if (inviteDoc.exists) {
        const inviteData = inviteDoc.data();
        console.log(inviteData.email, "inviteData.email>>>");
        if (inviteData.email === email) {
          console.log("Enter in if>>>");
          dispatch({ type: "INVITE_VERIFIED", payload: inviteDocId });
          dispatch(Loader(false));
        } else {
          console.log("Enter in else>>>");
          dispatch({ type: "INVITE_VERIFIED", payload: false });
          history.push("/auth/login");
          dispatch(Loader(false));
        }
      } else {
        dispatch({ type: "INVITE_VERIFIED", payload: false });
        history.push("/auth/login");
        dispatch(Loader(false));
      }
    } catch (error) {
      console.error(error.message);
      dispatch(Loader(false));
    }
  };
export const LogAuthClear = () => async (dispatch) => {
  await dispatch({
    type: "LOG_AUTH_CLEAR",
    payload: false,
  });
};
const Loader = (data) => async (dispatch) => {
  dispatch({
    type: "LOADER",
    payload: data,
  });
};
const updateLoader = (data) => async (dispatch) => {
  dispatch({
    type: "UPDATE_LOADER",
    payload: data,
  });
};
export const uploadingProgress = (val) => async (dispatch) => {
  dispatch({
    type: "UPLOADING_PROGRESS",
    payload: val,
  });
};

// -------------------------------------

export const orgDate = (data) => {
  return async (dispatch) => {
    try {
      const dataWithStatusAndCreatedAt = {
        ...data,
        status: "time",
        createdAt: firebase.firestore.Timestamp.now(),
      };
      const docRef = await firebase
        .firestore()
        .collection("your-collection")
        .add(dataWithStatusAndCreatedAt);
      dispatch({
        type: "ADD_NEW_ORG_NAME",
        payload: docRef.id,
      });
    } catch (error) {
      dispatch({
        type: "ADD_NEW_ORG_NAME",
        payload: error.message,
      });
    }
  };
};

// -------------------------
export const addNewOrgAction =
  (data, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      await firebase
        .firestore()
        .collection("venderRequests")
        .add({ ...data, createdAt: firebase.firestore.Timestamp.now() })
        .then(onSuccess());
    } catch (error) {
      dispatch({
        type: "ADD_NEW_ORG_NAME",
        payload: error.message,
      });
    }
  };

export const getUpdateRequest =
  (uid, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      const db = firebase.firestore();
      const snapshot = await db
        .collection("venderRequests")
        .where("requestedById", "==", uid)
        .get();

      let hasAnyRequestForOrg = false;
      let hasAnyRequestForWeekends = false;
      if (!snapshot.empty) {
        snapshot.docs.forEach((doc) => {
          let requestType = doc.data().type;
          let requestStatus = doc.data().status;
          if (requestType === "updateOrganization") {
            if (requestStatus === "pending") {
              hasAnyRequestForOrg = true;
            } else {
              hasAnyRequestForOrg = false;
            }
          }

          if (requestType === "updateWeekends") {
            if (requestStatus === "pending") {
              hasAnyRequestForWeekends = true;
            } else {
              hasAnyRequestForWeekends = false;
            }
          }
          }
        );
      }
      // const orgData = [];
      // console.log(">>>orgData: ", orgData);
      // snapshot.forEach((doc) => {
      //   orgData.push({ id: doc.id, ...doc.data() });
      // });
      dispatch({
        type: "ANY_UPDATE_REQUEST",
        payload: { hasAnyRequestForOrg, hasAnyRequestForWeekends },
      });
      onSuccess();
    } catch (error) {
      dispatch({
        type: "GET_NEW_ORG_DATA",
        payload: error.message,
      });
    }
  };

export const addNewWeekend =
  (data, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      await firebase
        .firestore()
        .collection("venderRequests")
        .add({ ...data, createdAt: firebase.firestore.Timestamp.now() })
        .then(onSuccess());
    } catch (error) {
      dispatch({
        type: "ADD_NEW_WEEK_NAME",
        payload: error.message,
      });
    }
  };

export const getUpdateWeekend =
  (uid, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      const db = firebase.firestore();
      const snapshot = await db
        .collection("venderRequests")
        .where("requestedById", "==", uid)
        .get();

      let hasAnyRequestForOrg = false;
      let hasAnyRequestForWeekends = false;
      if (!snapshot.empty) {
        snapshot.docs.forEach((doc) => {
          let requestType = doc.data().type;
          let requestStatus = doc.data().status;
          if (requestType === "updateOrganization") {
            if (requestStatus === "pending") {
              hasAnyRequestForOrg = true;
            } else {
              hasAnyRequestForOrg = false;
            }
          }

          if (requestType === "updateWeekends") {
            if (requestStatus === "pending") {
              hasAnyRequestForWeekends = true;
            } else {
              hasAnyRequestForWeekends = false;
            }
          }
        });
      }
      dispatch({
        type: "GET_NEW_WEEK_DATA",
        payload: { hasAnyRequestForOrg, hasAnyRequestForWeekends },
      });
      onSuccess();
    } catch (error) {
      dispatch({
        type: "GET_NEW_WEEK_DATA",
        payload: error.message,
      });
    }
  };

export const resetModalFields = () => ({
  type: "RESET_MODAL_FIELDS",
});
// -----------------------------------------
