import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  FormGroup,
  Form,
  Input,
  Spinner,
} from "reactstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { sendInvite } from "../../store/actions/distributorAction";
import { getCountries } from "store/actions/CountryAction";

const TeamManagementModal = ({ isOpen, toggle, selectedTeam }) => {
  const [teamMemberDetail, setTeamMemberDetail] = useState({
    name: "",
    email: "",
    organization: "",
    country: { value: "", label: "" },
  });

  const dispatch = useDispatch();
  const { addLoading } = useSelector((state) => state.promotion);
  const { uid, user } = useSelector((state) => state.authUser);
  const { countries } = useSelector((state) => state.country);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTeamMemberDetail({ ...teamMemberDetail, [name]: value });
  };

  const handleCountryChange = (selectedCountry) => {
    console.log('selectedCountry: ', selectedCountry);
    setTeamMemberDetail({
      ...teamMemberDetail,
      country: selectedCountry,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, email, country } = teamMemberDetail;

    if (!name || !email || !country.value) {
      alert("Please fill in all the fields");
      return;
    }

    let payload = {
      name: teamMemberDetail.name,
      email: teamMemberDetail.email,
      country: teamMemberDetail.country,
      role: selectedTeam,
      creatorId: uid,
    };

    dispatch(
      sendInvite(payload, () => {
        toggle();
        alert("Invite sent successfully!");
        setTeamMemberDetail({
          name: "",
          email: "",
          organization: "",
          country: { value: "", label: "" },
        });
      })
    );
  };

  useEffect(() => {
    dispatch(getCountries());
  }, []);

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle} className="border-bottom">
        <h3 className="mb-0">Add {selectedTeam}</h3>
      </ModalHeader>
      <Form onSubmit={handleSubmit}>
        <ModalBody>
          <FormGroup>
            <Label for="exampleName">Name</Label>
            <Input
              id="exampleName"
              name="name"
              placeholder="Enter name"
              type="text"
              value={teamMemberDetail.name}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="exampleEmail">Email</Label>
            <Input
              id="exampleEmail"
              name="email"
              placeholder="Enter email"
              type="email"
              value={teamMemberDetail.email}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="exampleCountry">Country</Label>
            <Select
              className="countrySelect"
              id="exampleCountry"
              value={teamMemberDetail.country}
              onChange={handleCountryChange}
              options={countries}
              placeholder="Select a country..."
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            type="submit"
            className="px-5 d-flex mx-auto"
            disabled={addLoading}
          >
            {addLoading ? <Spinner size="md" /> : "Submit"}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default TeamManagementModal;
