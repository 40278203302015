import firebase from "../firebase";
import { RepositoryFactory } from "../../repository/RepositoryFactory";
var order = RepositoryFactory.get("order");
// export const getOrders =
//   (lastOrder, batchSize = 1) =>
//   async (dispatch, getState) => {
//     dispatch(getLoader(true));
//     try {
//       const ordersRef = await firebase.firestore().collection("orders");
//       let query = ordersRef.orderBy("createdAt", "desc").limit(batchSize);
//       if (lastOrder && lastOrder.lastDocument) {
//         query = query.startAfter(lastOrder.lastDocument);
//       }
//       let isFirstFetch = lastOrder === null;
//       const unsubscribe = query.onSnapshot(async (snapshot) => {
//         const tempData = [];
//         for (const doc of snapshot.docs) {
//           const orderData = doc.data();
//           const userId = orderData.customerId;
//           if (userId) {
//             try {
//               const userDoc = await firebase
//                 .firestore()
//                 .collection("users")
//                 .doc(userId)
//                 .get();
//               const userData = userDoc.data();
//               if (userData) {
//                 tempData.push({
//                   ...orderData,
//                   customerName: userData.username,
//                   orderItems: [],
//                 });
//                 const orderItemsSnapshot = await doc.ref
//                   .collection("orderItems")
//                   .get();

//                 orderItemsSnapshot.forEach((itemDoc) => {
//                   tempData[tempData.length - 1].orderItems.push(itemDoc.data());
//                 });
//               }
//             } catch (error) {
//               console.error("Error fetching user data: ", error);
//             }
//           } else {
//             tempData.push({
//               ...orderData,
//               customerName: "",
//             });
//           }
//         }
//         const lastDocument =
//           snapshot.docs.length > 0
//             ? snapshot.docs[snapshot.docs.length - 1]
//             : null;
//         const newOrder = { lastDocument };
//         const previousOrders = getState().order.allOrders;
//         const newOrders = tempData.filter((order) => {
//           const exists = previousOrders.some(
//             (prevQuestion) => prevQuestion.id === order.orderId
//           );
//           return !exists;
//         });
//         let updatedItems = [];
//         if (isFirstFetch) {
//           isFirstFetch = false;
//           dispatch({
//             type: "GET_ALL_ORDERS",
//             payload: tempData,
//             lastDocument: newOrder,
//             active: true,
//           });
//           await dispatch(getLoader(false));
//         } else if (newOrders.length > 0) {
//           updatedItems = lastOrder
//             ? [...previousOrders, ...tempData]
//             : tempData;
//           dispatch({
//             type: "GET_ALL_ORDERS",
//             payload: updatedItems,
//             lastDocument: newOrder,
//             active: true,
//           });
//           await dispatch(getLoader(false));
//         } else {
//           updatedItems = lastOrder
//             ? [...previousOrders, ...tempData]
//             : tempData;
//           dispatch({
//             type: "GET_ALL_ORDERS",
//             payload: updatedItems,
//             lastDocument: newOrder,
//             active: false,
//           });
//           await dispatch(getLoader(false));
//         }
//       });
//       return () => unsubscribe();
//     } catch (error) {
//       alert(error.message);
//       dispatch(getLoader(false));
//     }
//   };

export const getOrders =
  (lastOrder, uid, role, country, batchSize = 4) =>
  async (dispatch, getState) => {
    console.log(country)
    await dispatch(getLoader(true));
    try {
      const ordersRef = await firebase
        .firestore()
        .collection("orders")
        .where("sellerId", "==", uid);
      let query = ordersRef.orderBy("createdAt", "desc").limit(batchSize);
      if (lastOrder && lastOrder.lastDocument) {
        query = query.startAfter(lastOrder.lastDocument);
      }
      let isFirstFetch = lastOrder === null;
    query.onSnapshot(async (snapshot) => {
        const tempData = [];
        for (const doc of snapshot.docs) {
          const orderData = doc.data();
          const userId = orderData.customerId;

          try {
            const userDoc = await firebase
              .firestore()
              .collection("users")
              .doc(userId)
              .get();
            const userData = userDoc.data();
            console.log('userData: ', userData.country.value);
            console.log('country: ', country);
            console.log('role: ', role);  
            console.log(userData)
            if (userData) {
              if (
                role === "seller" ||
                ((role === "distributor" || role === "distributor-member" || role === 'logistics') &&
                  userData.country.label === country)
              ) {

                tempData.push({
                  ...orderData,
                  customerName: userData.username,
                });
              }
            }
          } catch (error) {
            console.error("Error fetching user data: ", error);
          }
        }
        const lastDocument =
          snapshot.docs.length > 0
            ? snapshot.docs[snapshot.docs.length - 1]
            : null;
        const newOrder = { lastDocument };
        const previousOrders = getState().order.allOrders;
        const newOrders = tempData.filter((order) => {
          const exists = previousOrders.some(
            (prevQuestion) => prevQuestion.id === order.orderId
          );
          return !exists;
        });
        let updatedItems = [];
        if (isFirstFetch) {
          isFirstFetch = false;
          dispatch({
            type: "GET_ALL_ORDERS",
            payload: tempData,
            lastDocument: newOrder,
            active: true,
          });
          await dispatch(getLoader(false));
        } else if (newOrders.length > 0) {
          updatedItems = lastOrder
            ? [...previousOrders, ...tempData]
            : tempData;
          dispatch({
            type: "GET_ALL_ORDERS",
            payload: updatedItems,
            lastDocument: newOrder,
            active: true,
          });
          await dispatch(getLoader(false));
        } else {
          updatedItems = lastOrder
            ? [...previousOrders, ...tempData]
            : tempData;
          dispatch({
            type: "GET_ALL_ORDERS",
            payload: updatedItems,
            lastDocument: newOrder,
            active: false,
          });
          await dispatch(getLoader(false));
        }
      });

    } catch (error) {
      alert(error.message);
      dispatch(getLoader(false));
    }
  };
let unsubscribeOrder = null;
let unsubscribeOrderItems = null;

export const getOrderItems = (orderId) => async (dispatch) => {
  await dispatch(getLoader(true));
  try {
    const orderRef = firebase.firestore().collection("orders").doc(orderId);
    const orderItemsRef = orderRef.collection("orderItems");
    const combinedData = {};
    const orderSnapshot = await orderRef.get();
    combinedData.order = orderSnapshot.data();

    // Check and unsubscribe existing order listener
    if (unsubscribeOrder) {
      unsubscribeOrder();
    }

    unsubscribeOrder = orderRef.onSnapshot(async (orderSnapshot) => {
      combinedData.order = orderSnapshot.data();
      const userDoc = await firebase
        .firestore()
        .collection("users")
        .doc(combinedData.order.customerId)
        .get();
      if (userDoc.exists) {
        combinedData.order.customerName = userDoc.data().username;
      }

      // Check and unsubscribe existing orderItems listener
      if (unsubscribeOrderItems) {
        unsubscribeOrderItems();
      }

      unsubscribeOrderItems = orderItemsRef.onSnapshot(async (snapshot) => {
        const orderItems = [];
        for (const doc of snapshot.docs) {
          const orderItemData = doc.data();
          const itemId = orderItemData.itemId;
          const itemDoc = await firebase
            .firestore()
            .collection("items")
            .doc(itemId)
            .get();
          if (itemDoc.exists) {
            const itemDetails = itemDoc.data();
            const orderItemWithItemDetails = {
              ...orderItemData,
              name: itemDetails.detail.name,
            };
            orderItems.push(orderItemWithItemDetails);
          }
        }
        combinedData.orderItems = orderItems;
        dispatch({
          type: "GET_ORDER_ITEMS",
          payload: combinedData,
        });

        dispatch(getLoader(false));
      });
    });

    return () => {
      if (unsubscribeOrder) {
        unsubscribeOrder();
      }
      if (unsubscribeOrderItems) {
        unsubscribeOrderItems();
      }
    };
  } catch (error) {
    alert(error.message);
    dispatch(getLoader(false));
  }
};

// export const getOrderItems = (orderId, role, country) => async (dispatch) => {
//   await dispatch(getLoader(true));
//   try {
//     const orderRef = firebase.firestore().collection("orders").doc(orderId);
//     const orderItemsRef = orderRef.collection("orderItems");
//     const combinedData = {};
//     const orderSnapshot = await orderRef.get();
//     combinedData.order = orderSnapshot.data();

//     if (unsubscribeOrder) {
//       unsubscribeOrder();
//     }
//     unsubscribeOrder = orderRef.onSnapshot(async (orderSnapshot) => {
//       combinedData.order = orderSnapshot.data();
//       const userDoc = await firebase
//         .firestore()
//         .collection("users")
//         .doc(combinedData.order.customerId)
//         .get();

//       if (userDoc.exists) {
//         const userData = userDoc.data();
//         combinedData.order.customerName = userData.username;
//         if (
//           role === "seller" ||
//           ((role === "distributor" || role === "distributor-member") &&
//             userData.country.value === country)
//         ) {
//           if (unsubscribeOrderItems) {
//             unsubscribeOrderItems();
//           }
//           unsubscribeOrderItems = orderItemsRef.onSnapshot(async (snapshot) => {
//             const orderItems = [];
//             for (const doc of snapshot.docs) {
//               const orderItemData = doc.data();
//               const itemId = orderItemData.itemId;
//               const itemDoc = await firebase
//                 .firestore()
//                 .collection("items")
//                 .doc(itemId)
//                 .get();
//               if (itemDoc.exists) {
//                 const itemDetails = itemDoc.data();
//                 const orderItemWithItemDetails = {
//                   ...orderItemData,
//                   name: itemDetails.detail.name,
//                 };
//                 orderItems.push(orderItemWithItemDetails);
//               }
//             }
//             combinedData.orderItems = orderItems;
//             dispatch({
//               type: "GET_ORDER_ITEMS",
//               payload: combinedData,
//             });
//             dispatch(getLoader(false));
//           });
//         } else {
//           // Set orderItems to an empty array if roles don't match or country doesn't match
//           combinedData.orderItems = [];
//           dispatch({
//             type: "GET_ORDER_ITEMS",
//             payload: combinedData,
//           });
//           dispatch(getLoader(false));
//         }
//       }
//     });

//     return () => {
//       if (unsubscribeOrder) {
//         unsubscribeOrder();
//       }
//       if (unsubscribeOrderItems) {
//         unsubscribeOrderItems();
//       }
//     };
//   } catch (error) {
//     alert(error.message);
//     dispatch(getLoader(false));
//   }
// };
export const refundOrderAction = (paymentId, onSuccess) => async (dispatch) => {
  await dispatch(updateLoader(true));
  try {
    const { data } = await order.refundOrder(paymentId);
    console.log(data);
    if (data?.success == true) {
      alert(data.message);
      dispatch(updateLoader(false));
      onSuccess();
    } else {
      console.log(data.message);
      dispatch(updateLoader(false));
    }
  } catch (error) {
    console.log(error.message);
    dispatch(updateLoader(false));
  }
};
export const upgradeStatus = (payload, onSuccess) => async (dispatch) => {
  await dispatch(updateLoader(true));
  try {
    await firebase
      .firestore()
      .collection("orders")
      .doc(payload.orderId)
      .update({
        status: payload.status,
        history: payload.history,
        updatedAt: payload.updatedAt,
      });
    const batch = firebase.firestore().batch();
    payload.updatedItemStatus.forEach((item) => {
      const orderItem = firebase
        .firestore()
        .collection("orders")
        .doc(payload.orderId)
        .collection("orderItems")
        .doc(item.orderItemId);
      batch.update(orderItem, { status: item.type });
    });
    await batch.commit(); // Commit all updates in batch
    onSuccess();
    alert("Status upgraded successfully!");
    await dispatch(updateLoader(false));
  } catch (error) {
    console.error("Error upgrading status:", error);
    await dispatch(updateLoader(false));
  }
};
const getLoader = (data) => async (dispatch) => {
  dispatch({
    type: "GET_LOADER",
    payload: data,
  });
};
const updateLoader = (data) => async (dispatch) => {
  dispatch({
    type: "UPDATE_LOADER",
    payload: data,
  });
};
