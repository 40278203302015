import { useEffect, useState } from "react";
import useSystemTheme from "./useSystemTheme";

const useLocalStorageTheme = () => {
  // Key for storing theme in local storage
  const THEME_KEY = "theme";

  // Function to get the theme from local storage
  const getThemeFromLocalStorage = () => {
    const storedTheme = localStorage.getItem(THEME_KEY);
    return storedTheme ? storedTheme : "system";
  };

  // Function to set the theme to local storage
  const setThemeToLocalStorage = (theme) => {
    localStorage.setItem(THEME_KEY, theme);
  };

  // State to manage the current theme
  const [theme, setTheme] = useState(getThemeFromLocalStorage);

  // Effect to update the local storage when the theme changes
  useEffect(() => {
    if (theme !== null) {
      setThemeToLocalStorage(theme);
    }
  }, [theme]);

  // Function to toggle between light and dark themes
  const toggleTheme = (theme) => {
    setTheme(theme);
  };

  return { theme, toggleTheme };
};

export default useLocalStorageTheme;
