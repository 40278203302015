import { CircularProgress, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
export default function RadicalTimer({ createdAt }) {
  const [time, setTime] = useState({ hours: 0, minutes: 0 });

  function getTimeComponents(timeSpanInMilliseconds) {
    const totalMinutes = timeSpanInMilliseconds / 60000;
    const hours = Math.floor(totalMinutes / 60);
    const minutes = Math.floor(totalMinutes % 60);
    return { hours, minutes };
  }

  const { timerStopped } = useSelector((state) => state.timerStopped);

  useEffect(() => {
    if (timerStopped) {
      console.log("Timer Stopped!");
    }
  }, [timerStopped]);

  useEffect(() => {
    let remainingTime;

    if (timerStopped) {
      remainingTime =
        createdAt.toDate().getTime() + 72 * 60 * 60 * 1000 - Date.now();
    } else {
      remainingTime =
        createdAt.toDate().getTime() + 24 * 60 * 60 * 1000 - Date.now();
    }

    if (remainingTime >= 0) {
      setTime(getTimeComponents(remainingTime));
      const timer = setInterval(() => {
        const newMinutes = time.minutes - 1;
        setTime((prevTime) => ({
          hours: newMinutes < 0 ? prevTime.hours - 1 : prevTime.hours,
          minutes: newMinutes < 0 ? 59 : newMinutes,
        }));
      }, 60000);
    }
  }, [createdAt, time, timerStopped]);

  const getCircleColor = () => {
    if (time.hours >= 12) return "green";
    if (time.hours >= 3) return "orange";
    return "red";
  };

  const calculateProgress = () => {
    const totalMinutes = time.hours * 60 + time.minutes;
    const remainingMinutes = Math.max(totalMinutes, 0);
    return (remainingMinutes / 1440) * 100;
  };

  const formatTime = (value) => (value < 10 ? `0${value}` : `${value}`);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
      }}
    >
      <>
        <CircularProgress
          variant="determinate"
          value={calculateProgress()}
          sx={{ color: getCircleColor(), zIndex: 2 }}
        />
        <div className="outline"></div>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            zIndex: 2,
          }}
        >
          <p
            className="font-weight-bold m-2"
            style={{
              color: getCircleColor(),
              letterSpacing: "1px",
              fontSize: "0.6rem",
            }}
          >
            {`${formatTime(time.hours)}:${formatTime(time.minutes)}`}
          </p>
        </Box>
      </>
    </Box>
  );
}
