/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import CreateItem from "components/Item/CreateItem";
import AskQuestions from "views/AskQuestions";
import CustomerSupport from "views/CustomerSupport";
import Distributors from "views/Distributors";
import Finance from "views/Finance";
import Index from "views/Index.js";
import OrderDetails from "views/OrderDetails";
import Orders from "views/Orders";
import ProductReviews from "views/ProductReviews";
import Products from "views/Products";
import Profile from "views/Profile.js";
import Promotion from "views/Promotion";
import Reviews from "views/Reviews";
import Returns from "views/Returns";
import TeamManagement from "views/TeamManagement";
import TicketDetail from "views/TicketDetail";
import Login from "views/auth/Login.js";
import Register from "views/auth/Register.js";
import Tickets from "views/Tickets";
import { role } from "constant/teamRoles";
import MultivendorSupport from "views/MultivendorSupport";
import TeamManagementModal from "components/Models/TeamManagementModal";
import { ReportAnIssueModal } from "components/Models/ReportAnIssueModel";
import SupportDetail from "views/SupportDetail";
var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/admin",
    roles: [
      "distributor",
      "seller",
      "distributor-member",
      role?.LOGISTICS,
      role?.FINANCE,
    ],
  },
  // {
  // 	path: '/icons',
  // 	name: 'Icons',
  // 	icon: 'ni ni-planet text-blue',
  // 	component: Icons,
  // 	layout: '/admin',
  // },
  // {
  // 	path: '/maps',
  // 	name: 'Maps',
  // 	icon: 'ni ni-pin-3 text-orange',
  // 	component: Maps,
  // 	layout: '/admin',
  // },
  // {
  // 	path: '/user-profile',
  // 	name: 'User Profile',
  // 	icon: 'ni ni-single-02 text-yellow',
  // 	component: Profile,
  // 	layout: '/admin',
  // },
  // {
  // 	path: '/tables',
  // 	name: 'Tables',
  // 	icon: 'ni ni-bullet-list-67 text-red',
  // 	component: Tables,
  // 	layout: '/admin',
  // },
  {
    path: "/products",
    name: "Products",
    icon: "fas fa-list-ul text-primary",
    component: Products,
    layout: "/admin",
    roles: ["seller"],
  },
  {
    path: "/orders",
    name: "Orders",
    icon: "ni ni-cart text-orange",
    component: Orders,
    layout: "/admin",
    roles: ["distributor", "seller", "distributor-member", role.LOGISTICS],
  },
  {
    path: "/finance",
    name: "Finance",
    component: Finance,
    icon: "fas fa-chart-line text-cyan",
    layout: "/admin",
    roles: ["seller", role.FINANCE],
  },
  {
    path: "/tickets",
    name: "Tickets",
    icon: "fas fa-ticket-alt text-purple",
    component: Tickets,
    layout: "/admin",
    roles: ["seller", role.CUSTOMER_SUPPORT],
  },
  {
    path: "/ticket-detail/:id",
    name: "Detail",
    icon: "fas fa-info text-primary",
    component: TicketDetail,
    layout: "/admin",
    isMenu: false,
  },
  {
    path: "/promotion",
    name: "Promotions",
    icon: "ni ni-notification-70 text-yellow",
    component: Promotion,
    layout: "/admin",
    roles: ["seller"],
  },
  {
    path: "/ask-questions",
    name: "Product Q&A",
    component: AskQuestions,
    icon: "fas fa-question text-success",
    layout: "/admin",
    roles: ["seller", role.CUSTOMER_SUPPORT],
  },
  {
    path: "/reviews",
    name: "Reviews",
    component: Reviews,
    icon: "fas fa-star text-info",
    layout: "/admin",
    roles: ["seller", role.CUSTOMER_SUPPORT],
  },
  {
    path: "/returns",
    name: "Returns",
    component: Returns,
    icon: "fas fa-arrow-right text-purple",
    layout: "/admin",
    roles: ["seller"],
  },
  {
    path: "/distributors",
    name: "Distributors",
    icon: "ni ni-delivery-fast text-red",
    component: Distributors,
    layout: "/admin",
    roles: ["seller", role.LOGISTICS],
  },
  {
    path: "/team-management",
    name: "Team Management",
    icon: "fas fa-users text-primary",
    component: TeamManagement,
    layout: "/admin",
    roles: ["seller"],
  },
  {
    path: "/fullylocal-support",
    name: "Fullylocal Support",
    icon: "fas fa-headset text-primary",
    component: MultivendorSupport,
    layout: "/admin",
    roles: ["seller"],
  },
  {
    path: "/support-detail/:id",
    name: "SuportDetail",
    icon: "fas fa-info text-primary",
    component: SupportDetail,
    layout: "/admin",
    isMenu: false,
  },
  {
    path: "/customer-support",
    name: "Customer Support",
    component: CustomerSupport,
    icon: "fas fa-headset text-primary",
    layout: "/admin",
    isMenu: false,
    roles: ["seller", role.CUSTOMER_SUPPORT],
  },

  {
    path: "/team-members",
    name: "Team Members",
    icon: "ni ni-delivery-fast text-red",
    component: Distributors,
    layout: "/admin",
    roles: ["distributor"],
  },
  // {
  //   path: "/report-an-issue",
  //   name: "Report An Issue",
  //   // icon: "fas fa-bug text-orange",
  //   component: ReportAnIssueModal,
  //   layout: "/admin",
  //   isMenu: false,
  // },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
    isMenu: false,
  },
  {
    path: "/create-item",
    name: "Create Item",
    component: CreateItem,
    layout: "/admin",
    isMenu: false,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    layout: "/admin",
    isMenu: false,
  },
  {
    path: "/product-reviews/:id",
    name: "Detail",
    component: ProductReviews,
    layout: "/admin",
    isMenu: false,
  },

  {
    path: "/edit-item/:id",
    name: "Edit Item",
    component: CreateItem,
    layout: "/admin",
    isMenu: false,
  },
  {
    path: "/order-details/:id",
    name: "OrderDetails",
    component: OrderDetails,
    layout: "/admin",
    isMenu: false,
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth",
    isMenu: false,
  },
];
export default routes;
