import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Spinner,
} from "reactstrap";
import _ from "lodash";
import OnlyHeader from "components/Headers/OnlyHeader";
import TabSelection from "components/TeamManagement/TabSelection";
import ReviewCard from "components/Reviews/ReviewCard";
import ReviewsTable from "components/Reviews/ReviewsTable";
import { role } from "constant/teamRoles";
import { getReviews } from "../store/actions/reviewsAction";

const Reviews = () => {
  const dispatch = useDispatch();
  const { allReviews, loading, lastDocument, active } = useSelector(
    (state) => state.review
  );

  const { uid, user } = useSelector((state) => state.authUser);

  const [selectedReviews, setSelectedReviews] = useState("Product Reviews");
  const [selectedReviewsType, setSelectedReviewsType] = useState("published");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [lastDocuments, setLastDocuments] = useState("");
  const [filterBy, setFilterBy] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("");

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const handleLoadMore = () => {
    let id = user.role === role.CUSTOMER_SUPPORT ? user.creatorId : uid;
    let clearState = false;
    let collection =
      selectedReviews === "Product Reviews"
        ? "productReviews"
        : "sellerReviews";

    dispatch(
      getReviews(
        id,
        selectedReviewsType,
        collection,
        clearState,
        lastDocuments,
        "",
        ""
      )
    );
  };

  const handleSortSelection = (sortOption) => {
    setFilterBy("");

    if (sortOption === "Highest") {
      setSortBy("rating");
      setSortOrder("desc");
    } else if (sortOption === "Lowest") {
      setSortBy("rating");
      setSortOrder("asc");
    }
  };

  const handleFilterSelection = (filterBy) => {
    setFilterBy(filterBy);
    let collection =
      selectedReviews === "Product Reviews"
        ? "productReviews"
        : "sellerReviews";
    dispatch(
      getReviews(uid, selectedReviewsType, collection, true, null, filterBy)
    );
  };

  const sortedReviews = allReviews.slice().sort((a, b) => {
    if (sortBy === "rating") {
      const ratingA = a.rating || 0;
      const ratingB = b.rating || 0;

      if (sortOrder === "asc") {
        return ratingA - ratingB;
      } else {
        return ratingB - ratingA;
      }
    }
    return 0;
  });

  useEffect(() => {
    setLastDocuments(lastDocument);
  }, [lastDocument]);

  useEffect(() => {
    if (user) {
      let id = user.role === role.CUSTOMER_SUPPORT ? user.creatorId : uid;
      let clearState = true;
      let collection =
        selectedReviews === "Product Reviews"
          ? "productReviews"
          : "sellerReviews";

      dispatch(
        getReviews(
          id,
          selectedReviewsType,
          collection,
          clearState,
          null,
          filterBy,
          null
        )
      );
    }
  }, [
    user,
    uid,
    selectedReviewsType,
    selectedReviews,
    filterBy,
    sortOrder,
    sortBy,
  ]);

  return (
    <>
      <OnlyHeader />
      <Container className="mt--9 position-absolute" fluid>
        <div className="mb-2">
          <TabSelection
            options={reviews}
            onChange={(value) => setSelectedReviews(value)}
          />
        </div>
        <div>
          <TabSelection
            options={reviewsType}
            onChange={(value) => setSelectedReviewsType(value)}
          />
        </div>
      </Container>
      <Container fluid className="mt--4">
        <Row>
          <Col>
            <Card className="shadow-sm">
              <CardHeader className="border-0 d-flex justify-content-between align-items-center">
                <h3 className="mb-0">
                  {_.capitalize(selectedReviewsType)} Reviews{" "}
                  {selectedReviewsType === "recents" && (
                    <span className="text-danger text-xs px-2">
                      (Reviews published after 24h if disputed)
                    </span>
                  )}
                </h3>
                <div className="d-flex">
                  <Dropdown
                    isOpen={dropdownOpen}
                    toggle={toggle}
                    direction={"down"}
                  >
                    <DropdownToggle className="text-capitalize d-flex align-items-center justify-content-between flex-row-reverse">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-filter ml-1"
                        viewBox="0 0 16 16"
                      >
                        <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5m-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5" />
                      </svg>{" "}
                      {"Filter"}
                    </DropdownToggle>
                    <DropdownMenu>
                      {["All", "Newest", "Highest", "Lowest"].map((filter) => (
                        <DropdownItem
                          key={filter}
                          onClick={() => {
                            if (filter === "All" || filter === "Newest") {
                              handleFilterSelection(filter);
                            } else {
                              handleSortSelection(filter);
                            }
                          }}
                        >
                          {filter}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </Dropdown>
                  <Input
                    className=" float-right mt-1 ml-5 searchBar"
                    type="text"
                    placeholder="Search"
                  />
                </div>
              </CardHeader>

              <CardBody>
                {selectedReviewsType === "published" ? (
                  <ReviewCard allReviews={sortedReviews} />
                ) : (
                  <ReviewsTable
                    allReviews={sortedReviews}
                    reviewType={selectedReviewsType}
                    selectedReviews={selectedReviews}
                  />
                )}

                {allReviews.length ? (
                  active ? (
                    <div className="w-100 d-flex justify-content-center my-2">
                      {loading ? (
                        <Spinner size={"sm"} className="my-2" />
                      ) : (
                        <Button
                          onClick={handleLoadMore}
                          className="mx-4 text-secondary"
                          color="success"
                        >
                          Load more
                        </Button>
                      )}
                    </div>
                  ) : (
                    <div className="w-100 d-flex justify-content-center my-2">
                      <p>
                        Looks like you've seen it all! Nothing more to load.
                      </p>
                    </div>
                  )
                ) : loading ? (
                  <div className="w-100 d-flex justify-content-center my-2">
                    <Spinner size={"sm"} className="my-2" />
                  </div>
                ) : (
                  <Row className="justify-content-center my-3">
                    <p>No Reviews Yet. Looks like you're new here!</p>
                  </Row>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

const reviews = ["Product Reviews", "Seller Reviews"];
const reviewsType = ["published", "recents", "disputes"];

export default Reviews;
