import Repository from "./Repository";
const PAYMENTS = "/payments";
const CONNECTACCOUNTS = "/payments/connect-account";
export default {
  getCountrySpecs(country) {
    return Repository.get(`${CONNECTACCOUNTS}/country-specs/${country}`);
  },
  connectAccount(payload) {
    return Repository.post(`${CONNECTACCOUNTS}`, payload);
  },
  getConnectAccounts(uid) {
    return Repository.get(`${CONNECTACCOUNTS}/${uid}`);
  },
  verifyAccountStatus(uid) {
    return Repository.get(`${CONNECTACCOUNTS}/status/${uid}`);
  },
  getAvailableAmount(uid) {
    return Repository.get(`${CONNECTACCOUNTS}/balance/${uid}`);
  },
  withdrawBalance(uid) {
    return Repository.post(`${CONNECTACCOUNTS}/withdraw/${uid}`);
  },

};
