import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Badge, Button, Col, Form, Row, Spinner } from "reactstrap";
import { connectAccount } from "store/actions/paymentsAction";
import { getFieldName } from "utils/getFieldName";
import { getFieldType } from "utils/getFieldType";
import { reorderFields } from "utils/reorderFields";
import { FileInput } from "./FileInput";
import { FormField } from "./FormField";
import { verifyAccountStatus } from "store/actions/paymentsAction";
import { getConnectAccounts } from "store/actions/paymentsAction";
import { getCountrySpecs } from "store/actions/paymentsAction";

const ConnectAccountForm = () => {
  const dispatch = useDispatch();

  //use selector
  const { user, uid } = useSelector((state) => state.authUser);
  const { connectAccountFields, connectAccounts, accountStatus } = useSelector(
    (state) => state.payments
  );

  // states
  const [stripeObj, setStripeObj] = useState(null);
  const [fields, setFields] = useState([]);
  const [stripeLoading, setStripeLoading] = useState(false);
  const [showFields, setShowFields] = useState(false);

  //functions
  const handleInputChange = (e) => {
    const value = e.target.value;
    setStripeObj({
      ...stripeObj,
      [e.target.id]: value,
    });
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile && selectedFile.type === "application/pdf") {
      setStripeObj({
        ...stripeObj,
        [e.target.id]: selectedFile,
      });
    } else {
      alert("Please select a valid PDF file.");
    }
  };

  // submit stripe
  const handleStripeSubmit = (e, file) => {
    e.preventDefault();
    if (file && !stripeObj.file) return alert("Please attach a valid pdf file");
    const userDob = new Date(user?.dob);
    const day = userDob.getDate();
    const month = userDob.getMonth() + 1;
    const year = userDob.getFullYear();

    setStripeLoading(true);

    let formDataObj = {
      mcc: 5045,
      url: "https://bestcookieco.com",
      business_type: "individual",
      type: "custom",
      ip: "172.18.80.19",
      country: user?.country.iso2,
      card_payments: true,
      transfers: true,
      currency: "USD",
      day,
      month,
      year,
      email: user?.email,
      userId: uid,
    };
    let stripeFormData = new FormData();

    for (const prop in formDataObj) {
      stripeFormData.append(prop, formDataObj[prop]);
    }

    for (let key of Object.keys(stripeObj)) {
      let keyToAdd = key.split(".").at(-1);
      stripeFormData.append(keyToAdd, stripeObj[key]);
    }

    dispatch(connectAccount(stripeFormData));
    setStripeLoading(false);
  };

  //verify the account status, and render stripe fields
  useEffect(() => {
    if (connectAccountFields.length) {
      const filteredFields = connectAccountFields.filter(
        (field) =>
          !excludedFieldSuffixes.includes(field.split(".").pop()) &&
          !removeFields.includes(field)
      );

      const reorderedFields = reorderFields(filteredFields, reOrderBy);
      setFields(reorderedFields);
    } else {
      setFields([]);
    }
  }, [connectAccountFields]);

  // get the connect accounts
  useEffect(() => {
    if (!user || !user.country) return;

    dispatch(getCountrySpecs(user.country.iso2));
    dispatch(getConnectAccounts(uid));
    dispatch(verifyAccountStatus(uid));
  }, [user, uid]);

  return !user.country || !user.dob ? (
    <div>
      <h2 className="text-center p-3">
        Ready for Stripe? First, Complete Your Profile! Add Your Birthdate and
        Country to Get Started.
      </h2>
    </div>
  ) : (
    <div className="">
      {connectAccountFields === -1 ||
      connectAccounts === -1 ||
      accountStatus === -1 ? (
        <h1>Loading...</h1>
      ) : (
        <>
          <div className="connected-accounts-container">
            {connectAccounts.length > 0 &&
              connectAccounts.map((account) => (
                <div className="account-box" key={account.id}>
                  <div className="account-info">
                    <div className="info-section">
                      <p className="label">Bank:</p>
                      <p className="value">{account.bank_name}</p>
                    </div>
                    <div className="info-section">
                      <p className="label">Connected Account:</p>
                      <p className="value">***********{account.last4}</p>
                    </div>
                    <div className="info-section">
                      <p className="label">Status:</p>
                      <p
                        className={`value ${
                          accountStatus?.status === "verified"
                            ? "text-green"
                            : "text-red"
                        }`}
                      >
                        {accountStatus?.status}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          {connectAccounts.length === 0 ? (
            <div className="container mt-2 mb-4">
              <div
                className="card border-0 shadow-lg mx-auto"
                style={{ maxWidth: "600px" }}
              >
                <div className="card-body text-center">
                  {fields?.length === 0 ? (
                    <>
                      <h3 className="card-title mb-4 text-danger">
                        Stripe Unavailable
                      </h3>
                      <p className="card-text text-muted">
                        We regret to inform you that Stripe is not currently
                        available in your country.
                      </p>
                    </>
                  ) : (
                    <>
                      <h3 className="card-title mb-4">Welcome</h3>
                      <p className="card-text mb-4">
                        Ready to get started? Create your Stripe account now.
                      </p>
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={() =>
                          fields?.length !== 0 && setShowFields(!showFields)
                        }
                      >
                        Create Account
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          ) : (
            accountStatus &&
            accountStatus.status !== "verified" && (
              <h3 className="mt-3 border text-center">
                The stripe account provided can not be used to charge, because
                it is not verified yet.
              </h3>
            )
          )}

          <div>
            {/* <div className="mt-6">
          {connectAccountData?.requirements?.currently_due.length > 0 &&
            reTry &&
            connectAccountData?.requirements?.errors.map((item, i) => (
              <div>
                <h2 className="font-semibold">
                  Account was not saved Please provide valid data for below
                  fields:
                </h2>
                <p key={i} className="text-red-600">
                  {i + 1} {item.reason}
                </p>
              </div>
            ))}
        </div> */}

            <div>
              {!accountStatus && showFields && (
                <Form onSubmit={(e) => handleStripeSubmit(e, "file")}>
                  <Row className="d-flex flex-wrap w-100 mt-6 ">
                    {fields?.map((field) => (
                      <Col sm={12} md={6} className="mt-4">
                        <FormField
                          key={field}
                          label={
                            getFieldName(field) === "Name"
                              ? "Business Profile Name"
                              : getFieldName(field)
                          }
                          id={field}
                          type={getFieldType(field)}
                          placeholder={getFieldName(field)}
                          onChange={handleInputChange}
                          required
                        />
                      </Col>
                    ))}
                    <Col sm={12} md={6} className="mt-4">
                      <FormField
                        label="Account Number"
                        id="ibn_number"
                        type="text"
                        placeholder="Account Number"
                        onChange={handleInputChange}
                        required
                      />
                    </Col>
                    <Col sm={12} md={6} className="mt-4">
                      <FormField
                        label="Routing Number"
                        id="routing_number"
                        type="text"
                        placeholder="Routing Number"
                        onChange={handleInputChange}
                        required
                      />
                    </Col>
                    <Col sm={12} md={6} className="mt-4">
                      <FileInput
                        stripeObj={stripeObj}
                        id="file"
                        onChange={handleFileChange}
                      />
                    </Col>
                  </Row>

                  <div className="flex mt-4">
                    <Button
                      type="submit"
                      color="primary"
                      className="float-right"
                    >
                      {stripeLoading ? <Spinner size="sm" /> : "Save"}
                    </Button>
                  </div>
                </Form>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default ConnectAccountForm;

const excludedFieldSuffixes = [
  "mcc",
  "url",
  "business_type",
  "external_account",
  "ip",
  "day",
  "month",
  "year",
  "date",
  "email",
  "document",
];

const removeFields = [
  "company.name",
  "company.address.city",
  "company.address.line1",
  "company.address.postal_code",
  "company.address.state",
  "business_profile.product_description",
  "settings.card_payments.statement_descriptor_prefix",
  "settings.payments.statement_descriptor",
];

const reOrderBy = [
  "individual.first_name",
  "individual.last_name",
  "business_profile.name",
  "individual.phone",
  "individual.address.city",
  "individual.address.state",
  "individual.address.postal_code",
  "individual.address.line1",
];
