const initialData = {
  loading: false,
  allReviews: [],
  lastDocument: {},
  active: true,
  filterBy: [], 
};

const itemReducer = (state = initialData, action) => {
  switch (action.type) {
    case "LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    case "GET_REVIEWS":
      return {
        ...state,
        allReviews: action.payload,
        active: action.active,
        lastDocument: action.lastDocument,
        filterBy: action.filterBy, 
      };
    default:
      return state;
  }
};

export default itemReducer;
