export function reorderFields(fields, orderBy) {
  let tempFields = [...fields];
  // Create a mapping of field names to their index in orderBy array
  const orderMap = {};
  orderBy.forEach((field, index) => {
    orderMap[field] = index;
  });

  // Sort the _fields array based on the order specified in orderBy
  let reorderedFields = tempFields.sort((a, b) => {
    const indexA = orderMap[a];
    const indexB = orderMap[b];

    // If both elements are in the orderBy array, compare their indices
    if (indexA !== undefined && indexB !== undefined) {
      return indexA - indexB;
    }

    // If only one element is in the orderBy array, prioritize it
    if (indexA !== undefined) {
      return -1;
    } else if (indexB !== undefined) {
      return 1;
    }

    // If neither element is in the orderBy array, maintain the original order
    return 0;
  });

  return reorderedFields;
}
