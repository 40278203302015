const initialData = {
  loading: false,
  itemUploadingProgress: -1,
  deleteLoading: false,
  allItems: [],
  selectedCategories: [],
  sellerReviewsItems: [],
  singleItem: {},
  lastDocument: {},
  active: true,
  promotionItems: [],
  colorList: [],
};
const itemReducer = (state = initialData, action) => {
  switch (action.type) {
    case "LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    case "GET_ALL_ITEMS":
      return {
        ...state,
        allItems: action.payload,
        active: action.active,
        lastDocument: action.lastDocument,
      };

    case "PROMOTION_ITEMS":
      return {
        ...state,
        promotionItems: action.payload,
      };
    case "GET_SELLER_REVIEWS_ITEMS":
      return {
        ...state,
        sellerReviewsItems: action.payload,
      };
    case "GET_SELECTED_CATEGORY":
      return {
        ...state,
        selectedCategories: action.payload,
      };
    case "DELETE_LOADING":
      return {
        ...state,
        deleteLoading: action.payload,
      };
    case "GET_SINGLE_ITEM":
      return {
        ...state,
        singleItem: action.payload,
      };
    case "GET_ALL_COLORS":
      return {
        ...state,
        colorList: action.payload,
      };
    case "CLEAR_SINGLE_ITEM":
      return {
        ...state,
        singleItem: action.payload,
      };
    case "ITEM_UPLOADING_PROGRESS":
      return {
        ...state,
        itemUploadingProgress: action.payload,
      };
    default:
      return state;
  }
};

export default itemReducer;
