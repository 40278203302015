import React, { useEffect, useRef } from "react";

const MessageInput = ({ disabled, value, onChange }) => {
  const textareaRef = useRef(null);

  useEffect(() => {
    adjustTextareaHeight();
  }, [value]);

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  const handleChange = (e) => {
    onChange(e);
    adjustTextareaHeight();
  };

  return (
    <div className="message-input-container bg-white mx-2 py-2">
      <textarea
        ref={textareaRef}
        id="prompt-textarea"
        tabIndex="0"
        data-id="root"
        rows={1}
        placeholder="Send a message"
        className="message-input"
        value={value}
        onChange={handleChange}
        disabled={disabled}
      ></textarea>
    </div>
  );
};

export default MessageInput;
