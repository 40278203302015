import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { logout } from "store/actions/authAction";

const OnlyHeader = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  function getCookieValue(cookieName) {
    const cookies = document.cookie.split("; ");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const [name, value] = cookie.split("=");
      if (name === cookieName) {
        return decodeURIComponent(value);
      }
    }
    return null;
  }
  useEffect(() => {
    const cboShortSessionCookie = getCookieValue("cbo_short_session");
    if (!cboShortSessionCookie) {
      dispatch(logout(history,""));
    }
  }, []);
  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8"></div>
    </>
  );
};

export default OnlyHeader;
