import { faStore } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useSelector } from "react-redux";
import { Button } from "reactstrap";

function Store() {
  const { uid } = useSelector((state) => state.authUser);
  return (
    <>
      <Button         className="rounded py-1 px-2 text-xs m-0 mr-2 btn-secondary text-primary"
>
        <a
          href={`https://fullylocal-main-44c14dd4484e.herokuapp.com/vendor-store/${uid}`}
          target="_blank"
        >
          <FontAwesomeIcon icon={faStore}/>
        </a>
      </Button>
    </>
  );
}

export default Store;
