import OnlyHeader from "components/Headers/OnlyHeader";
import Details from "components/ItemSteps/Details";
import Guides from "components/ItemSteps/Guides";
import React, { useState } from "react";

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Progress,
  Row,
  Spinner,
} from "reactstrap";
import { EditorState } from "draft-js";
import RecommendedProducts from "components/ItemSteps/RecommendedProducts";
import QueAns from "components/ItemSteps/QueAns";
import RelatedTools from "components/ItemSteps/RelatedTools";
import ThreeSixtyViewer from "components/ItemSteps/ThreeSixtyViewer";
import CADFile from "components/ItemSteps/CADFile";
import {
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createItem,
  editItem,
  getSingleItem,
} from "../../store/actions/itemAction";
import { ContentState, convertFromHTML } from "draft-js";
import Calculator from "../../components/ItemSteps/Calculator";
import Repository from "../../components/ItemSteps/Repository";
import { convertToHTML } from "draft-convert";
import { MdArrowBackIosNew } from "react-icons/md";
export default function CreateItem() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { loading, singleItem, itemUploadingProgress } = useSelector(
    (state) => state.item
  );

  const [modalOpen, setModalOpen] = useState(false);

  const { uid } = useSelector((state) => state.authUser);
  const location = useLocation();
  const history = useHistory();
  const moduleDetail = location?.state;
  const [detail, setDetail] = useState({
    name: "",
    price: 0,
    rating: 0,
    description: "",
    categoryId: "",
    ecoSystem: "Zigbee",
    power: "Battery",
    shippingTimeLocal: "Days",
    shippingTimeInternational: "Weeks",
    images: [],
    color: [],
    videoFile: null,
  });
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [convertedEditorContent, setConvertedEditorContent] = useState("");
  const [selectedRecommedProduct, setSelectedRecommendProduct] = useState([]);
  const [questionAnswer, setQuestionAnswer] = useState([
    { question: "", answer: "" },
  ]);
  const [relatedTool, setRelatedTool] = useState([]);
  const [threeSixtyUrl, setThreeSixtyUrl] = useState("");
  const [cadFile, setCadFile] = useState("");
  const [calculator, setCalculator] = useState([]);
  const [repository, setRepository] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      convertedEditorContent &&
      questionAnswer.length &&
      relatedTool.length &&
      threeSixtyUrl &&
      cadFile &&
      detail.name &&
      detail.price &&
      detail.description &&
      detail.images.length &&
      detail.color.length &&
      calculator.length &&
      repository.length
    ) {
      setModalOpen(true);
      const item = {
        detail: detail,
        guide: convertedEditorContent,
        recommendedProduct: selectedRecommedProduct,
        relatedTool: relatedTool,
        questionAnswer: questionAnswer,
        threeSixtyUrl: threeSixtyUrl,
        cadFile: cadFile,
        creatorId: uid,
        calculator: calculator,
        repository: repository,
      };
      dispatch(
        createItem(item, () => {
          setModalOpen(false);
          alert("Item created successfully!");
          history.push("/admin/products");
        })
      );
    } else {
      alert("Every section is necessary!");
    }
  };
  const handleEdit = (e) => {
    e.preventDefault();
    let item = null;
    if (
      detail.name &&
      detail.price &&
      detail.description &&
      detail.images.length &&
      detail.color.length
    ) {
      item = {
        detail: detail,
        guide:
          convertedEditorContent == ""
            ? convertToHTML(editorState.getCurrentContent())
            : convertedEditorContent,
        recommendedProduct: selectedRecommedProduct,
        relatedTool: relatedTool,
        questionAnswer: questionAnswer,
        threeSixtyUrl: threeSixtyUrl,
        cadFile: cadFile,
        creatorId: uid,
        calculator: calculator,
        repository: repository,
        createdAt: singleItem.createdAt,
      };
    } else {
      alert("Every section is necessary!");
    }

    if (item) {
      dispatch(
        editItem(item, id, () => {
          history.push("/admin/products");
        })
      );
    }
  };

  useEffect(() => {
    if (singleItem) {
      if (singleItem?.detail) {
        let updatedImages = [];
        singleItem?.detail?.images?.map((img, index) => {
          updatedImages.push({
            uid: index,
            name: "image.png",
            status: "done",
            url: img,
          });
        });
        setDetail({ ...singleItem?.detail, images: updatedImages });
      }
      if (singleItem?.guide) {
        const contentState = ContentState.createFromBlockArray(
          convertFromHTML(singleItem?.guide)
        );
        const editorStateWithContent =
          EditorState.createWithContent(contentState);
        setEditorState(editorStateWithContent);
      }
      if (
        singleItem?.recommendedProduct &&
        singleItem?.recommendedProduct.length > 0
      ) {
        setSelectedRecommendProduct(singleItem?.recommendedProduct);
      }
      if (singleItem?.relatedTool && singleItem?.relatedTool.length > 0) {
        setRelatedTool(singleItem?.relatedTool);
      }
      if (singleItem?.calculator && singleItem?.calculator.length > 0) {
        setCalculator(singleItem?.calculator);
      }
      if (singleItem?.repository && singleItem?.repository.length > 0) {
        setRepository(singleItem?.repository);
      }
      if (singleItem?.questionAnswer && singleItem?.questionAnswer.length > 0) {
        setQuestionAnswer(singleItem?.questionAnswer);
      }
      if (singleItem?.cadFile) {
        setCadFile(singleItem?.cadFile);
      }
      if (singleItem?.threeSixtyUrl) {
        setThreeSixtyUrl(singleItem?.threeSixtyUrl);
      }
    }
  }, [singleItem]);
  useEffect(() => {
    if (id) {
      dispatch(getSingleItem(id));
    }
  }, [id]);
  useEffect(() => {
    if (location?.state == undefined) {
      history.push("/admin/products");
    }
  }, [location?.state]);

  return (
    <>
      <OnlyHeader />
      <Container fluid className="mt--6">
        <Row>
          <Col>
            <Card className="shadow-sm">
              <CardHeader>
                <MdArrowBackIosNew
                  className="mx-2"
                  style={{ cursor: "pointer" }}
                  onClick={() => history.goBack()}
                />
                {moduleDetail}
              </CardHeader>
              <CardBody>
                <Details detail={detail} setDetail={setDetail} />
                <Guides
                  editorState={editorState}
                  setEditorState={setEditorState}
                  setConvertedEditorContent={setConvertedEditorContent}
                />
                <RecommendedProducts
                  selectedRecommedProduct={selectedRecommedProduct}
                  setSelectedRecommendProduct={setSelectedRecommendProduct}
                />
                <Calculator
                  calculator={calculator}
                  setCalculator={setCalculator}
                />
                <Repository
                  repository={repository}
                  setRepository={setRepository}
                />
                <RelatedTools
                  relatedTool={relatedTool}
                  setRelatedTool={setRelatedTool}
                />
                <QueAns
                  questionAnswer={questionAnswer}
                  setQuestionAnswer={setQuestionAnswer}
                />
                <ThreeSixtyViewer
                  threeSixtyUrl={threeSixtyUrl}
                  setThreeSixtyUrl={setThreeSixtyUrl}
                />
                <CADFile cadFile={cadFile} setCadFile={setCadFile} />
              </CardBody>
              <CardFooter>
                {moduleDetail == "Create Item" ? (
                  <>
                    <Button
                      color="primary"
                      className="px-5 text-secondary float-right"
                      disabled={loading}
                      onClick={(e) => {
                        handleSubmit(e);
                      }}
                    >
                      {loading ? <Spinner size="md" /> : "Create"}
                    </Button>
                    <Modal
                      isOpen={modalOpen}
                      toggle={() => setModalOpen(!modalOpen)}
                    >
                      <ModalHeader toggle={() => setModalOpen(!modalOpen)}>
                        uploading
                      </ModalHeader>
                      <ModalBody>
                        {itemUploadingProgress !== -1 &&
                          itemUploadingProgress < 100 && (
                            <Progress
                              animated
                              color="primary"
                              striped
                              value={itemUploadingProgress}
                              className="mt-2"
                            />
                          )}
                      </ModalBody>
                    </Modal>
                  </>
                ) : (
                  <>
                    <Button
                      className="px-5 float-right"
                      color="primary"
                      disabled={loading}
                      onClick={(e) => {
                        handleEdit(e);
                      }}
                    >
                      {loading ? <Spinner size="md" /> : "Update"}
                    </Button>
                  </>
                )}
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
