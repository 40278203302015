import React, { useState } from "react";
import { Button, Form } from "reactstrap";
import MessageInput from "./MessageInput";

const ChatFooter = ({ onSendClick }) => {
  const [newMessage, setNewMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (newMessage.trim() !== "") {
      // Ensure message is not empty before sending
      onSendClick(newMessage);
      setNewMessage("");
    }
  };

  return (
    <Form onSubmit={handleSubmit} className="d-flex border rounded mt-3">
      <div className="d-flex align-items-center w-100 bg-white rounded position-relative">
        <MessageInput
          disabled={false}
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
        />
        <Button
          className={`bg-primary rounded border-0 message-send-btn`}
          type="submit"
          disabled={newMessage.trim() === ""} // Disable button if message is empty
        >
          <i className="fas text-white fa-paper-plane"></i>
        </Button>
      </div>
    </Form>
  );
};

export default ChatFooter;
