import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import ReactStars from "react-rating-stars-component";
import moment from "moment";

export default function ReviewCard({ allReviews }) {
  return (
    <>
      {allReviews &&
        allReviews.map((item) => {
          return (
            <Row>
              <Col className="d-flex">
                <Card
                  className="shadow-sm flex-fill mb-2"
                  color="primary"
                  outline
                >
                  <CardBody>
                    <Row>
                      <Col sm={6} xs={5}>
                        <h3 className="heading-product fs--16 mt-1 mb-0">
                          <strong>{item?.buyerName}</strong>
                        </h3>
                        <span
                          className="fs--13 text-muted"
                          style={{ letterSpacing: "1px" }}
                        >
                          {" "}
                          {moment
                            .unix(item?.createdAt.seconds)
                            .format("DD/MM/YYYY")}
                        </span>
                        <ReactStars
                          count={5}
                          value={item?.rating || 0}
                          size={24}
                          activeColor="#ffd700"
                          isHalf={true} // Set to true if you want to allow half-star ratings
                          edit={false}
                        />
                      </Col>
                      {/* <Col sm={6} xs={7}>
                        <div className="float-right">
                          <DisappearingButton createdAt={item?.createdAt} />
                        </div>
                      </Col> */}
                    </Row>
                    {/* <p className="fs--13">OrderId:&nbsp;{item?.orderId}</p> */}

                    <p className="fs--13 mt-2">
                      <strong>{item?.description}</strong>
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          );
        })}
    </>
  );
}
