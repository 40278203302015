import DistributorRepository from "./DistributorRepository";
import CountryRepository from "./CountryRepository";
import OrderRepository from "./OrderRepository";
import dashboardRepository from "./dashboardRepository";
import TicketsRepository from "./TicketsRepository";
import PaymentsRepository from "./PaymentsRepository";
const repositories = {
  distributor: DistributorRepository,
  country: CountryRepository,
  order: OrderRepository,
  dashboard: dashboardRepository,
  tickets: TicketsRepository,
  payments: PaymentsRepository,
};
export const RepositoryFactory = {
  get: (name) => repositories[name],
};
