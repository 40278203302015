const initialData = {
  connectAccountFields: -1,
  connectAccounts: -1,
  accountStatus: -1,
  accountBalance: -1,
  allPaymentLogs: [],
  lastDocument: {},
  active: true,
  loader: false,
  amountToWithdraw: {},
  withdrawBalance: -1,
};
const paymentsReducer = (state = initialData, action) => {
  switch (action.type) {
    case "GET_CONNECT_ACCOUNTS":
      return {
        ...state,
        connectAccounts: action.payload,
      };
    case "VERIFY_CONNECT_ACCOUNTS":
      return {
        ...state,
        accountStatus: action.payload,
      };
    case "GET_CONNECT_ACCOUNTS_FIELDS":
      return {
        ...state,
        connectAccountFields: action.payload,
      };
    case "WITHDRAW_BALANCE":
      return {
        ...state,
        withdrawBalance: action.payload,
      };

    case "GET_ACCOUNT_BALANCE":
      return {
        ...state,
        accountBalance: action.payload,
      };
    case "GET_ALL_PAYMENT_LOGS":
      return {
        ...state,
        allPaymentLogs: action.payload,
        active: action.active,
        lastDocument: action.lastDocument,
      };
    case "GET_AMOUNT_TO_WITHDRAW":
      return {
        ...state,
        amountToWithdraw: action.payload,
      };
    case "LOADING":
      return {
        ...state,
        loader: action.payload,
      };
    default:
      return state;
  }
};

export default paymentsReducer;
