import OnlyHeader from "components/Headers/OnlyHeader";
import ReviewCard from "components/Reviews/ReviewCard";
import React, { useEffect, useState } from "react";
import { MdArrowBackIosNew } from "react-icons/md";
import LoadingOverlay from "react-loading-overlay";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import { getReviews } from "store/actions/reviewsAction";

export default function ProductReviews() {
  const { id } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { allReviews, loading, lastDocument, active } = useSelector(
    (state) => state.review
  );
  const { uid } = useSelector((state) => state.authUser);

  const [lastDocuments, setLastDocuments] = useState("");
  const handleLoadMore = () => {
    dispatch(
      getReviews(
        uid,
        "published",
        "productReviews",
        false,
        lastDocuments,
        "",
        id
      )
    );
  };
  useEffect(() => {
    setLastDocuments(lastDocument);
  }, [lastDocument]);

  useEffect(() => {
    dispatch(
      getReviews(uid, "published", "productReviews", true, null, "", id)
    );
  }, [uid, id]);
  return (
    <>
      <OnlyHeader />
      <Container fluid className="mt--7">
        <Row className="mt-2 mb-2">
          <Col>
            <LoadingOverlay
              active={loading}
              spinner
              text="Product Reviews Loading...."
            >
              <Card className="shadow-sm">
                <CardHeader className="border-0 ">
                  <MdArrowBackIosNew
                    className="mx-1"
                    style={{ cursor: "pointer" }}
                    onClick={() => history.goBack()}
                  />
                  {location?.state} Reviews
                </CardHeader>
                <CardBody>
                  <ReviewCard allReviews={allReviews} />
                </CardBody>
                {loading == false ? (
                  <>
                    <p className="text-center mt-3">
                      {active == false
                        ? "No more Reviews"
                        : allReviews.length == 0
                        ? "Reviews not found"
                        : ""}
                    </p>
                    {allReviews.length != 0 ? (
                      <Row>
                        <Col md={6}>
                          <Button
                            onClick={handleLoadMore}
                            color="success"
                            className="mx-4 mb-2"
                            disabled={active ? false : true}
                          >
                            Load more
                          </Button>
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}
              </Card>
            </LoadingOverlay>
          </Col>
        </Row>
      </Container>
    </>
  );
}
