import { Avatar } from "@mui/material";
import React from "react";
import { Button } from "reactstrap";

const ChatHeader = ({ profileImage, name, onBack }) => {
  return (
    <div className="d-flex bg-primary p-4 align-items-center rounded-lg  ">
      <i
        role="button"
        onClick={onBack}
        class="fas fa-chevron-left text-white fa-2x mr-5"
      ></i>

      <Avatar alt="" src={profileImage || ""} sx={{ height: 50, width: 50 }} />
      <div className="flex flex-col">
        <h5 className="m-0 mx-2 fs--20 fw--700 ml-4 text-white">{name}</h5>
      </div>
    </div>
  );
};

export default ChatHeader;
