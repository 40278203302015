import React, { useEffect } from "react";
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Button,
  CardBody,
  Col,
  CardTitle,
} from "reactstrap";
import LoadingOverlay from "react-loading-overlay";
import { useDispatch, useSelector } from "react-redux";

import { useState } from "react";
import { getPaymentLogs } from "store/actions/paymentsAction";
import { withdrawAmount } from "store/actions/paymentsAction";
import { getAccountBalance } from "store/actions/paymentsAction";
import { withdraw } from "store/actions/paymentsAction";

const Finance = () => {
  const dispatch = useDispatch();
  const { uid, user } = useSelector((state) => state.authUser);
  const {
    allPaymentLogs,
    loader,
    active,
    lastDocument,
    amountToWithdraw,
    accountBalance,
  } = useSelector((state) => state.payments);

  const [lastDocuments, setLastDocuments] = useState("");
  function formatDate(timestamp) {
    if (!timestamp) {
      return "Invalid Date";
    }
    if (
      timestamp.seconds !== undefined &&
      timestamp.nanoseconds !== undefined
    ) {
      const date = new Date(
        timestamp.seconds * 1000 + timestamp.nanoseconds / 1e6
      );
      return date.toLocaleDateString();
    }

    if (typeof timestamp === "string") {
      const date = new Date(timestamp);
      if (!isNaN(date.getTime())) {
        return date.toLocaleDateString();
      }
    }

    return "Invalid Date";
  }

  useEffect(() => {
    setLastDocuments(lastDocument);
  }, [lastDocument]);

  const handleLoadMore = () => {
    let sellerId = "";
    if (user?.role == "seller") {
      sellerId = uid;
    }
    if (user?.role == "finance") {
      sellerId = user?.creatorId;
    }
    dispatch(getPaymentLogs(lastDocuments, sellerId));
  };

  useEffect(() => {
    let sellerId = "";
    if (user?.role == "seller") {
      sellerId = uid;
    }
    if (user?.role == "finance") {
      sellerId = user?.creatorId;
    }
    dispatch(getPaymentLogs(null, sellerId));
  }, []);

  useEffect(() => {
    let sellerId = "";
    if (user?.role == "seller") {
      sellerId = uid;
    }
    if (user?.role == "finance") {
      sellerId = user?.creatorId;
    }
    dispatch(withdrawAmount(sellerId));
    dispatch(getAccountBalance(sellerId));
  }, []);

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            <Row>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase mb-0 text-muted fs--16"
                        >
                          Available Balance
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {accountBalance === -1
                            ? "Loading"
                            : accountBalance?.availableAmount}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-primary text-white rounded-circle shadow">
                          <i className="fas fa-solid fa-wallet"></i>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6" xl="4">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0 fs--16"
                        >
                          Ready to Withdraw
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {loader
                            ? "Loading..."
                            : amountToWithdraw &&
                              amountToWithdraw.readyToWithdraw
                            ? amountToWithdraw.readyToWithdraw
                            : 0}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                          <i class="fas fa-solid fa-dollar-sign"></i>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col
                lg="6"
                xl="3"
                className="d-inline-flex justify-content-start align-items-center"
              >
                <Button
                  className="bg-danger text-white shadow"
                  color="primary"
                  type="button"
                  disabled={
                    amountToWithdraw?.readyToWithdraw == 0 ||
                    user?.role === "finance"
                  }
                  onClick={() => dispatch(withdraw(uid))}
                >
                  Withdraw Now
                </Button>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <Container fluid className="mt--7">
        <Row>
          <Col>
            <LoadingOverlay
              active={loader}
              spinner
              text={`Payment Logs Loading....`}
            >
              <Card className="shadow-sm">
                <CardHeader className="border-0 d-flex justify-content-between align-items-center">
                  <h3 className="mb-0">Payment Logs</h3>
                </CardHeader>

                <CardBody className="p-0">
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead">
                      <tr>
                        <th scope="col">Date</th>
                        <th scope="col">Order Id</th>
                        <th scope="col">From</th>
                        <th>Activity</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    {allPaymentLogs.length === 0 ? (
                      <div className="d-flex align-items-center justify-content-center">
                        <p className="text-center">No Payment Logs Found!</p>
                      </div>
                    ) : (
                      <tbody>
                        {allPaymentLogs &&
                          allPaymentLogs.map((log) => {
                            return (
                              <>
                                <tr key={log?.id}>
                                  <td style={{ letterSpacing: "1px" }}>
                                    {log?.createdAt &&
                                      formatDate(log?.createdAt)}{" "}
                                  </td>
                                  <th scope="row">
                                    <span className="mb-0 text-sm">
                                      {log?.orderId}
                                    </span>
                                  </th>
                                  <td className="text-capitalize">
                                    {log?.from}
                                  </td>
                                  <td className="text-info text-capitalize">
                                    {log?.activity}
                                  </td>
                                  <td>
                                    {log?.activity === "Earnings" ? (
                                      <i className="fas fa-solid fa-plus text-success"></i>
                                    ) : (
                                      <i className="fas fa-solid fa-minus text-danger"></i>
                                    )}
                                    <span className="fs--16 font-weight-bold">
                                      ${log?.amount}
                                    </span>
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                      </tbody>
                    )}
                  </Table>
                </CardBody>
              </Card>
              <div className="d-flex justify-content-center align-items-center mt-3">
                {loader == false ? (
                  <>
                    <p className="text-center">
                      {active == false ? "No more Payment Logs" : ""}
                    </p>
                    <Button
                      onClick={handleLoadMore}
                      className="mx-3 text-secondary  mb-2"
                      color="success"
                      disabled={active ? false : true}
                    >
                      Load more
                    </Button>
                  </>
                ) : (
                  ""
                )}
              </div>
            </LoadingOverlay>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Finance;
