import useLocalStorageTheme from "hooks/useLocalStorageTheme";
import useSystemTheme from "hooks/useSystemTheme";
import { useEffect, useState } from "react";
import _ from "lodash";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

export default function ThemeDropDown({ direction }) {
  //hooks
  const isDarkMode = useSystemTheme();
  const { theme, toggleTheme } = useLocalStorageTheme();

  //states
  const [dropdownOpen, setDropdownOpen] = useState(false);

  //function
  const setSiteTheme = (newTheme) => {
    const selectedTheme =
      newTheme === "system" ? (isDarkMode ? "dark" : "light") : newTheme;
    document.body.className = selectedTheme;
  };

  //side Effects
  useEffect(() => {
    setSiteTheme(theme);
  }, [theme]);

  return (
    <Dropdown
      className=""
      isOpen={dropdownOpen}
      toggle={() => setDropdownOpen((prevState) => !prevState)}
      direction={direction || "down"}
    >
      <DropdownToggle className="rounded py-1 px-2 text-xs m-0 mr-2 btn-secondary text-primary">
        <i
          className={`fas fa-solid ${
            theme === "light"
              ? "fa-sun text-yellow"
              : theme === "dark"
              ? "fa-moon text-white"
              : "fa-laptop text-black"
          }`}
        ></i>
      </DropdownToggle>
      <DropdownMenu className="m-1 w-auto">
        {themeOptions.map(({ themeName, icon }) => (
          <DropdownItem
            onClick={() => toggleTheme(themeName)}
            className="d-flex px-1"
          >
            <i className={`fas fa-solid ${icon}`}></i>
            {_.capitalize(themeName)}

            {theme === themeName && (
              <i className="text-gray fas fa-regular fa-check  m-0 ml-3 "></i>
            )}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
}

const themeOptions = [
  {
    themeName: "light",
    icon: "fa-sun text-yellow",
  },
  {
    themeName: "dark",
    icon: "fa-moon text-dark",
  },
  {
    themeName: "system",
    icon: "fa-laptop text-primary",
  },
];
