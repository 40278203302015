import { Avatar } from "@mui/material";
import React from "react";
import { Input } from "reactstrap";

const Conservations = ({ conservations, activeChatIndex, onChangeActive }) => {
  return (
    <div
      style={{ minWidth: "300px" }}
      className=" d-lg-block d-none bg-white border rounded-lg p-3 mr-2"
    >
      <h3 className="fw--700 border-bottom pb-2">Chats</h3>
      <Input placeholder="Search Chat" />
      <div className="my-2 vh-65">
        {conservations.map(({ profilePic, name, text }, index) => (
          <div
            key={index}
            className={`d-flex align-items-center my-4 p-2 rounded-lg ${
              activeChatIndex === index ? "active-chat-color" : "chat-color"
            }`}
            onClick={() => onChangeActive(index)}
          >
            <Avatar
              src={profilePic}
              alt=""
              className="mr-3"
              sx={{ width: 50, height: 50 }}
            />
            <div>
              <h3 className="fs--15 fw--700 m-0 truncate-line-1">{name}</h3>
              <p className="fs--13 fw--500 m-0  truncate-line-1 ">{text}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Conservations;
