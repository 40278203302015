/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import OnlyHeader from "components/Headers/OnlyHeader";
import ConnectAccountForm from "components/Profile/ConnectAccountForm";
import TabSelection from "components/TeamManagement/TabSelection";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Progress,
  Row,
  Spinner,
} from "reactstrap";
import { getCountries } from "../store/actions/CountryAction";
import {
  addNewOrgAction,
  addNewWeekend,
  getUpdateRequest,
  getUpdateWeekend,
  updateUser,
  uploadFile,
} from "../store/actions/authAction";

const Profile = () => {
  const dispatch = useDispatch();
  const fileInputRef = useRef();

  const { user, uid, updateLoader, fileUploadingProgress, anyUpdateRequest } =
    useSelector((state) => state.authUser);
  const { countries } = useSelector((state) => state.country);

  const [data, setData] = useState({
    reason: "",
    requestedOrgName: "",
  });
  const [requestedWeekName, setRequestedWeekName] = useState("");
  const [weekendReason, setweekendReason] = useState("");

  const [updateImage, setUpdateImage] = useState("");
  const [username, setUsername] = useState("");
  const [dob, setDob] = useState("");
  const [organization, setOrganization] = useState("");
  const [country, setCountry] = useState({ value: "", label: "", iso2: "" });
  const [modalOpen, setModalOpen] = useState(false);
  const [weekendModalOpen, setWeekendModalOpen] = useState(false);
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [weekends, setWeekends] = useState("");
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  const [profileSection, setProfileSection] = useState("my profile");

  const handleImageUpload = (e) => {
    const file = e.target.files[0];

    let parts = e.target.files[0].name.split(".");
    if (
      !["jpg", "jpeg", "png"].includes(parts[parts.length - 1].toLowerCase())
    ) {
      alert("File must be .jpg, .jpeg, or .png");
      return;
    }

    if (file) {
      setUpdateImage(file);
      dispatch(uploadFile(uid, file));
    }
  };

  const handleInputChange = (e) => {
    setCountry({
      label: e.label,
      value: e.value,
      iso2: e.iso2,
    });

  };
  const handleInputDate = (e) => {
    const selectedDate = e.target.value;
    const currentDate = new Date();
    const selectedDateObj = new Date(selectedDate);
    const minAgeDate = new Date(currentDate.getFullYear() - 18, currentDate.getMonth(), currentDate.getDate());
  
    if (selectedDateObj > minAgeDate) {
      alert("You must be at least 18 years old.");
      return;
    }
      setDob(selectedDate);
  };

  const handleSaveOrganization = (e) => {
    e.preventDefault();
    let payload = {
      ...data,
      currentOrgName: user?.organization,
      requestedById: uid,
      email: user?.email,
      sellerName: user?.username,
      status: "pending",
      type: "updateOrganization",
    };
    dispatch(
      addNewOrgAction(payload, () => {
        setModalOpen(false);
        dispatch(getUpdateRequest(uid));
      })
    );
  };

  const handleSaveWeekend = (e) => {
    e.preventDefault();
    let payload = {
      currentWeekendName: user?.weekends,
      requestedWeekName: requestedWeekName.split("/"),
      reason: weekendReason,
      requestedById: uid,
      email: user?.email,
      sellerName: user?.username,
      status: "pending",
      type: "updateWeekends",
    };
    dispatch(
      addNewWeekend(payload, () => {
        setWeekendModalOpen(false);
        dispatch(getUpdateWeekend(uid));
      })
    );
  };

  const resetForm = () => {
    setUsername(user?.username);
    setDob(user?.dob);
    setOrganization(user?.organization);
    setCountry(user?.country);
    setWeekends(user?.weekends ? user?.weekends.join("/") : "");
  };

  useEffect(() => {
    const isUsernameChanged = user?.username !== username;
    const isDobChanges = user?.dob !== dob;
    const isOrganizationChanged = user?.organization !== organization;
    const isWeekendChanged = user?.weekends !== weekends;
    const isCountryChanged =
      JSON.stringify(user?.country) !== JSON.stringify(country);

    setIsFormChanged(
      isUsernameChanged ||
        isOrganizationChanged ||
        isCountryChanged ||
        isDobChanges ||
        isWeekendChanged
    );
  }, [user, username, organization, country, dob, weekends]);

  useEffect(() => {
    if (user) {
      setUpdateImage(user?.logo ? user?.logo : "");
      setUsername(user?.username);
      setDob(user?.dob);
      setOrganization(user?.organization);
      setCountry(user?.country);
      setWeekends(user?.weekends ? user?.weekends.join("/") : "");
      dispatch(getCountries());
      dispatch(getUpdateRequest(uid));
      dispatch(getUpdateWeekend(uid));
    }
  }, [user, uid]);

  return (
    <>
      <OnlyHeader />
      {/* Page content */}

      <Container
        className="mt--9 left left--1 position-absolute d-flex justify-content-start"
        fluid
      >
        <TabSelection
          options={["my profile", "add payments method"]}
          onChange={(value) => setProfileSection(value)}
        />
      </Container>
      <Container className="mt--7" fluid>
        {profileSection === "my profile" ? (
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardBody>
                  <Form className="p-4">
                    <h5 className="heading-small mb-4 p-3">User information</h5>
                    <div className="pl-lg-4">
                      <Row className="mb-4">
                        <Col>
                          <div className="d-flex align-items-center justify-content-center">
                            <div>
                              {updateImage ? (
                                <>
                                  <div className="position-relative">
                                    <img
                                      style={{
                                        filter: `opacity(${
                                          fileUploadingProgress === -1 ||
                                          fileUploadingProgress === 100
                                            ? 1
                                            : fileUploadingProgress > 10
                                            ? fileUploadingProgress / 100
                                            : 0.2
                                        })`,
                                      }}
                                      alt="..."
                                      src={
                                        updateImage &&
                                        typeof updateImage === "object"
                                          ? URL.createObjectURL(updateImage)
                                          : user?.logo
                                      }
                                      className="rounded-circle mt-2"
                                      height={100}
                                      width={100}
                                    />
                                    <i
                                      className="fas fa-camera camera-icon rounded-circle p-2"
                                      onClick={() =>
                                        fileInputRef?.current?.click()
                                      }
                                    ></i>
                                  </div>
                                  {fileUploadingProgress !== -1 &&
                                    fileUploadingProgress < 100 && (
                                      <Progress
                                        animated
                                        color="primary"
                                        striped
                                        value={fileUploadingProgress}
                                        className="mt-2"
                                      />
                                    )}
                                </>
                              ) : (
                                <div className="position-relative">
                                  <span className="avatar avatar-lg fs--20 rounded-circle bg-warning">
                                    {user?.username[0].toUpperCase()}
                                  </span>
                                  <i
                                    className="fas fa-camera camera-default-icon rounded-circle p-2"
                                    onClick={() =>
                                      fileInputRef?.current?.click()
                                    }
                                  ></i>
                                </div>
                              )}
                              <input
                                type="file"
                                ref={fileInputRef}
                                className="d-none"
                                accept="image/*"
                                onChange={handleImageUpload}
                              />
                            </div>
                          </div>

                          {/* <div className="">
                          <h3 className="text-center mt-1 ">{user?.username}</h3>
                            <p className="text-center">{user?.email}</p>
                            </div> */}
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Display Name
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue="lucky.jesse"
                              value={username}
                              placeholder="Username"
                              type="text"
                              onChange={(e) => {
                                setUsername(e.target.value);
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Email address
                            </label>
                            <Input
                              className="form-control-alternative bg-secondary"
                              value={user?.email}
                              disabled={true}
                              placeholder="email"
                              type="email"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Role
                            </label>
                            <Input
                              className="form-control-alternative bg-secondary"
                              value={user?.role}
                              disabled={true}
                              placeholder="role"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Status
                            </label>
                            <Input
                              className="form-control-alternative bg-secondary"
                              value={
                                user?.active == true ? "Active" : "Suspend"
                              }
                              disabled={true}
                              placeholder="status"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup
                            className="form-control-label d-block"
                            htmlFor="input-dob"
                          >
                            <label>Date of birth</label>
                            <Input
                              id="input-dob"
                              className="form-control-alternative"
                              type="date"
                              name="dob"
                              required
                              value={dob}
                              onChange={handleInputDate}
                            />
                          </FormGroup>
                        </Col>
                        <Col
                          lg={
                            (user && user.role == "seller") ||
                            user?.role == "distributor"
                              ? "6"
                              : "12"
                          }
                        >
                          <FormGroup>
                            {" "}
                            <label
                              className="form-control-label d-block"
                              htmlFor="input-username"
                            >
                              Country
                            </label>
                            <Select
                              className="countrySelect"
                              id="exampleCountry"
                              value={country}
                              onChange={(e) => {
                                handleInputChange(e);
                              }}
                              options={countries}
                              placeholder="Select a country..."
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="border rounded py-3 position-relative">
                        <small className="text-warning position-absolute  legend bg-secondary profile-badge">
                          {
                            " ( One-time setting, contact admin for modifications. )"
                          }
                        </small>
                        {(user?.role == "seller" ||
                          user?.role == "distributor") && (
                          <Col md={12} xl={12} className="mt-3">
                            <FormGroup className="m-0">
                              <label className="form-control-label mt-3">
                                Organization:
                              </label>
                              <div className="d-lg-flex d-md-flex">
                                <Col md={6} className="mb-3">
                                  <Input
                                    disabled={user?.organization}
                                    className={`form-control-alternative  ${
                                      organization ? "bg-secondary" : ""
                                    } `}
                                    value={organization}
                                    placeholder="Organization name"
                                    type="text"
                                    onChange={(e) => {
                                      setOrganization(e.target.value);
                                    }}
                                  />
                                </Col>
                                <Col md={6}>
                                  <Button
                                    color="primary"
                                    onClick={() => setModalOpen(true)}
                                    className="btn-sm py-2"
                                    disabled={
                                      anyUpdateRequest?.hasAnyRequestForOrg
                                    }
                                  >
                                    {anyUpdateRequest?.hasAnyRequestForOrg                                   
                                      ? "Pending"
                                      : "Contact Admin"}
                                  </Button>
                                </Col>
                              </div>
                            </FormGroup>
                          </Col>
                        )}

                        <Col md={12} xl={12}>
                          <label className="form-control-label mt-3">
                            Choose Weekend days:
                          </label>
                          <Col md={6} className="ml-3">
                            <FormGroup className="mt-2">
                              {["Friday/Saturday", "Saturday/Sunday"].map(
                                (weekend, index) => (
                                  <div key={index} className="mb-2">
                                    <Label check className="radio-label">
                                      <Input
                                        type="radio"
                                        name="weekendDays"
                                        id={weekend}
                                        className="form-check-input-lg"
                                        value={weekend}
                                        onChange={() => setWeekends(weekend)}
                                        checked={weekends === weekend}
                                        disabled={user?.weekends}
                                      />
                                      {weekend}
                                    </Label>
                                  </div>
                                )
                              )}
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <Button
                              color="primary"
                              onClick={() => setWeekendModalOpen(true)}
                              className="btn-sm py-2 mr-4"
                              disabled={
                                anyUpdateRequest?.hasAnyRequestForWeekends
                              }
                            >
                              {anyUpdateRequest?.hasAnyRequestForWeekends
                              
                                ? "Pending"
                                : "Contact Admin"}
                            </Button>
                          </Col>
                        </Col>
                        <Modal
                          isOpen={modalOpen}
                          toggle={() => setModalOpen(!modalOpen)}
                        >
                          <ModalHeader toggle={() => setModalOpen(!modalOpen)}>
                            Request Modification
                          </ModalHeader>
                          <ModalBody>
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="new-org-name"
                              >
                                New Organization Name
                              </label>
                              <Input
                                className=""
                                name="new-org-name"
                                placeholder="new organization name"
                                type="text"
                                value={data?.requestedOrgName}
                                onChange={(e) =>
                                  setData({
                                    ...data,
                                    requestedOrgName: e.target.value,
                                  })
                                }
                              />
                            </FormGroup>
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="resonForModif"
                              >
                                Enter the reason for the modification request
                              </label>
                              <Input
                                name="resonForModif"
                                type="textarea"
                                placeholder="Reason for modification request"
                                value={data?.reason}
                                onChange={(e) =>
                                  setData({ ...data, reason: e.target.value })
                                }
                              />
                            </FormGroup>
                          </ModalBody>
                          <ModalFooter>
                            <Button
                              color="primary"
                              onClick={handleSaveOrganization}
                            >
                              Submit Request
                            </Button>{" "}
                            <Button
                              color="secondary"
                              onClick={() => setModalOpen(false)}
                            >
                              Cancel
                            </Button>
                          </ModalFooter>
                        </Modal>
                        <Modal
                          isOpen={weekendModalOpen}
                          toggle={() => setWeekendModalOpen(!weekendModalOpen)}
                        >
                          <ModalHeader
                            toggle={() =>
                              setWeekendModalOpen(!weekendModalOpen)
                            }
                          >
                            Request Modification
                          </ModalHeader>
                          <ModalBody>
                            <label
                              className="form-control-label"
                              htmlFor="new-org-name"
                            >
                              New Weekend Names
                            </label>
                            <div className="d-flex mb-4 ">
                              <FormGroup check className="mr-3 cursor-pointer">
                                <Label check className="radio-label">
                                  <Input
                                    type="radio"
                                    name="weekendDays"
                                    id="radio1"
                                    className="form-check-input-lg"
                                    // value="Friday/Saturday"
                                    value="Friday/Saturday"
                                    onChange={() =>
                                      setRequestedWeekName("Friday/Saturday")
                                    }
                                    checked={
                                      requestedWeekName === "Friday/Saturday"
                                    }
                                  />
                                  Friday/Saturday
                                </Label>
                              </FormGroup>
                              <FormGroup check className="cursor-pointer">
                                <Label check className="radio-label">
                                  <Input
                                    type="radio"
                                    name="weekendDays"
                                    id="radio2"
                                    className="form-check-input-lg"
                                    value="Saturday/Sunday"
                                    onChange={() =>
                                      setRequestedWeekName("Saturday/Sunday")
                                    }
                                    checked={
                                      requestedWeekName === "Saturday/Sunday"
                                    }
                                  />
                                  Saturday/Sunday
                                </Label>
                              </FormGroup>
                            </div>
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="resonForModif"
                              >
                                Enter the reason for the modification request
                              </label>
                              <Input
                                name="resonForModif"
                                type="textarea"
                                placeholder="Reason for modification request"
                                value={data?.weekendreason}
                                onChange={(e) =>
                                  setweekendReason(e.target.value)
                                }
                              />
                            </FormGroup>
                          </ModalBody>
                          <ModalFooter>
                            <Button color="primary" onClick={handleSaveWeekend}>
                              Submit Request
                            </Button>{" "}
                            <Button
                              color="secondary"
                              onClick={() => setWeekendModalOpen(false)}
                            >
                              Cancel
                            </Button>
                          </ModalFooter>
                        </Modal>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
                <CardFooter>
                  <Button
                    color="primary"
                    className="float-right"
                    onClick={() => {
                      dispatch(
                        updateUser(
                          user?.role,
                          uid,
                          username,
                          organization,
                          updateImage,
                          country,
                          dob,
                          weekends.split("/"),
                          () => {}
                        )
                      );
                    }}
                    disabled={updateLoader || !isFormChanged}
                  >
                    {updateLoader ? <Spinner size="sm" /> : "Save"}
                  </Button>
                  <Button
                    color="danger"
                    className="float-right mr-2"
                    onClick={resetForm}
                    disabled={updateLoader || !isFormChanged}
                  >
                    Cancel
                  </Button>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardBody>
                  <h6 className="heading-small text-muted mb-4">
                    Payment Methods
                  </h6>
                  <div className="pl-lg-4">
                    <ConnectAccountForm />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
};

export default Profile;
