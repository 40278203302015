import { Avatar } from "@mui/material";
import useInitScroll from "hooks/useInitScroll";
import useScrollToBottom from "hooks/useScrollToBottom";

const ChatBody = ({ messages, leftAvatar, rightAvatar }) => {
  const chatEndRef = useScrollToBottom([messages]);
  const initScroll = useInitScroll();

  return (
    <div
      className="chat-container bg-white  chat-body-border"
      ref={initScroll}
    >
      {messages.map((msg, index) => (
        <div key={index} ref={chatEndRef}>
          {index === 0 || msg.position !== messages[index - 1].position ? (
            <div className={`d-flex ${msg.position} mt-3`}>
              <Avatar
                alt=""
                src={msg.position === "right" ? rightAvatar : leftAvatar}
                className="mx-1"
                sx={{ width: 30, height: 30, marginTop: "4px" }}
              />
              <div className={`message-bubble-${msg.position}`}></div>
              <div className={`message message-${msg.position} mr-0`}>
                {msg.text}
              </div>
            </div>
          ) : (
            <div className={`d-flex ${msg.position}`}>
              <dv style={{ width: 30, height: 30 }} className="mx-1"></dv>
              <div className={` message mx-2 message-${msg.position} `}>
                {msg.text}
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default ChatBody;
