import { FormGroup, Input } from "reactstrap";

export const FormField = ({
  label,
  id,
  type,
  placeholder,
  onChange,
  required,
  value,
}) => (
  <>
    <FormGroup>
      <label className="form-control-label" htmlFor={`input-${id}`}>
        {label}
      </label>
      <Input
        className="form-control-alternative border"
        id={id}
        onChange={onChange}
        min={type === "number" ? 1 : ""}
        type={type}
        placeholder={placeholder}
        required={required}
        value={value}
      />
    </FormGroup>
  </>
);
