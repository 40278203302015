import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Form,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

export default function RelatedTools({ relatedTool, setRelatedTool }) {
  const [IsOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!IsOpen);
  };
  const [currentRelatedTool, setCurrentRelatedTool] = useState({
    title: "",
    url: "",
    description: "",
  });
  const [modal, setModal] = useState(false);
  const toolToggle = () => setModal(!modal);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCurrentRelatedTool({ ...currentRelatedTool, [name]: value });
  };
  const handleRemoveTool = (index) => {
    const updatedRelatedTools = relatedTool.filter((_, i) => i !== index);
    setRelatedTool(updatedRelatedTools);
  };
  const handleAddTool = () => {
    if (
      currentRelatedTool.title &&
      currentRelatedTool.url &&
      currentRelatedTool.description
    ) {
      setRelatedTool([...relatedTool, currentRelatedTool]);
      setCurrentRelatedTool({ title: "", url: "", description: "" });
      toolToggle();
    } else {
      alert("All fields are required");
    }
  };
  return (
    <div className="my-3 border border-2 p-2 shadow-sm">
      <Form className="my-2 form-2">
        <div
          className="d-flex justify-content-between align-items-center cursor"
          onClick={() => {
            toggle();
            setIsOpen(!IsOpen);
          }}
        >
          <h4 className="mb-0 pb-0 font-weight-bold">Related Tools</h4>
          <i
            className={`fas ${IsOpen ? "fa-chevron-up" : "fa-chevron-down"}`}
          ></i>
        </div>
        <Collapse isOpen={IsOpen}>
          <Row>
            {relatedTool?.map((item, index) => {
              return (
                <Col md={6}>
                  <Card className="shadow-sm border">
                    <CardHeader className="m-0">
                      <div className="d-flex justify-content-between">
                        <h4 className="font-weight-bold">{item?.title}</h4>
                        <i
                          className="fas fa-times mt-1 cursor"
                          onClick={() => handleRemoveTool(index)}
                        />
                      </div>
                    </CardHeader>
                    <CardBody>
                      <span>Url: {item?.url}</span>
                      <p>Description: {item?.description}</p>
                    </CardBody>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Collapse>
        <div
          className="d-flex alig-items-center cursor mt-3"
          onClick={() => {
            toolToggle();
          }}
        >
          <div>
            <i className="fas fa-plus cursor"></i>
          </div>
          <label className="mx-2 cursor">ADD RELATED TOOLS</label>
        </div>
        <Modal isOpen={modal} toggle={toolToggle}>
          <Form>
            <ModalHeader toggle={toolToggle}>Add Related Tool</ModalHeader>
            <ModalBody>
              <Input
                className="mb-1"
                name="title"
                type="text"
                placeholder="Title..."
                value={currentRelatedTool.title}
                onChange={(e) => {
                  handleInputChange(e);
                }}
              />
              <Input
                className="mb-1"
                name="url"
                type="text"
                placeholder="Url..."
                value={currentRelatedTool.url}
                onChange={(e) => {
                  handleInputChange(e);
                }}
              />
              <Input
                type="textarea"
                name="description"
                placeholder="Description..."
                value={currentRelatedTool.description}
                onChange={(e) => {
                  handleInputChange(e);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button
                className="bg-primary text-secondary"
                onClick={() => {
                  handleAddTool();
                }}
              >
                Add
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      </Form>
    </div>
  );
}
