/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import "@corbado/webcomponent";
import "@corbado/webcomponent/pkg/auth_cui.css";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useHistory
} from "react-router-dom/cjs/react-router-dom.min";
import { Card, CardBody, Col } from "reactstrap";
import { hashAction, verifyInvite } from "../../store/actions/authAction";

const Login = () => {
  const history = useHistory();
  const { hashGaurd } = useSelector((state) => state.authUser);
  // const [hashValues, setHashValues] = useState({ type: "", id: "", email: "" });
  const dispatch = useDispatch();
  useEffect(() => {
    const parseSearch = (search) => {
      const searchValues = {};
      const searchParams = new URLSearchParams(search);
      searchParams.forEach((value, key) => {
        searchValues[key] = decodeURIComponent(value);
      });
      return searchValues;
    };
    const search = window.location.search;
    if (search !== "") {
      const parsedSearch = parseSearch(search);
      if (parsedSearch && parsedSearch.type) {
        if (!hashGaurd) {
          dispatch(hashAction(parsedSearch.type));
        }
        dispatch(verifyInvite(parsedSearch?.id, parsedSearch?.email, history));
      } else {
        if (!hashGaurd) {
          dispatch(hashAction("seller"));
        }
      }
      // setHashValues(parsedSearch);
    }
  }, []);
  // useEffect(() => {
  //   if (hashValues && hashValues.type) {
  //     if (!hashGaurd) {
  //       dispatch(hashAction(hashValues.type));
  //     }
  //     dispatch(verifyInvite(hashValues?.id, hashValues?.email, history));
  //   } else {
  //     if (!hashGaurd) {
  //       dispatch(hashAction("seller"));
  //     }
  //   }
  // }, [hashValues]);
  return (
    <>
      <Col lg="5" md="7" className="mt-5">
        <Card className="bg-secondary shadow-sm border-0">
          <CardBody className="px-lg-5 py-lg-5">
            {/* <div className="text-center text-muted mb-4">
              <small>Sign in</small>
            </div> */}
            {/* <Form
              onSubmit={(e) => {
                e.preventDefault();
                dispatch(userLogin(userDetail));
              }}
            > */}
            <corbado-auth
              project-id={process.env.REACT_APP_CORBADO_PROJECT_ID}
              conditional="yes"
              id="corbado"
            >
              <input
                name="username"
                id="corbado-username"
                required
                autoComplete="webauthn"
              />
            </corbado-auth>

            {/* <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    autoComplete="new-email"
                    name="email"
                    value={userDetail?.email}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                </InputGroup>
              </FormGroup> */}
            {/* <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type="password"
                    autoComplete="new-password"
                    name="password"
                    value={userDetail?.password}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                </InputGroup>
              </FormGroup> */}
            {/* <div className="text-center">
                <Button
                  className="mt-4"
                  color="primary"
                  onClick={() => {
                    history.push("/admin/index");
                  }}
                >
                  Sign In
                </Button>
              </div> */}
            {/* <div className="custom-control custom-control-alternative custom-checkbox">
                <input
                  className="custom-control-input"
                  id=" customCheckLogin"
                  type="checkbox"
                />
                <label
                  className="custom-control-label"
                  htmlFor=" customCheckLogin"
                >
                  <span className="text-muted">Remember me</span>
                </label>
              </div> */}
            {/* <div className="text-center">
                <Button
                  className="my- mx-auto d-flex justify-content-center"
                  color="primary"
                  type="button"
                  onClick={() => {
                    // history.push("/admin/index")
                    dispatch(userLogin());
                  }}
                >
                  Enter Passkey
                </Button>
              </div> */}
            {/* </Form> */}
          </CardBody>
        </Card>
        {/* <Row className="mt-3">
          <Col xs="6">
            <a
              className="text-light"
              href="#pablo"
              onClick={(e) => e.preventDefault()}
            >
              <small>Forgot password?</small>
            </a>
          </Col>
          <Col className="text-right" xs="6">
            <a
              className="text-light"
              href="#pablo"
              onClick={(e) => e.preventDefault()}
            >
              <small>Create new account</small>
            </a>
          </Col>
        </Row> */}
      </Col>
    </>
  );
};

export default Login;
