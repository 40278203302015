import React, { useState } from "react";
import {
  Button,
  Col,
  Collapse,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

export default function QueAns({ questionAnswer, setQuestionAnswer }) {
  const [openSections, setOpenSections] = useState(
    questionAnswer?.map(() => false)
  );
  const toggle = (index) => {
    const newOpenSections = [...openSections];
    newOpenSections[index] = !newOpenSections[index];
    setOpenSections(newOpenSections);
  };
  const addQuestion = () => {
    setQuestionAnswer([...questionAnswer, { question: "", answer: "" }]);
  };
  const handleRemoveFields = (index) => {
    const updatedData = [...questionAnswer];
    updatedData.splice(index, 1);
    setQuestionAnswer(updatedData);
  };
  const handleChange = (index, event) => {
    const { name, value } = event.target;
    const updatedData = [...questionAnswer];
    updatedData[index][name] = value;
    setQuestionAnswer(updatedData);
  };
  return (
    <div className="my-3 p-2 shadow-sm border border-1">
      <h4 className="font-weight-bold">Question / Answer</h4>

      {questionAnswer?.length == 0 ? (
        <div
          className="d-flex alig-items-center cursor"
          onClick={addQuestion}
        >
          <div>
            <i className="fas fa-plus cursor"></i>
          </div>
          <label className="ml-1 cursor">ADD QUESTION</label>
        </div>
      ) : (
        <Form className="my-2">
          {questionAnswer.map((item, index) => (
            <div key={index}>
              <div className="d-flex justify-content-between align-items-center mb-3 cursor">
                <h5 className="font-weight-bold ">Question: {index + 1}</h5>
                <div className="d-flex align-items-center">
                  <i
                    onClick={() => {
                      toggle(index);
                    }}
                    className={`fas ${
                      openSections[index] ? "fa-chevron-up" : "fa-chevron-down"
                    }`}
                  ></i>

                  <i
                    className="fas fa-trash mx-2 text-danger"
                    onClick={() => handleRemoveFields(index)}
                  ></i>
                </div>
              </div>
              <Collapse isOpen={openSections[index]}>
                <FormGroup>
                  <Input
                    type="text"
                    name="question"
                    value={item.question}
                    onChange={(event) => handleChange(index, event)}
                    placeholder="Question..."
                  />
                </FormGroup>
                <FormGroup>
                  <Input
                    type="textarea"
                    name="answer"
                    value={item.answer}
                    onChange={(event) => handleChange(index, event)}
                    placeholder="Answer..."
                  />
                </FormGroup>
              </Collapse>
            </div>
          ))}
        </Form>
      )}
      {questionAnswer?.length > 0 ? (
        <div
          className="d-flex alig-items-center cursor"
          onClick={() => {
            addQuestion();
            // toggle(index);
          }}
        >
          <div>
            <i className="fas fa-plus cursor"></i>
          </div>
          <label className="mx-2 cursor">
            ADD MORE
          </label>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
