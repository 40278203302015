const initialData = {
  issueDetails: {
    issuerEmail: "",
    issuerName: "",
    category: null,
    description: "",
    issuerId: "",
    role: "guest",
    status: "open",
  },
  nameFieldDisable: false,
  emailFieldDisable: false,
  modalIssue: false,
  isContinue: false,
  isLoading: false,
};
const reportAnIssueReducer = (state = initialData, action) => {
  switch (action.type) {
    case "SET_ISSUE_DETAILS":
      return {
        ...state,
        issueDetails: { ...state.issueDetails, ...action.payload },
      };
    case "SET_ISSUE_DETAILS_TO_INITIAL":
      return {
        ...state,
        issueDetails: {
          ...state.issueDetails,
          issuerEmail: "",
          issuerName: "",
          category: null,
          description: "",
          issuerId: "",
          role: "guest",
          status: "open",
          attachments: [],
        },
      };
    case "SET_MODAL_ISSUE":
      return {
        ...state,
        modalIssue: !state.modalIssue,
      };
    case "SET_IS_CONTINUE":
      return {
        ...state,
        isContinue: action.payload,
      };
    case "SET_IS_LOADING":
      return {
        ...state,
        isLoading: action.payload,
      };
    case "SET_NAME_FIELD_DISABLE":
      return {
        ...state,
        nameFieldDisable: action.payload,
      };
    case "SET_EMAIL_FIELD_DISABLE":
      return {
        ...state,
        emailFieldDisable: action.payload,
      };

    default:
      return state;
  }
};

export default reportAnIssueReducer;
