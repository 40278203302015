/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import { useState } from "react";
import { Link, NavLink as NavLinkRRD } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";

// reactstrap components
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Select from "react-select";
import {
  Button,
  Col,
  Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Media,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Navbar,
  NavbarBrand,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import { getCountries } from "store/actions/CountryAction";
import { logout } from "store/actions/authAction";
import Fullylocallogo from "../../assets/img/logo.png";
import { updateUser } from "../../store/actions/authAction";
import ThemeDropDown from "components/Theme/ThemeDropDown";
import Store from "components/Store/Store";
import { ReportAnIssueModal } from "components/Models/ReportAnIssueModel";
import { toggleIssueModal } from "store/actions/reportAnIssueAction";
import { setIsContinueClicked } from "store/actions/reportAnIssueAction";
var ps;

const Sidebar = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user, uid, updateLoader } = useSelector((state) => state.authUser);
  const { countries } = useSelector((state) => state.country);
  const [collapseOpen, setCollapseOpen] = useState();
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [country, setCountry] = useState({ value: "", label: "" });

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  // toggles collapse between opened and closed (true/false)
  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };
  // closes the collapse
  const closeCollapse = () => {
    setCollapseOpen(false);
  };
  const accessibleRoles = [
    "distributor",
    "Logistics",
    "distributor-member",
    "seller",
  ];

  // 'path' will contain the appropriate value based on user role and conditions

  // creates the links that appear in the left menu / Sidebar
  const createLinks = (routes) => {
    return (
      <>
        {routes
          .filter((prop) => {
            if (prop.isMenu === false) return false;
            if (prop.roles && !prop.roles.includes(user?.role)) return false;
            return true;
          })
          .map((prop, key) => (
            <NavItem key={key}>
              <NavLink
                to={
                  user.role === "seller" && !user.country
                    ? "/admin/"
                    : prop.layout + prop.path
                }
                tag={NavLinkRRD}
                onClick={closeCollapse}
                activeClassName="active"
              >
                <i className={prop.icon} />
                {prop.name}
              </NavLink>
            </NavItem>
          ))}
        <NavItem>
          <NavLink>
            <i className="fas fa-solid fa-bug text-danger"></i>
            <Button
              className=""
              color="danger"
              type="button"
              onClick={() => {
                dispatch(toggleIssueModal());
              }}
              size={"sm"}
            >
              Report An Issue{" "}
              <i className="fas fa-solid fa-arrow-right ml-1"></i>
            </Button>
          </NavLink>
        </NavItem>
        <ReportAnIssueModal />
      </>
    );
  };
  const { routes, logo } = props;
  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }
  const handleInputChange = (e) => {
    setCountry({
      label: e.label,
      value: e.value,
      iso2: e.iso2,
    });
  };
  useEffect(() => {
    if (user?.role == "seller" && typeof user.country != "object") {
      toggle();
      dispatch(getCountries());
    }
  }, [user]);
  return (
    <Navbar
      className="navbar-vertical fixed-left"
      expand="lg"
      id="sidenav-main"
    >
      <Container fluid>
        {/* Toggler */}
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleCollapse}
        >
          <i class="fas fa-bars"></i>
        </button>
        {/* Brand */}
        {logo ? (
          <NavbarBrand className="pt-0 " {...navbarBrandProps}>
            {/* <img
							alt={logo.imgAlt}
							className='navbar-brand-img'
							src={logo.imgSrc}
						/> */}
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              id="Layer_1"
              data-name="Layer 1"
              viewBox="0 0 32 50"
              height={30}
            >
              <path
                d="M0,8.5V44.5l4.5,5.5V18.5l3,3,4.5,5,4,4,4.5-4.5,4.5-4.5,3-3v31.5l4-5.5V8.5L16,24.5,0,8.5Z"
                style={{ fill: "#8f9091" }}
              />
              <path
                d="M12.16,4.62L3,0,15.54,43,30,0l-8.68,4.16-5.3,24.51L12.16,4.62Z"
                style={{ fill: "#8f9091" }}
              />
            </svg> */}
            <div className="d-flex align-items-center">
              <img
                src={Fullylocallogo}
                alt="logo"
                style={{ maxWidth: "40px", marginRight: "5px" }}
              />
              <span
                className="font-weight-bold fs--14"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {user && user?.organization
                  ? `Fullylocal-${user?.organization}`
                  : "Fullylocal-vendor"}
              </span>
            </div>
          </NavbarBrand>
        ) : null}
        {/* User */}
        <Nav className="d-flex align-items-center d-lg-none">
          <Store />
          <ThemeDropDown direction={"left"} />
          <UncontrolledDropdown nav>
            <DropdownToggle nav>
              <Media className="align-items-center">
                {user?.logo ? (
                  <img
                    alt="..."
                    src={user?.logo}
                    height={30}
                    width={30}
                    className="rounded-circle"
                  />
                ) : (
                  <span className="avatar avatar-sm rounded-circle bg-warning">
                    {user && user?.username && user?.username[0]?.toUpperCase()}
                  </span>
                )}
              </Media>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
              <DropdownItem
                onClick={() => {
                  history.push("/admin/profile");
                }}
                tag={Link}
              >
                <i className="ni ni-single-02" />
                <span>My profile</span>
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  dispatch(logout(history, ""));
                }}
              >
                <i className="ni ni-user-run" />
                <span>Logout</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
        {/* Collapse */}
        <Collapse navbar isOpen={collapseOpen}>
          {/* Collapse header */}
          <div className="navbar-collapse-header d-md-none">
            <Row>
              {/* {logo ? (
                <Col className="collapse-brand" xs="6">
                  {logo.innerLink ? (
                    <Link to={logo.innerLink}>
                      <img alt={logo.imgAlt} src={logo.imgSrc} />
                    </Link>
                  ) : (
                    <a href={logo.outterLink}>
                      <img alt={logo.imgAlt} src={logo.imgSrc} />
                    </a>
                  )}
                </Col>
              ) : null} */}
              <Col className="collapse-brand" xs="6">
                <h3>Fullylocal Seller</h3>
              </Col>
              <Col className="collapse-close" xs="6">
                <i onClick={toggleCollapse} class="fas fa-times"></i>
              </Col>
            </Row>
          </div>
          {/* Form */}
          {/* <Form className="mt-4 mb-3 d-md-none">
            <InputGroup className="input-group-rounded input-group-merge">
              <Input
                aria-label="Search"
                className="form-control-rounded form-control-prepended"
                placeholder="Search"
                type="search"
              />
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <span className="fa fa-search" />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </Form> */}
          {/* Navigation */}
          <Nav navbar>{createLinks(routes)}</Nav>
        </Collapse>
      </Container>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Where's your live in?</ModalHeader>
        <ModalBody>
          <div className="d-flex align-items-center justify-content-center">
            {" "}
            <i className="fas fa-exclamation-triangle fs--30 text-danger"></i>
          </div>
          <p className="fs--14 text-center mt-2">
            Please add your country. Otherwise, access to the complete site will
            be restricted for you.
          </p>
          <FormGroup>
            {" "}
            <label
              className="form-control-label d-block"
              htmlFor="input-username"
            >
              Country
            </label>
            <Select
              className="countrySelect"
              id="exampleCountry"
              value={country}
              onChange={(e) => {
                handleInputChange(e);
              }}
              options={countries}
              placeholder="Select a country..."
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              if (country.value != "") {
                dispatch(
                  updateUser(
                    user?.role,
                    uid,
                    user?.username,
                    "",
                    "",
                    country,
                    "",
                    "",
                    () => {
                      toggle();
                    }
                  )
                );
              } else {
                alert("Please must add your country!");
              }
            }}
            disabled={updateLoader}
          >
            {updateLoader ? <Spinner size="md" /> : "Add"}
          </Button>
        </ModalFooter>
      </Modal>
    </Navbar>
  );
};

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default Sidebar;
