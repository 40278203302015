/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { ReportAnIssueModal } from "components/Models/ReportAnIssueModel";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
// reactstrap components
import { Container, Row, Col, Button } from "reactstrap";

// core components

import routes from "routes.js";
import { setIsContinueClicked } from "store/actions/reportAnIssueAction";
import { toggleIssueModal } from "store/actions/reportAnIssueAction";

const Auth = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const mainContent = React.useRef(null);
  const location = useLocation();

  const { logAuth } = useSelector((state) => state.authUser);
  React.useEffect(() => {
    document.body.classList.add("bg-default");
    return () => {
      document.body.classList.remove("bg-default");
    };
  }, []);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <div className="main-content" ref={mainContent}>
        <div className="header bg-gradient-info py-3 py-lg-5">
          <div className="container-fluid">
            <div className="d-flex justify-content-end align-items-center w-100">
              <Button
                className="mt-0 mb-1"
                color="danger"
                type="button"
                onClick={() => {
                  dispatch(setIsContinueClicked(false));
                  dispatch(toggleIssueModal());
                }}
                size={"sm"}
              >
                <i className="fas fa-solid fa-bug mr-2"></i>
                Report An Issue{" "}
              </Button>
            </div>
          </div>
          <Container>
            <div className="header-body text-center mb-7">
              <Row className="justify-content-center">
                <Col lg="5" md="6">
                  <h1 className="text-white">
                    Welcome to Fullylocal Platform!
                  </h1>
                </Col>
              </Row>
            </div>
          </Container>
          <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-default"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
        </div>
        {/* Page content */}
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Switch>
              {getRoutes(routes)}
              <Redirect from="*" to="/auth/login" />
            </Switch>
          </Row>
        </Container>
      </div>
      <ReportAnIssueModal />
    </>
  );
};

export default Auth;
