import { useEffect, useState } from "react";

import Chart from "chart.js";
import { Bar, Line } from "react-chartjs-2";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Nav,
  NavItem,
  Row,
  Spinner,
} from "reactstrap";

import { chartOptions, parseOptions } from "variables/charts.js";

import { useDispatch, useSelector } from "react-redux";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import {
  getEarningPerformance,
  getStatYearsBy,
} from "store/actions/dashboardStatsAction.js";
import Header from "../components/Headers/Header.js";
import { getLogistics } from "store/actions/dashboardStatsAction.js";
import { role } from "constant/teamRoles.js";

const Index = (props) => {
  const dispatch = useDispatch();
  const { uid, user } = useSelector((state) => state.authUser);
  const currentYear = new Date().getFullYear().toString();
  const [year, setYear] = useState(currentYear);
  const [barChartYear, setBarChartYear] = useState(currentYear);
  const {
    dashboardLoading,
    monthlyPerformance,
    sellerYears,
    logisticsPerfomance,
    logisticsLoading,
  } = useSelector((state) => state.dashboardStats);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpenBarChart, setDropdownOpenBarChart] = useState(false);

  let id = user?.role === role.LOGISTICS ? user?.creatorId : uid;

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const toggleBarChart = () =>
    setDropdownOpenBarChart((prevState) => !prevState);

  const [chartData, setChartData] = useState({});
  const [barChart, setBarChart] = useState({});

  useEffect(() => {
    dispatch(getEarningPerformance(id, year));
  }, [dispatch, year, uid]);

  useEffect(() => {
    dispatch(getLogistics(id, barChartYear));
  }, [barChartYear, uid]);

  useEffect(() => {
    let collectionName = dropdownOpenBarChart
      ? "soldItemsStats"
      : "sellerEarnings";
    dispatch(getStatYearsBy(id, collectionName));
  }, [uid, dropdownOpenBarChart, dropdownOpen]);

  useEffect(() => {
    if (monthlyPerformance.length > 0) {
      const labels = monthlyPerformance.map((entry) => entry.month.slice(0, 3));
      const data = monthlyPerformance.map((entry) => entry.earnings);

      setChartData({
        options: {
          scales: {
            yAxes: [
              {
                ticks: {
                  callback: function (value) {
                    const formatter = new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                      notation: "compact",
                      compactDisplay: "short",
                    });
                    return formatter.format(value);
                  },
                },
              },
            ],
          },
        },
        labels: labels,
        datasets: [
          {
            label: "Earnings",
            data: data,
          },
        ],
      });
    }
  }, [monthlyPerformance]);
  useEffect(() => {
    if (logisticsPerfomance.length > 0) {
      const labels = logisticsPerfomance.map((entry) =>
        entry.month.slice(0, 3)
      );
      const data = logisticsPerfomance.map((entry) => entry.soldItems);

      setBarChart({
        options: {
          scales: {
            yAxes: [
              {
                ticks: {
                  callback: function (value) {
                    const formatter = new Intl.NumberFormat("en-US", {
                      notation: "compact",
                      compactDisplay: "short",
                    });
                    return formatter.format(value);
                  },
                },
              },
            ],
          },
        },
        data: {
          labels: labels,
          datasets: [
            {
              label: "Sales",
              data: data,
              maxBarThickness: 10,
            },
          ],
        },
      });
    } else {
      setBarChart({
        data: {
          labels: [],
          datasets: [
            {
              label: "Sales",
              data: [],
              maxBarThickness: 10,
            },
          ],
        },
      });
    }
  }, [logisticsPerfomance]);

  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row className="mb-3 justify-content-center">
          <Col className="mb-5 mb-xl-0 col-12" xl="8">
            {/* <Col className="mb-5 mb-xl-0 col-12"> */}
            <Card className=" shadow-sm ">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase ls-1 mb-1">Overview</h6>
                    <h2 className="mb-0">Earnings</h2>
                  </div>
                  <div className="col">
                    <Nav className="justify-content-end" pills>
                      <NavItem>
                        <Dropdown
                          isOpen={dropdownOpen}
                          toggle={toggle}
                          direction="down"
                          
                        >
                          <DropdownToggle caret size="sm" color="primary">
                            {year}
                          </DropdownToggle>
                          <DropdownMenu>
                            {sellerYears === -1 ? (
                              <div className="px-4">
                                <Spinner size={"sm"} />
                              </div>
                            ) : (
                              sellerYears?.map((item, index) => {
                                if (item !== year) {
                                  return (
                                    <DropdownItem
                                      key={index}
                                      onClick={() => setYear(item)}
                                    >
                                      {item}
                                    </DropdownItem>
                                  );
                                }
                                return null;
                              })
                            )}
                          </DropdownMenu>
                        </Dropdown>
                      </NavItem>
                    </Nav>
                  </div>
                </Row>
              </CardHeader>

              <CardBody>
                <div className="chart d-flex  justify-content-center align-items-center">
                  {dashboardLoading ? (
                    <Spinner size="sm" color="white" />
                  ) : (
                    <Line data={chartData} options={chartData.options} />
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl="4">
            <Card className="shadow-sm">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-muted ls-1 mb-1">
                      Performance
                    </h6>
                    <h2 className="mb-0">Sold Items</h2>
                  </div>
                  <div className="col">
                    <Nav className="justify-content-end" pills>
                      <NavItem>
                        <Dropdown
                          isOpen={dropdownOpenBarChart}
                          toggle={toggleBarChart}
                          direction="down"
                        >
                          <DropdownToggle color="primary" size="sm" caret>
                            {barChartYear}
                          </DropdownToggle>
                          <DropdownMenu>
                            {sellerYears === -1 ? (
                              <div className="px-4">
                                <Spinner size={"sm"} />
                              </div>
                            ) : (
                              sellerYears?.map((item, index) => {
                                if (item !== barChartYear) {
                                  return (
                                    <DropdownItem
                                      key={index}
                                      onClick={() => setBarChartYear(item)}
                                    >
                                      {item}
                                    </DropdownItem>
                                  );
                                }
                                return null;
                              })
                            )}
                          </DropdownMenu>
                        </Dropdown>
                      </NavItem>
                    </Nav>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="chart">
                  {logisticsLoading ? (
                    <Row className="justify-content-center align-items-center">
                      <Spinner size="sm" />
                    </Row>
                  ) : (
                    <Bar data={barChart.data} options={barChart.options} />
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Index;
