const initialData = {
  tickets: [],
  singleTicket: {},
  vendorTicketCategories: [],
  platformTicketCategories: [],
  issuesTicket: [],
  ticketsLoading: false,
  hasMoreData: false,
};
const ticketsReducer = (state = initialData, action) => {
  switch (action.type) {
    case "TICKETS":
      return {
        ...state,
        tickets: action.payload,
        hasMoreData: action.hasMoreData,
      };
    case "SINGLE_TICKET":
      return {
        ...state,
        singleTicket: action.payload,
      };
    case "UDPATE_TICKET":
      const { id, status } = action.payload;
      const ticketIndex = state.tickets.findIndex((ticket) => ticket.id === id);

      if (ticketIndex !== -1) {
        const updatedTickets = [...state.tickets];
        updatedTickets[ticketIndex] = {
          ...updatedTickets[ticketIndex],
          status: status,
        };
        return {
          ...state,
          tickets: updatedTickets,
        };
      } else {
        return state;
      }
    case "ASSIGN_TICKET":
      const { ID, userID } = action.payload;
      const assignedTicketIndex = state.tickets.findIndex(
        (ticket) => ticket.id === ID
      );

      if (assignedTicketIndex !== -1) {
        const updatedAssignedTickets = [...state.tickets];
        updatedAssignedTickets[assignedTicketIndex] = {
          ...updatedAssignedTickets[assignedTicketIndex],
          assignTo: userID,
        };
        return {
          ...state,
          tickets: updatedAssignedTickets,
          singleTicket: { ...state.singleTicket, assignTo: userID },
        };
      } else {
        return state;
      }
    case "TICKETS_LOADING":
      return {
        ...state,
        ticketsLoading: action.payload,
      };
    case "SET_VENDOR_TICKETS_CATEGORIES":
      return {
        ...state,
        vendorTicketCategories: action.payload,
      };
    case "SET_PLATFORM_TICKETS_CATEGORIES":
      return {
        ...state,
        platformTicketCategories: action.payload,
      };
      case "ISSUED_TICKETS":
        return{
          ...state,
          issuesTicket: action.payload,
        }
    default:
      return state;
  }
};

export default ticketsReducer;
