import Repository from "./Repository";
const MY_TTICKETS = "/ticket/my";
const SINGLE_TICKET = "/ticket";
const TICKETS_CATEGORIES = "/ticket-categories";
const TICKET_ISSUER = "/ticket/issuer";

export default {
  getAllTickets(payload) {
    let { sellerId, limit, lastDocument, category } = payload;
    let paginatedParam = lastDocument ? `&lastDocument=${lastDocument}` : "";
    let categoryParam = category ? `&category=${category}` : "";
    return Repository.get(
      `${MY_TTICKETS}/${sellerId}?limit=${limit}${paginatedParam}${categoryParam}`
    );
  },
  getSingleTicket(ID) {
    return Repository.get(`${SINGLE_TICKET}/${ID}`);
  },
  UpdateTicket(payload) {
    return Repository.put(`/ticket`, payload);
  },
  getAllTicketsCategories: (ticketCategory) => {
    return Repository.get(`${TICKETS_CATEGORIES}/${ticketCategory}`);
  },
  getAllTicketsIssued: (uid) => {
    return Repository.get(`${TICKET_ISSUER}/${uid}`);
  },
};
