import OnlyHeader from "components/Headers/OnlyHeader";
import { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Table,
} from "reactstrap";
import { getAllTicketsIssue } from "../store/actions/ticketsActions";

const MultivendorSupport = () => {
  const { uid } = useSelector((state) => state.authUser);
  const { issuesTicket } = useSelector((state) => state.ticket);
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [filterTicket, setFilterTicket] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [categoryDropdownOpen, setCategoryDropdownOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const toggleCategory = () =>
    setCategoryDropdownOpen((prevState) => !prevState);
  const handleDropdownSelect = (dropdownValue) => {
    setSelectedCategory(dropdownValue);
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const filterTicketsByCategory = (tickets, category) => {
    return tickets.filter(
      (ticket) =>
        category === "" ||
        ticket.category.toLowerCase() === category.toLowerCase()
    );
  };

  const filterTicketsByStatus = (tickets, status) => {
    return tickets.filter(
      (ticket) =>
        status === "" || ticket.status.toLowerCase() === status.toLowerCase()
    );
  };

  const filteredBySearch = issuesTicket.filter((ticket) => {
    const matchesSearchQuery =
      ticket.category.toLowerCase().includes(searchQuery.toLowerCase()) ||
      ticket.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
      ticket.status.toLowerCase().includes(searchQuery.toLowerCase()) ||
      String(ticket.ticketNumber).includes(searchQuery);

    return matchesSearchQuery;
  });

  const filteredByStatus = filterTicketsByStatus(
    filteredBySearch,
    filterTicket
  );
  const filteredByCategory = filterTicketsByCategory(
    filteredByStatus,
    selectedCategory.toLowerCase()
  );

  const getStatusBadgeClass = (status) => {
    switch (status) {
      case "open":
        return "badge badge-success";
      case "close":
        return "badge badge-warning";
      default:
        return "badge badge-secondary";
    }
  };

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      await dispatch(getAllTicketsIssue(uid));
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    };

    fetchData();
  }, []);

  return (
    <>
      <OnlyHeader />
      <Container className="mt--9 position-absolute " fluid>
        <div>
          <Dropdown isOpen={dropdownOpen} toggle={toggle} direction={"down"}>
            <DropdownToggle className="text-capitalize">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-filter"
                viewBox="0 0 16 16"
              >
                <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5m-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5m-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5" />
              </svg>{" "}
              {filterTicket || "Filter"}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => setFilterTicket("")}>
                All Tickets
              </DropdownItem>
              <DropdownItem onClick={() => setFilterTicket("open")}>
                Open
              </DropdownItem>
              <DropdownItem onClick={() => setFilterTicket("close")}>
                Closed
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </Container>
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <LoadingOverlay
              active={isLoading}
              spinner
              text={"Tickets Loading..."}
            >
              <Card className="shadow-sm">
                <CardHeader className="border-0 d-lg-flex d-md-flex d-sm-flex justify-content-between align-items-center">
                  <h3>FullyLocal Support</h3>
                  <div style={{ maxWidth: "200px" }} className="mb-2">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-search" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Search"
                        type="text"
                        value={searchQuery}
                        onChange={handleSearch}
                      />
                    </InputGroup>
                  </div>
                  <Dropdown
                    isOpen={categoryDropdownOpen}
                    toggle={toggleCategory}
                  >
                    <DropdownToggle color="primary text-capitalize" caret>
                      {selectedCategory || "All Categories"}
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={() => handleDropdownSelect("")}>
                        All Categories
                      </DropdownItem>
                      <DropdownItem
                        onClick={() =>
                          handleDropdownSelect("Account and Access")
                        }
                      >
                        Account and Access
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => handleDropdownSelect("Technical Issues")}
                      >
                        Technical Issues
                      </DropdownItem>
                      <DropdownItem
                        onClick={() =>
                          handleDropdownSelect("Security Concerns")
                        }
                      >
                        Security Concerns
                      </DropdownItem>
                      <DropdownItem
                        onClick={() =>
                          handleDropdownSelect("General Inquiries/Questions")
                        }
                      >
                        General Inquiries/Questions
                      </DropdownItem>
                      <DropdownItem
                        onClick={() =>
                          handleDropdownSelect("Content or Data Issues")
                        }
                      >
                        Content or Data Issues
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => handleDropdownSelect("Other/Custom")}
                      >
                        Other/Custom
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </CardHeader>
                <CardBody className="p-0">
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead">
                      <tr>
                        <th scope="col">Ticket No</th>
                        <th scope="col">Description</th>
                        <th scope="col">Category</th>
                        <th scope="col">Status</th>
                        <th scope="col">Detail</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredByCategory?.map((issue, index) => {
                        return (
                          <>
                            <tr key={index}>
                              <td>#{issue?.ticketNumber}</td>
                              <td className="truncate">{issue?.description}</td>
                              <td>{issue?.category}</td>
                              <td>
                                <span
                                  className={getStatusBadgeClass(issue?.status)}
                                >
                                  {issue?.status}
                                </span>
                              </td>
                              <td>
                                <Button
                                  color="info"
                                  size="sm"
                                  onClick={() => {
                                    history.push(
                                      `/admin/support-detail/${issue?.id}`
                                    );
                                  }}
                                >
                                  <i class="fas fa-eye"></i>{" "}
                                </Button>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </LoadingOverlay>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default MultivendorSupport;
