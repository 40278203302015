import { Switch } from "@mui/material";
import { Avatar, Segmented } from "antd";
import OnlyHeader from "components/Headers/OnlyHeader";
import TeamManagementModal from "components/Models/TeamManagementModal";
import TeamCSVModal from "components/TeamCSVModal";
import TabSelection from "components/TeamManagement/TabSelection";
import { role } from "constant/teamRoles";
import React, { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, CardHeader, Container, Row, Table } from "reactstrap";
import { suspendUser } from "store/actions/distributorAction";
import { getUsersWithRole } from "store/actions/distributorAction";

const TeamManagement = () => {
  const dispatch = useDispatch();

  const { uid } = useSelector((state) => state.authUser);
  const { usersWithRole, getLoading } = useSelector(
    (state) => state.distributor
  );

  const [suspendStatus, setSuspendStatus] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState("logistics");
  const [modal, setModal] = useState(false);
  const [modalCSV, setModalCSV] = useState(false);

  const toggleCSV = () => setModalCSV(!modalCSV);

  const toggle = () => setModal(!modal);
  const handleSuspendStatusUpdate = (e, id) => {
    setSuspendStatus(e.target.checked);
    dispatch(suspendUser(e.target.checked, id));
  };

  useEffect(() => {
    dispatch(getUsersWithRole(uid, selectedTeam));
  }, [selectedTeam]);
  return (
    <>
      <OnlyHeader />
      <Container
        className="mt--9 left--2 position-absolute d-flex justify-content-start"
        fluid
      >
        <TabSelection
          options={teams}
          onChange={(value) => setSelectedTeam(value)}
        />
      </Container>

      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <LoadingOverlay active={getLoading} spinner text="Loading">
              <Card className="shadow-sm">
                <CardHeader className="border-0 d-lg-flex d-md-flex justify-content-between align-items-center">
                  <h3 className="mb-2">Team Management</h3>
                  <div>
                    <Button
                      className="mb-2 text-capitalize"
                      color="primary"
                      type="button"
                      onClick={toggle}
                    >
                      Add {selectedTeam} Member
                    </Button>
                    <Button
                      className="mb-2"
                      color="info"
                      type="button"
                      onClick={toggleCSV}
                    >
                      Upload CSV
                    </Button>
                  </div>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead">
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Organization</th>
                      <th scope="col">Country</th>
                      <th scope="col">Status</th>
                      <th scope="col" className="text-right">
                        Actions
                      </th>
                    </tr>
                  </thead>

                  {usersWithRole?.length == 0 ? (
                    <div className=" mt-2">
                      <p className="mx-4">Yet Data not found!</p>
                    </div>
                  ) : (
                    <tbody>
                      {usersWithRole &&
                        usersWithRole.map((item) => {
                          return (
                            <>
                              <tr>
                                <th scope="row">
                                  <span className="mb-0 text-sm">
                                    {item?.username}
                                  </span>
                                </th>
                                <td>{item?.email}</td>
                                <td>{item?.organization}</td>
                                <td>{item?.country?.label}</td>
                                <td>
                                  {item?.active == true
                                    ? "Active"
                                    : "Suspended"}
                                </td>
                                <td className="text-right">
                                  <Switch
                                    value={suspendStatus}
                                    defaultChecked={item?.active}
                                    onChange={(e) => {
                                      handleSuspendStatusUpdate(e, item?.id);
                                    }}
                                  />
                                </td>
                              </tr>
                            </>
                          );
                        })}
                    </tbody>
                  )}
                </Table>
              </Card>
            </LoadingOverlay>
          </div>
        </Row>
      </Container>
      <TeamManagementModal
        isOpen={modal}
        toggle={toggle}
        selectedTeam={selectedTeam}
      />
      <TeamCSVModal toggle={toggleCSV} modal={modalCSV} />
    </>
  );
};

export default TeamManagement;

const teams = [role.LOGISTICS, role.CUSTOMER_SUPPORT, role.FINANCE];
