import firebase from "../firebase";
import { v4 as uuidv4 } from "uuid";
import { createNullCache } from "@algolia/cache-common";
import algoliasearch from "algoliasearch";
import { uploadVideoFile } from "utils/videoUpload";
import { deleteVideoFile } from "utils/videoUpload";
const client = algoliasearch("AKYAZJGVV9", "f5306d66f87dbf20ad044268210656ed", {
  responsesCache: createNullCache(),
});
export const itemsIndex = client.initIndex("items");
export const createItem =
  (payload, onSuccess) => async (dispatch, getState) => {
    await dispatch(Loading(true));
    try {
      const imageURLs = [];
      let threeSixtyUrl = payload?.threeSixtyUrl;
      let cadFile = payload?.cadFile;
      let videoFile = payload?.detail?.videoFile;
      let videoUrl = null;
      if (videoFile) {
        videoUrl = await uploadVideoFile(videoFile, dispatch);
      }

      dispatch(itemUploadingProgress(parseInt(60)));

      if (payload?.detail?.images && payload?.detail?.images?.length > 0) {
        for await (let item of payload.detail.images) {
          if (item?.originFileObj) {
            let filName1 = item?.originFileObj.name;
            let filName2 = filName1.slice(filName1.lastIndexOf("."));
            let filName3 = uuidv4() + filName2.toLowerCase();
            let storageRef = await firebase
              .storage()
              .ref("Details/" + filName3)
              .put(item?.originFileObj);
            let url = await storageRef.ref.getDownloadURL();
            imageURLs.push(url);
          }
        }
      }
      dispatch(itemUploadingProgress(parseInt(70)));
      if (threeSixtyUrl && threeSixtyUrl != "") {
        let filName1 = threeSixtyUrl.name;
        let filName2 = filName1.slice(filName1.lastIndexOf("."));
        let filName3 = uuidv4() + filName2.toLowerCase();
        let storageRef = await firebase
          .storage()
          .ref("ThreeSixty/" + filName3)
          .put(threeSixtyUrl);
        threeSixtyUrl = await storageRef.ref.getDownloadURL();
      }
      dispatch(itemUploadingProgress(parseInt(85)));
      if (cadFile && cadFile != "") {
        let filName1 = cadFile.name;
        let filName2 = filName1.slice(filName1.lastIndexOf("."));
        let filName3 = uuidv4() + filName2.toLowerCase();
        let storageRef = await firebase
          .storage()
          .ref("cadFiles/" + filName3)
          .put(cadFile);
        cadFile = await storageRef.ref.getDownloadURL();
      }
      dispatch(itemUploadingProgress(parseInt(100)));
      await firebase
        .firestore()
        .collection("items")
        .add({
          ...payload,
          detail: {
            ...payload?.detail,
            images: imageURLs,
            videoFile: videoUrl,
          },
          cadFile: cadFile || "",
          threeSixtyUrl: threeSixtyUrl || "",
          isDeleted: false,
          createdAt: firebase.firestore.Timestamp.now(),
        })
        .then(() => {
          dispatch(Loading(false));
          onSuccess();
        });
    } catch (error) {
      console.log(error.message);
      dispatch(Loading(false));
    }
  };
export const getItems =
  (item, uid, search, batchSize = 2) =>
  async (dispatch, getState) => {
    await dispatch(Loading(true));
    try {
      if (search) {
        itemsIndex.search(search).then(async (response) => {
          dispatch(Loading(false));
          const objectIDs = response.hits.map((hit) => hit.objectID);
          const matchingItems = [];
          for (const objectID of objectIDs) {
            const docRef = await firebase
              .firestore()
              .collection("items")
              .doc(objectID);
            const docSnapshot = await docRef.get();
            if (docSnapshot.exists) {
              const itemData = { id: docSnapshot.id, ...docSnapshot.data() };
              if (itemData.creatorId === uid) {
                matchingItems.push(itemData);
              }
            }
          }
          dispatch({
            type: "GET_ALL_ITEMS",
            payload: matchingItems,
            lastDocument: {},
            active: false,
          });
        });
      } else {
        const itemsRef = await firebase
          .firestore()
          .collection("items")
          .where("creatorId", "==", uid);
        let query = itemsRef.orderBy("createdAt", "desc").limit(batchSize);
        if (item && item.lastDocument) {
          query = query.startAfter(item.lastDocument);
        }
        let isFirstFetch = item === null;
        const unsubscribe = query.onSnapshot(async (snapshot) => {
          const tempData = [];
          for (const doc of snapshot.docs) {
            const itemData = doc.data();
            tempData.push({
              ...itemData,
              id: doc.id,
            });
          }
          const lastDocument =
            snapshot.docs.length > 0
              ? snapshot.docs[snapshot.docs.length - 1]
              : null;
          const newItem = { lastDocument };
          const previousQuestions = getState().item.allItems;
          const newItems = tempData.filter((item) => {
            const exists = previousQuestions.some(
              (prevQuestion) => prevQuestion.id === item.id
            );
            return !exists;
          });
          let updatedItems = [];
          if (isFirstFetch) {
            isFirstFetch = false;
            dispatch({
              type: "GET_ALL_ITEMS",
              payload: tempData,
              lastDocument: newItem,
              active: true,
            });
            await dispatch(Loading(false));
          } else if (newItems.length > 0) {
            updatedItems = item
              ? [...previousQuestions, ...tempData]
              : tempData;
            dispatch({
              type: "GET_ALL_ITEMS",
              payload: updatedItems,
              lastDocument: newItem,
              active: true,
            });
            await dispatch(Loading(false));
          } else {
            updatedItems = item
              ? [...previousQuestions, ...tempData]
              : tempData;
            dispatch({
              type: "GET_ALL_ITEMS",
              payload: updatedItems,
              lastDocument: newItem,
              active: false,
            });
            await dispatch(Loading(false));
          }
        });
        return () => unsubscribe();
      }
    } catch (error) {
      console.log(error.message);
      dispatch(Loading(false));
    }
  };
export const getPromotionItems = (uid) => async (dispatch) => {
  try {
    const itemsRef = await firebase
      .firestore()
      .collection("items")
      .where("creatorId", "==", uid);
    itemsRef.onSnapshot(async (data) => {
      let tempData = [];
      for (let doc of data.docs) {
        let id = doc.id;
        let data1 = doc.data();
        tempData.push({ id: id, ...data1 });
      }
      dispatch({ type: "PROMOTION_ITEMS", payload: tempData });
    });
  } catch (error) {
    console.log(error.message);
    dispatch(Loading(false));
  }
};
export const getSingleItem = (docId) => async (dispatch) => {
  try {
    const itemDoc = await firebase
      .firestore()
      .collection("items")
      .doc(docId)
      .get();
    if (itemDoc.exists) {
      let itemData = {};
      itemData = { ...itemDoc.data(), id: itemDoc?.id };
      dispatch({ type: "GET_SINGLE_ITEM", payload: itemData });
    } else {
      console.log("Document not found.");
    }
  } catch (error) {
    console.log("Error fetching document:", error.message);
  }
};
export const clearSingleItem = () => async (dispatch) => {
  dispatch({ type: "CLEAR_SINGLE_ITEM", payload: {} });
};
export const deleteItem = (payload, onSuccess) => async (dispatch) => {
  dispatch(deleteLoading(true));
  try {
    await firebase.firestore().collection("items").doc(payload.id).delete();
    dispatch(deleteLoading(false));
    if (payload?.cadFile) {
      const cadFileRef = firebase.storage().ref().child(payload.cadFile);
      await cadFileRef.delete();
    }
    if (payload?.threeSixtyUrl) {
      const threeSixtyUrlRef = firebase
        .storage()
        .ref()
        .child(payload.threeSixtyUrl);
      await threeSixtyUrlRef.delete();
    }
    if (payload?.detailImages && payload.detailImages.length > 0) {
      await Promise.all(
        payload.detailImages.map(async (doc) => {
          await firebase.storage().refFromURL(doc).delete();
        })
      );
    }
    // const cadFileRef = firebase.storage().ref().child(payload?.cadFile);
    // const threeSixtyUrlRef = firebase
    //   .storage()
    //   .ref()
    //   .child(payload?.threeSixtyUrl);
    //   await cadFileRef.delete();
    //   await threeSixtyUrlRef.delete();
    //   payload?.detailImages?.forEach(async (doc) => {
    //     await firebase.storage().refFromURL(doc).delete();
    //   });
    setTimeout(() => {
      alert("Item deleted successfully!");
      onSuccess();
    }, 0);
  } catch (error) {
    console.log(error.message);
    dispatch(deleteLoading(false));
  }
};
export const getSelectedCategoryItems = (categoryId) => async (dispatch) => {
  try {
    const querySnapshot = await firebase
      .firestore()
      .collection("items")
      .where("detail.categoryId", "==", categoryId)
      .get();
    const items = [];
    querySnapshot.forEach((doc) => {
      const itemData = doc.data();
      itemData.id = doc.id;
      items.push(itemData);
    });
    dispatch({ type: "GET_SELECTED_CATEGORY", payload: items });
  } catch (error) {
    console.log(error.message);
  }
};

export const editItem = (payload, id, onSuccess) => async (dispatch) => {
  console.log(payload);
  await dispatch(Loading(true));
  try {
    const imageURLs = [];
    let threeSixtyUrl = payload?.threeSixtyUrl;
    let cadFile = payload?.cadFile;
    let videoFile = payload?.detail?.videoFile;
    let videoUrl = null;

    if (typeof videoFile === "string" && videoFile !== "") {
      videoUrl = videoFile;
    } else if (videoFile) {
      videoUrl = await uploadVideoFile(videoFile, dispatch);
    }

    if (payload.detail.images.length && payload.detail.images.length > 0) {
      for await (let item of payload.detail.images) {
        if (item?.originFileObj) {
          let filName1 = item?.originFileObj.name;
          let filName2 = filName1.slice(filName1.lastIndexOf("."));
          let filName3 = uuidv4() + filName2.toLowerCase();
          let storageRef = await firebase
            .storage()
            .ref("Details/" + filName3)
            .put(item?.originFileObj);
          let url = await storageRef.ref.getDownloadURL();
          imageURLs.push(url);
        } else {
          imageURLs.push(item?.url);
        }
      }
    }
    if (threeSixtyUrl && typeof threeSixtyUrl != "string") {
      let filName1 = threeSixtyUrl.name;
      let filName2 = filName1.slice(filName1.lastIndexOf("."));
      let filName3 = uuidv4() + filName2.toLowerCase();
      let storageRef = await firebase
        .storage()
        .ref("ThreeSixty/" + filName3)
        .put(threeSixtyUrl);
      threeSixtyUrl = await storageRef.ref.getDownloadURL();
    }
    if (cadFile && typeof cadFile != "string") {
      let filName1 = cadFile.name;
      let filName2 = filName1.slice(filName1.lastIndexOf("."));
      let filName3 = uuidv4() + filName2.toLowerCase();
      let storageRef = await firebase
        .storage()
        .ref("cadFiles/" + filName3)
        .put(cadFile);
      cadFile = await storageRef.ref.getDownloadURL();
    }
    await firebase
      .firestore()
      .collection("items")
      .doc(id)
      .set({
        ...payload,
        detail: { ...payload?.detail, images: imageURLs, videoFile: videoUrl },
        cadFile: cadFile,
        threeSixtyUrl: threeSixtyUrl,
        updatedAt: firebase.firestore.Timestamp.now(),
        isDeleted: false,
      })
      .then(() => {
        dispatch(Loading(false));
        alert("Item updated successfully!");
        onSuccess();
      });
  } catch (error) {
    console.log(error.message);
    dispatch(Loading(false));
  }
};

///Upload Multiple Images

export const uploadImages = (payload, id, onSuccess) => async (dispatch) => {
  console.log(payload);
  await dispatch(Loading(true));
  try {
    const imageURLs = payload?.item.detail?.images;
    console.log(imageURLs);
    if (payload.images.length > 0) {
      for await (let item of payload.images) {
        if (item) {
          const imageFile = item;
          const fileName = item.name;
          const fileExtension = fileName.slice(fileName.lastIndexOf("."));
          const fileNameWithExtension = uuidv4() + fileExtension.toLowerCase();
          const storageRef = firebase
            .storage()
            .ref("Details/" + fileNameWithExtension);
          const uploadTaskSnapshot = await storageRef.put(imageFile);
          const downloadURL = await uploadTaskSnapshot.ref.getDownloadURL();
          imageURLs.push(downloadURL);
        } else {
          imageURLs.push(item?.url);
        }
      }
    }
    console.log(imageURLs);
    await firebase
      .firestore()
      .collection("items")
      .doc(id)
      .set({
        ...payload.item,
        detail: { ...payload?.item?.detail, images: imageURLs },
        updatedAt: firebase.firestore.Timestamp.now(),
      })
      .then(() => {
        dispatch(Loading(false));
        alert("Item updated successfully!");
        onSuccess();
      });
  } catch (error) {
    console.log(error.message);
    dispatch(Loading(false));
  }
};

export const getColors = () => async (dispatch) => {
  try {
    const querySnapshot = await firebase.firestore().collection("colors").get();
    const colors = [];
    querySnapshot.forEach((doc) => {
      const colorData = doc.data();
      colors.push(colorData);
    });
    dispatch({ type: "GET_ALL_COLORS", payload: colors });
  } catch (error) {
    console.log(error.message);
  }
};

export const itemUploadingProgress = (val) => async (dispatch) => {
  dispatch({ type: "ITEM_UPLOADING_PROGRESS", payload: val });
};
export const Loading = (val) => async (dispatch) => {
  dispatch({ type: "LOADING", payload: val });
};
export const deleteLoading = (val) => async (dispatch) => {
  dispatch({ type: "DELETE_LOADING", payload: val });
};
