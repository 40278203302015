import { PlusOutlined } from "@ant-design/icons";
import { Upload } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner,
  Container,
  Row,
  Col,
} from "reactstrap";
import { setIsContinueClicked } from "store/actions/reportAnIssueAction";
import { setEmailFieldDisable } from "store/actions/reportAnIssueAction";
import { setIssueDetailsIfUserLoggedIn } from "store/actions/reportAnIssueAction";
import { addIssueToDB } from "store/actions/reportAnIssueAction";
import { setIssueDetails } from "store/actions/reportAnIssueAction";
import { checkUserByEmail } from "store/actions/reportAnIssueAction";
import { toggleIssueModal } from "store/actions/reportAnIssueAction";
import { getSupportTicket } from "store/actions/supportAction";
import { getTicketsCategories } from "store/actions/ticketsActions";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const ReportAnIssueModal = () => {
  const dispatch = useDispatch();
  const {
    modalIssue,
    isContinue,
    isLoading,
    issueDetails,
    nameFieldDisable,
    emailFieldDisable,
  } = useSelector((store) => store.reportAnIssue);
  const { platformTicketCategories } = useSelector((store) => store.ticket);
  const { uid } = useSelector((store) => store.authUser);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const videoRef = useRef(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [selectedVideo, setSelectedVideo] = useState(null);
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const checkFileType = (file) => {
    if (file && /\.(png|jpe?g)$/i.test(file.name)) {
      return true;
    } else {
      alert("You can only upload PNG/JPG/JPEG file!");
      return false;
    }
  };
  const handleChange = (newFileList) => {
    const filteredList = newFileList.filter((file) => checkFileType(file));
    const temp = filteredList.map((file) => {
      return { ...file, status: "done" };
    });
    dispatch(
      setIssueDetails({
        ...issueDetails,
        images: temp,
      })
    );
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const handleVideoChange = (e) => {
    setModalOpen(true);
    const file = e.target.files[0];
    console.log("video details", file);
    if (file && file.type === "video/mp4") {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedVideo({
          file: file,
          previewURL: URL.createObjectURL(file),
        });
      };

      dispatch(setIssueDetails({ ...issueDetails, videoFile: file }));

      reader.readAsDataURL(file);
    } else {
      alert("Invalid file format. Please upload an MP4 video.");
    }
  };

  const handleDeleteVideo = () => {
    setSelectedVideo(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    dispatch(setIssueDetails({ ...issueDetails, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { category, images } = issueDetails;

    if (!category) {
      alert("Please choose the category!");
      return;
    }
    if (images?.length > 5) {
      alert("Max 5 images allowed!");
      return;
    }
    const history = [
      {
        event: `${issueDetails.issuerName} open the ticket`,
        createdAt: new Date(),
      },
    ];

    dispatch(addIssueToDB(issueDetails, history));
    dispatch(getSupportTicket())
  };

  useEffect(() => {
    dispatch(getTicketsCategories("platformTickets"));
  }, []);

  useEffect(() => {
    if (uid) {
      dispatch(setIsContinueClicked(true));
      dispatch(setIssueDetailsIfUserLoggedIn());
    } else {
      dispatch(setEmailFieldDisable(false));
      dispatch(setIsContinueClicked(false));
    }
  }, [dispatch, uid]);

  return (
    <Modal
      isOpen={modalIssue}
      toggle={() => {
        dispatch(toggleIssueModal());
      }}
    >
      <ModalHeader
        className="border-bottom"
        toggle={() => {
          dispatch(toggleIssueModal());
        }}
      >
        <span className="fs--17 mb-0">Report An Issue</span>
      </ModalHeader>
      <Form onSubmit={handleSubmit}>
        <ModalBody>
          <FormGroup>
            <Label for="email">Email</Label>
            <Input
              id="email"
              name="issuerEmail"
              placeholder="Enter your email here..."
              type="email"
              value={issueDetails.issuerEmail}
              disabled={emailFieldDisable}
              onChange={handleInputChange}
              required
            />
          </FormGroup>
          {isContinue && (
            <>
              <FormGroup>
                <Label for="name">Name</Label>
                <Input
                  id="name"
                  name="issuerName"
                  placeholder="Enter your name here..."
                  type="text"
                  disabled={nameFieldDisable}
                  value={issueDetails.issuerName}
                  onChange={handleInputChange}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label className="d-block" for="category">
                  Category
                </Label>
                <UncontrolledDropdown
                  isOpen={dropdownOpen}
                  toggle={toggleDropdown}
                  direction={"right"}
                  required
                >
                  <DropdownToggle className="text-capitalize" caret  color="primary">
                    {issueDetails?.category
                      ? issueDetails.category
                      : "select category"}
                  </DropdownToggle>
                  <DropdownMenu className="m-1">
                    {platformTicketCategories &&
                      platformTicketCategories.map((category, index) => (
                        <DropdownItem
                          onClick={() =>
                            dispatch(
                              setIssueDetails({
                                category,
                              })
                            )
                          }
                          key={index}
                          className="text-capitalize"
                        >
                          {category}
                        </DropdownItem>
                      ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </FormGroup>
              <Container>
                <Row>
                  <Col>
                    <Upload
                      listType="picture-card"
                      fileList={issueDetails?.images}
                      multiple
                      onPreview={handlePreview}
                      onChange={(info) => handleChange(info.fileList)}
                      accept="image/PNG, image/jpeg, image/jpg"
                      showUploadList={{ showTooltip: false }}
                    >
                      {uploadButton}
                    </Upload>
                    <small className="text-danger">Max 5 images allowed.</small>
                  </Col>
                </Row>
              </Container>
              <Modal isOpen={previewOpen} toggle={handleCancel}>
                <ModalHeader toggle={handleCancel}>{previewTitle}</ModalHeader>
                <ModalBody>
                  <img
                    alt="example"
                    style={{
                      width: "100%",
                    }}
                    src={previewImage}
                  />
                </ModalBody>
              </Modal>
              <Row>
                <Col md={6}>
                  {!selectedVideo ? (
                    <FormGroup
                      className="m-3 p-3 rounded d-flex justify-content-center align-items-center video-container"
                      style={{
                        border: "1px dashed lightgray",
                        backgroundColor: "#fafafa",
                        width: "100px",
                        height: "100px",
                        cursor: "pointer",
                      }}
                    >
                      <span onClick={() => videoRef?.current.click()}>
                        <i className="fas fa-solid fa-video fa-2x"></i>
                      </span>
                      <input
                        ref={videoRef}
                        onChange={handleVideoChange}
                        type="file"
                        placeholder=""
                        name="video"
                        accept=".mp4"
                        className="d-none"
                      />
                    </FormGroup>
                  ) : (
                    <div className="d-flex position-relative">
                      <video
                        ref={videoRef}
                        width="100%"
                        controls
                        className="p-3"
                      >
                        <source
                          src={selectedVideo?.previewURL}
                          type="video/mp4"
                        />
                      </video>
                      {selectedVideo && (
                        <>
                          <button
                            color="tranparent"
                            className="my-5 position-absolute border-0 bg-transparent"
                            style={{ top: "-41px", right: "3px" }}
                            onClick={(e) => {
                              e.preventDefault();
                              videoRef.current.pause();
                              handleDeleteVideo();
                            }}
                          >
                            <i
                              className="fas fa-times text-white bg-danger rounded-circle"
                              style={{
                                fontSize: "10px",
                                paddingLeft: "8px",
                                paddingRight: "8px",
                                paddingTop: "6px",
                                paddingBottom: "6px",
                              }}
                            ></i>
                          </button>
                        </>
                      )}
                    </div>
                  )}
                </Col>
              </Row>
              <FormGroup>
                <Label for="description">Description</Label>
                <Input
                  id="description"
                  name="description"
                  type="textarea"
                  value={issueDetails.description}
                  onChange={handleInputChange}
                  required
                />
              </FormGroup>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          {!isContinue && (
            <Button
              color="primary"
              onClick={() =>
                issueDetails?.issuerEmail
                  ? dispatch(checkUserByEmail(issueDetails?.issuerEmail))
                  : alert("Enter email before continue!")
              }
              className="px-5 d-flex mx-auto align-items-center"
            >
              {isLoading && <Spinner size="sm" className="mr-2" />}
              Continue <i className="fas fa-solid fa-arrow-right ml-1"></i>{" "}
            </Button>
          )}
          {isContinue && (
            <>
              <Button
                color="primary"
                type="submit"
                className="px-5 d-flex mx-auto"
                disabled={isLoading}
              >
                {isLoading && <Spinner size="sm" className="mr-2" />}
                Submit
              </Button>
            </>
          )}
        </ModalFooter>
      </Form>
    </Modal>
  );
};
