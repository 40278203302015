import firebase from "../firebase";
export const addPromotion = (promotionData, onSuccess) => async (dispatch) => {
  dispatch(addLoader(true));
  try {
    await firebase
      .firestore()
      .collection("promotions")
      .add({
        ...promotionData,
        createdAt: firebase.firestore.Timestamp.now(),
      })
      .then(() => {
        dispatch(addLoader(false));
        setTimeout(() => {
          alert("Promotion added successfully!");
          onSuccess();
        }, 0);
      });
  } catch (error) {
    alert(error.message);
    dispatch(addLoader(false));
  }
};
export const getPromotions = (uid) => async (dispatch) => {
  dispatch(getLoader(true));
  try {
    firebase
      .firestore()
      .collection("promotions")
      .where("creatorId", "==", uid)
      .onSnapshot(async (data) => {
        let tempData = [];
        for (let doc of data.docs) {
          let id = doc.id;
          let data1 = doc.data();
          tempData.push({ id: id, ...data1 });
        }
        tempData.sort((a, b) => b.createdAt - a.createdAt);
        dispatch({ type: "GET_ALL_PROMOTIONS", payload: tempData });
        dispatch(getLoader(false));
      });
  } catch (error) {
    alert(error.message);
    dispatch(getLoader(false));
  }
};

export const deletePromotion = (id, onSuccess) => async (dispatch) => {
  dispatch(addLoader(true));
  try {
    await firebase
      .firestore()
      .collection("promotions")
      .doc(id)
      .delete()
      .then(() => {
        dispatch(addLoader(false));
        setTimeout(() => {
          alert("Promotion deleted successfully!");
          onSuccess();
        }, 0);
      });
  } catch (error) {
    alert(error.message);
    dispatch(addLoader(false));
  }
};
export const updatePromotion =
  (promotionData, id, onSuccess) => async (dispatch) => {
    //console.log(linksData);
    dispatch(addLoader(true));
    try {
      await firebase
        .firestore()
        .collection("promotions")
        .doc(id)
        .update({
          ...promotionData,
          updatedAt: firebase.firestore.Timestamp.now(),
        })
        .then(() => {
          dispatch(addLoader(false));
          setTimeout(() => {
            alert("Promotion updated successfully!");
            onSuccess();
          }, 0);
        });
    } catch (error) {
      alert(error.message);
      dispatch(addLoader(false));
    }
  };
const addLoader = (data) => async (dispatch) => {
  dispatch({
    type: "ADD_LOADER",
    payload: data,
  });
};
const getLoader = (data) => async (dispatch) => {
  dispatch({
    type: "GET_LOADER",
    payload: data,
  });
};
