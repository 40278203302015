import firebase from "../firebase";

export const getCategories = () => async (dispatch) => {
   await  dispatch(Loading(true));
    try {
      firebase
        .firestore()
        .collection("categories")
        .onSnapshot(async (data) => {
          let tempData = [];
          for (let doc of data.docs) {
            let id = doc.id;
            let data1 = doc.data();
            tempData.push({ id: id, ...data1 });
          }
          dispatch({ type: "GET_ALL_CATEGORIES", payload: tempData });
          dispatch(Loading(false));
        });
    } catch (error) {
      console.log(error.message);
      dispatch(Loading(false));
    }
  };
export const Loading = (val) => async (dispatch) => {
  dispatch({ type: "LOADING", payload: val });
};
