const initialData = {
  addLoading: false,
  getLoading: false,
  allPromotions: [],
};
const itemReducer = (state = initialData, action) => {
  switch (action.type) {
    case "ADD_LOADER":
      return {
        ...state,
        addLoading: action.payload,
      };
    case "GET_LOADER":
      return {
        ...state,
        getLoading: action.payload,
      };

    case "GET_ALL_PROMOTIONS":
      return {
        ...state,
        allPromotions: action.payload,
      };
    default:
      return state;
  }
};

export default itemReducer;
