import firebase from "../firebase";
import { RepositoryFactory } from "../../repository/RepositoryFactory";
var distributor = RepositoryFactory.get("distributor");
export const sendInvite = (payload, onSuccess) => async (dispatch) => {
  await dispatch(addLoader(true));
  try {
    const emailNotExists = await dispatch(checkEmailExists(payload.email));

    if (emailNotExists) {
      let inviteDocRef = await firebase
        .firestore()
        .collection("invites")
        .add(payload);
      let inviteDocId = inviteDocRef.id;
      let updatedPayload = { ...payload, id: inviteDocId };
      const { data } = await distributor.inviteSend(updatedPayload);
      // console.log(data);
      if (data.success) {
        dispatch(addLoader(false));
        onSuccess();
      } else {
        console.log("Invite send failed");
        dispatch(addLoader(false));
      }
    } else {
      alert("Email already exists. Invite not sent. Try another email!");
      dispatch(addLoader(false));
    }
  } catch (error) {
    console.log("catchInInviteAction", error);
    dispatch(addLoader(false));
  }
};
export const checkEmailExists = (email) => async (dispatch) => {
  dispatch(addLoader(true));
  try {
    // console.log(">>>>>>>>>>Email:", email);
    const usersSnapshot = await firebase
      .firestore()
      .collection("users")
      .where("email", "==", email)
      .get();
    const invitedVendorsSnapshot = await firebase
      .firestore()
      .collection("invitedVendors")
      .where("email", "==", email)
      .get();
    const invitesSnapshot = await firebase
      .firestore()
      .collection("invites")
      .where("email", "==", email)
      .get();

    if (
      usersSnapshot.empty &&
      invitedVendorsSnapshot.empty &&
      invitesSnapshot.empty
    ) {
      dispatch(setIsEmailNotExists(true));
      return true;
    } else {
      dispatch(setIsEmailNotExists(false));
      return false;
    }
  } catch (error) {
    console.log("ERROR: error in checking email existence.", error);
    dispatch(addLoader(false));
  }
};
export const getUsersWithRole = (creatorId, role) => async (dispatch) => {
  await dispatch(getLoader(true));
  const usersRef = firebase.firestore().collection("users");
  const query = usersRef
    .where("creatorId", "==", creatorId)
    .where("role", "==", role);
  const unsubscribe = query.onSnapshot(
    (querySnapshot) => {
      const data = [];
      querySnapshot.forEach((doc) => {
        data.push({ id: doc.id, ...doc.data() });
      });
      dispatch({
        type: "USER_WITH_ROLE",
        payload: data,
      });
      dispatch(getLoader(false));
    },
    (error) => {
      console.error("Error in getUsersWithRole listener", error);
      dispatch(getLoader(false));
    }
  );
  return unsubscribe;
};

export const suspendUser = (status, uid) => async (dispatch) => {
  await dispatch(addLoader(true));
  try {
    await firebase
      .firestore()
      .collection("users")
      .doc(uid)
      .update({ active: status });
    alert("Status Updated Successfully!");
    dispatch(addLoader(false));
  } catch (error) {
    console.log("", error);
    dispatch(addLoader(false));
  }
};
const addLoader = (data) => async (dispatch) => {
  dispatch({
    type: "ADD_LOADER",
    payload: data,
  });
};
const getLoader = (data) => async (dispatch) => {
  dispatch({
    type: "GET_LOADER",
    payload: data,
  });
};

// export const addColortodb = () => async (dispatch) => {
//   await dispatch(addLoader(true));
//   try {
//     const payload = [
//       { label: "Red", value: "#FF0000" },
//       { label: "Green", value: "#00FF00" },
//       { label: "Blue", value: "#0000FF" },
//       { label: "Cyan", value: "#00FFFF" },
//       { label: "Magenta", value: "#FF00FF" },
//       { label: "Yellow", value: "#FFFF00" },
//       { label: "Black", value: "#000000" },
//       { label: "White", value: "#FFFFFF" },
//       { label: "Orange", value: "#FFA500" },
//       { label: "Purple", value: "#800080" },
//       { label: "Lime", value: "#00FF00" },
//       { label: "Pink", value: "#FFC0CB" },
//       { label: "Teal", value: "#008080" },
//       { label: "Lavender", value: "#E6E6FA" },
//       { label: "Brown", value: "#A52A2A" },
//       { label: "Maroon", value: "#800000" },
//       { label: "Navy", value: "#000080" },
//       { label: "Olive", value: "#808000" },
//       { label: "Sky Blue", value: "#87CEEB" },
//       { label: "Turquoise", value: "#40E0D0" },
//       { label: "Indigo", value: "#4B0082" },
//       { label: "Slate Gray", value: "#708090" },
//       { label: "Chocolate", value: "#D2691E" },
//       { label: "Gold", value: "#FFD700" },
//       { label: "Silver", value: "#C0C0C0" },
//       { label: "Dim Gray", value: "#696969" },
//       { label: "Fire Brick", value: "#B22222" },
//       { label: "Forest Green", value: "#228B22" },
//       { label: "Steel Blue", value: "#4682B4" },
//       { label: "Tomato", value: "#FF6347" },
//       { label: "Violet", value: "#EE82EE" },
//       { label: "Rosy Brown", value: "#BC8F8F" },
//       { label: "Aqua", value: "#00FFFF" },
//       { label: "Beige", value: "#F5F5DC" },
//       { label: "Chartreuse", value: "#7FFF00" },
//       { label: "Cornflower Blue", value: "#6495ED" },
//       { label: "Dark Goldenrod", value: "#B8860B" },
//       { label: "Deep Pink", value: "#FF1493" },
//       { label: "Hot Pink", value: "#FF69B4" },
//       { label: "Lemon Chiffon", value: "#FFFACD" },
//       { label: "Medium Orchid", value: "#BA55D3" },
//       { label: "Pale Green", value: "#98FB98" },
//       { label: "Royal Blue", value: "#4169E1" },
//       { label: "Sandy Brown", value: "#F4A460" },
//       { label: "Thistle", value: "#D8BFD8" },
//       { label: "Wheat", value: "#F5DEB3" },
//       { label: "Coral", value: "#FF7F50" },
//       { label: "Dodger Blue", value: "#1E90FF" },
//       { label: "Gainsboro", value: "#DCDCDC" },
//       { label: "Khaki", value: "#F0E68C" },
//       { label: "Medium Purple", value: "#9370DB" },
//       { label: "Pale Violet Red", value: "#DB7093" },
//       { label: "Sienna", value: "#A0522D" },
//       { label: "Tan", value: "#D2B48C" },
//       { label: "Yellow Green", value: "#9ACD32" },
//       { label: "Alice Blue", value: "#F0F8FF" },
//       { label: "Blue Violet", value: "#8A2BE2" },
//       { label: "Crimson", value: "#DC143C" },
//       { label: "Dark Slate Gray", value: "#2F4F4F" },
//       { label: "Goldenrod", value: "#DAA520" },
//       { label: "Ivory", value: "#FFFFF0" },
//       { label: "Lawn Green", value: "#7CFC00" },
//       { label: "Medium Sea Green", value: "#3CB371" },
//       { label: "Orchid", value: "#DA70D6" },
//       { label: "Pale Goldenrod", value: "#EEE8AA" },
//       { label: "Plum", value: "#DDA0DD" },
//       { label: "Salmon", value: "#FA8072" },
//       { label: "Spring Green", value: "#00FF7F" },
//       { label: "Yellow Orange", value: "#FFA500" },
//     ];
//     const firestore = firebase.firestore();
//     const colorsCollection = firestore.collection("colors");

//     // Loop through the payload array and add each object as a separate document
//     for (const colorObj of payload) {
//       await colorsCollection.add(colorObj);
//     }

//     dispatch(addLoader(false));
//   } catch (error) {
//     console.log("Error:", error);
//     dispatch(addLoader(false));
//   }
// };

export const setIsEmailNotExists = (payload) => (dispatch) => {
  try {
    dispatch({ type: "IS_EMAIL_EXISTS", payload });
  } catch (error) {
    console.log("Error: error in setting isEmailExists", error);
  }
};
