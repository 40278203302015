import React, { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Collapse, Form } from "reactstrap";
import { convertToRaw } from "draft-js";
import { convertToHTML } from "draft-convert";
export default function Guides({ editorState, setEditorState ,setConvertedEditorContent}) {
  const [IsOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!IsOpen);
  };
  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
    convertContentToHTML();
  };
  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setConvertedEditorContent(currentContentAsHTML);
  };
  return (
    <div className="my-3 border border-2 p-2 shadow-sm">
      <Form className="my-2">
        <div
          className="d-flex justify-content-between align-items-center cursor"
          onClick={() => {
            toggle();
            setIsOpen(!IsOpen);
          }}
        >
          <h4 className="mb-1 pb-0 font-weight-bold">Guides</h4>
          <i
            className={`fas ${IsOpen ? "fa-chevron-up" : "fa-chevron-down"}`}
          ></i>
        </div>
        <Collapse isOpen={IsOpen}>
          <Editor
          
            editorState={editorState}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            onEditorStateChange={onEditorStateChange}
          />
        </Collapse>
      </Form>
    </div>
  );
}
