import firebase from "firebase";
import { itemUploadingProgress } from "store/actions/itemAction";
import generateFileName from "./generateFileName";

export const uploadVideoFile = async (file, dispatch) => {
  try {
    let fileName = generateFileName(file.name);
    let storageRef = await firebase.storage().ref("itemVideos/" + fileName);

    let uploadTask = storageRef.put(file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        dispatch(itemUploadingProgress(parseInt(progress / 2)));
      },
      (error) => {
        alert("Upload failed due to: " + error.message);
      }
    );

    if (uploadTask) {
      const snapshot = await uploadTask;
      let url = await snapshot.ref.getDownloadURL();
      return url;
    }
  } catch (error) {
    console.log(error);
  }
};

export const deleteVideoFile = async (url) => {
  try {
    if (typeof url === "string" && url !== "") {
      let videoRef = await firebase.storage().refFromURL(url);
      await videoRef.delete();
    }
  } catch (error) {
    console.log(error);
  }
};
