import { combineReducers } from "redux";
import askQuestionsReducer from "./askQuestionsReducer";
import authReducer from "./authReducer";
import authUser from "./authUser";
import categoryReducer from "./categoryReducer";
import countryReducer from "./countryReducer";
import dashboardStatsReducer from "./dashboardStatsReducer";
import distributorReducer from "./distributorReducer";
import itemReducer from "./itemReducer";
import orderReducer from "./orderReducer";
import paymentsReducer from "./paymentsReducer";
import promotionReducer from "./promotionReducer";
import reportAnIssueReducer from "./reportAnIssueReducer";
import reviewReducer from "./reviewsReducer";
import SupportReducer from "./supportReducer";
import ticketsReducer from "./ticketsReducer";
import weekendReducer from "./weekendReducer";

const rootReducer = combineReducers({
  user: authReducer,
  authUser: authUser,
  item: itemReducer,
  category: categoryReducer,
  promotion: promotionReducer,
  distributor: distributorReducer,
  country: countryReducer,
  question: askQuestionsReducer,
  order: orderReducer,
  review: reviewReducer,
  dashboardStats: dashboardStatsReducer,
  reportAnIssue: reportAnIssueReducer,
  ticket: ticketsReducer,
  timerStopped: weekendReducer,
  support: SupportReducer,
  payments:paymentsReducer

});
export default rootReducer;
