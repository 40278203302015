import firebase from "../firebase";
import { RepositoryFactory } from "../../repository/RepositoryFactory";
import { sortDescending } from "utils/sortDescending";
var Dashboard = RepositoryFactory.get("dashboard");

export const getDashboardStats = (sellerId) => async (dispatch) => {
  try {
    const statsDoc = await firebase
      .firestore()
      .collection("users")
      .doc(sellerId)
      .get();
    if (statsDoc.exists) {
      dispatch({
        type: "DASHBOARD_STATS",
        payload: statsDoc.data().stats,
      });
    }
  } catch (error) {
    console.log(error.message);
  }
};
export const getEarningPerformance = (sellerId, year) => async (dispatch) => {
  dispatch(loader(true));
  try {
    const { data } = await Dashboard.getUserEarnings(sellerId, year);
    if (data?.success) {
      let earningsPerformace = [];
      for (let doc of data.data) {
        if (doc) {
          earningsPerformace.push({
            earnings: doc.earnings,
            month: doc.month,
            year: doc.year,
          });
        }
      }
      dispatch(loader(false));
      dispatch({
        type: "EARNINGS",
        payload: earningsPerformace,
      });
    }
  } catch (error) {
    dispatch(loader(false));
    console.log(error.message);
  }
};
export const getLogistics = (SellerID, year) => async (dispatch) => {
  dispatch(logisticsLoader(true));
  try {
    const { data } = await Dashboard.getUserLogictics(SellerID, year);
    if (data?.success) {
      let logisticsPerformance = [];
      for (let doc of data.data) {
        if (doc) {
          logisticsPerformance.push({
            ...doc,
          });
        }
      }
      dispatch(logisticsLoader(false));
      dispatch({
        type: "LOGISTICS",
        payload: logisticsPerformance,
      });
    }
  } catch (error) {
    dispatch(logisticsLoader(false));
    console.log(error.message);
  }
};

export const getStatYearsBy = (sellerId, collection) => async (dispatch) => {
  try {
    dispatch({
      type: "SELLER_YEARS",
      payload: -1,
    });

    const snapshot = await firebase
      .firestore()
      .collection(collection)
      .where("sellerId", "==", sellerId)
      .orderBy("year", "desc") // Assuming "year" field exists in the documents
      .get();

    if (snapshot.empty) {
      // Handle case when no documents are found
      dispatch({
        type: "SELLER_YEARS",
        payload: [],
      });
      return;
    }

    const uniqueYears = new Set();
    snapshot.forEach((doc) => {
      const earningData = doc.data();
      uniqueYears.add(earningData.year);
    });

    // Convert Set to Array and sort it in descending order
    const sortedUniqueYears = Array.from(uniqueYears);

    dispatch({
      type: "SELLER_YEARS",
      payload: sortedUniqueYears,
    });
  } catch (error) {
    console.error("Error fetching years:", error);
    dispatch({
      type: "SELLER_YEARS",
      payload: [],
    });
  }
};

export const loader = (val) => async (dispatch) => {
  dispatch({
    type: "DASHBOARD_LOADING",
    payload: val,
  });
};
export const logisticsLoader = (val) => async (dispatch) => {
  dispatch({
    type: "LOGISTICS_LOADING",
    payload: val,
  });
};
