import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  FormGroup,
  Form,
  Input,
  Spinner,
} from "reactstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { sendInvite } from "../../store/actions/distributorAction";
const DistributorModel = ({
  isOpen,
  toggle,
  type,
  distributorDetail,
  setDistributorDetail,
}) => {
  const dispatch = useDispatch();
  const { addLoading } = useSelector((state) => state.promotion);
  const { uid, user } = useSelector((state) => state.authUser);
  const { countries } = useSelector((state) => state.country);
  // const countryOptions = [
  //   { value: "us", label: "United States" },
  //   { value: "ca", label: "Canada" },
  //   { value: "uae", label: "United Arab Emirates" },
  //   { value: "pak", label: "Pakistan" },
  //   { value: "ind", label: "India" },
  // ];
  const handleInputChange = (e) => {
    if (
      typeof e === "object" &&
      e.hasOwnProperty("value") &&
      e.hasOwnProperty("label")
    ) {
      setDistributorDetail({
        ...distributorDetail,
        country: { label: e.label, value: e.value },
      });
    } else {
      const { name, value } = e.target;
      setDistributorDetail({ ...distributorDetail, [name]: value });
    }
  };
  return (
    <>
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle} className="border-bottom">
          <h3 className="mb-0">Add {type}</h3>
        </ModalHeader>
        <Form
        className="mt--2"
          onSubmit={(e) => {
            e.preventDefault();
            if (
              distributorDetail.name === "" ||
              distributorDetail.email === "" ||
              distributorDetail.country.value === ""
            ) {
              alert("Please fill in all the fields");
              return;
            }
            if (type == "Distributor") {
              let payload = {
                name: distributorDetail?.name,
                email: distributorDetail?.email,
                country: distributorDetail?.country,
                role: "distributor",
                creatorId: uid,
              };
              dispatch(
                sendInvite(payload, () => {
                  toggle();
                  alert("Invite sent successfully!");
                  setDistributorDetail({
                    name: "",
                    email: "",
                    country: { value: "", label: "" },
                  });
                })
              );
            } else {
              let payload = {
                name: distributorDetail?.name,
                email: distributorDetail?.email,
                country: distributorDetail?.country,
                role: "distributor-member",
                creatorId: uid,
                sellerId: user?.creatorId,
              };
              dispatch(
                sendInvite(payload, () => {
                  toggle();
                  alert("Invite sent successfully!");
                  setDistributorDetail({
                    name: "",
                    email: "",
                    country: { value: "", label: "" },
                  });
                })
              );
            }
          }}
        >
          <ModalBody>
            <FormGroup>
              <Label for="exampleName">Name</Label>
              <Input
                id="exampleName"
                name="name"
                placeholder="Enter name"
                type="text"
                value={distributorDetail?.name}
                onChange={(e) => {
                  handleInputChange(e);
                }}
              />
            </FormGroup>
            <FormGroup>
              <Label for="exampleEmail">Email</Label>
              <Input
                id="exampleEmail"
                name="email"
                placeholder="Enter email"
                type="email"
                value={distributorDetail?.email}
                onChange={(e) => {
                  handleInputChange(e);
                }}
              />
            </FormGroup>
            <FormGroup>
              {" "}
              <Label for="exampleCountry">Country</Label>
              <Select
                className="select"
                id="exampleCountry"
                value={distributorDetail?.country}
                onChange={(e) => {
                  handleInputChange(e);
                }}
                options={countries}
                placeholder="Select a country..."
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter className="mb--2">
            <Button
              color="primary"
              type="submit"
              className="px-5 d-flex mx-auto"
              disabled={addLoading}
            >
              {addLoading ? <Spinner size="md" /> : "Submit"}
            </Button>{" "}
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default DistributorModel;
