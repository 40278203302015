import firebase from "../firebase";
import { createNullCache } from "@algolia/cache-common";
import algoliasearch from "algoliasearch";
const client = algoliasearch("AKYAZJGVV9", "f5306d66f87dbf20ad044268210656ed", {
  responsesCache: createNullCache(),
});
export const userIndex = client.initIndex("users");
export const reviewsIndex = client.initIndex("items");

export const getReviews =
  (uid, status, collection, clearState, lastReview, filterBy, productId, batchSize = 4) =>
  async (dispatch) => {
    if (clearState) {
      dispatch({
        type: "GET_REVIEWS",
        payload: [],
        active: true,
        filterBy: filterBy || "",
      });
    }

    await dispatch(Loading(true));
    try {
      let reviewRef = await firebase
        .firestore()
        .collection(collection)
        .where("sellerId", "==", uid);

      let query = reviewRef.limit(batchSize);

      if (productId) {
        query = query.where("productId", "==", productId);
      }

      if (filterBy === "Newest") {
        query = query.orderBy("createdAt", "desc");
      }

     

      await dispatch(
        operateReviews(query, lastReview, status)
      );
    } catch (error) {
      alert(error.message);
      dispatch(Loading(false));
    }
  };

const operateReviews =
  (query, lastReview, status) => async (dispatch, getState) => {
    try {
      function getYesterday() {
        const yesterday = new Date();
        yesterday.setTime(yesterday.getTime() - 24 * 60 * 60 * 1000);
        return yesterday;
      }

      if (status === "published") {
        query = query
          .where("createdAt", "<", getYesterday())
          .where("disputeStatus", "in", ["published", "undisputed"]);
      } else if (status === "recents") {
        query = query
          .where("createdAt", ">", getYesterday())
          .where("disputeStatus", "==", "undisputed");
      } else if (status === "disputes") {
        query = query.where("disputeStatus", "in", [
          "published",
          "removed",
          "disputed",
        ]);
      }

      if (lastReview && lastReview.lastDocument) {
        query = query.startAfter(lastReview.lastDocument);
      }

      let isFirstFetch = lastReview === null;
      const unsubscribe = query.onSnapshot(async (snapshot) => {
        const tempData = [];
        for (const doc of snapshot.docs) {
          const reviewData = doc.data();
          const orderId = reviewData.orderId;
          let customerName = "";
          const orderDoc = await firebase
            .firestore()
            .collection("orders")
            .doc(orderId)
            .get();
          if (orderDoc.exists) {
            let customerId = orderDoc.data().customerId;
            const userDoc = await firebase
              .firestore()
              .collection("users")
              .doc(customerId)
              .get();
            if (userDoc.exists) {
              customerName = userDoc.data().username;
              tempData.push({
                ...reviewData,
                id: doc.id,
                customerName,
              });
            }
          }
        }
        const lastDocument =
          snapshot.docs.length > 0
            ? snapshot.docs[snapshot.docs.length - 1]
            : null;
        const newReview = { lastDocument };
        const previousReviews = getState().review.allReviews;
        const newReviews = tempData.filter((review) => {
          const exists = previousReviews.some(
            (prevQuestion) => prevQuestion.id === review.id
          );
          return !exists;
        });
        let updatedItems = [];
        if (isFirstFetch) {
          isFirstFetch = false;
          dispatch({
            type: "GET_REVIEWS",
            payload: tempData,
            lastDocument: newReview,
            active: true,
          });
          await dispatch(Loading(false));
        } else if (newReviews.length > 0) {
          updatedItems = lastReview
            ? [...previousReviews, ...tempData]
            : tempData;
          dispatch({
            type: "GET_REVIEWS",
            payload: updatedItems,
            lastDocument: newReview,
            active: true,
          });
          await dispatch(Loading(false));
        } else {
          updatedItems = lastReview
            ? [...previousReviews, ...tempData]
            : tempData;
          dispatch({
            type: "GET_REVIEWS",
            payload: updatedItems,
            lastDocument: newReview,
            active: false,
          });
          await dispatch(Loading(false));
        }
      });
      return () => unsubscribe();
    } catch (error) {
      dispatch(Loading(false));
      console.log('error: ', error);
    }
  };

export const Loading = (val) => async (dispatch) => {
  dispatch({ type: "LOADING", payload: val });
};

export const updateSellerReview =
  (reviewId, status, description) => async (dispatch) => {
    const sellerReviews = firebase.firestore().collection("sellerReviews");

    try {
      await sellerReviews.doc(reviewId).update({
        disputeStatus: status,
        disputeDescription: description,
      });

      dispatch({ type: "UPDATE_REVIEW_STATUS", payload: reviewId });
    } catch (error) {
      console.error("Error Updating review:", error);
    }
  };

export const updateweekend = (selectedValue, reviewId) => async (dispatch) => {
  try {
    let weekendsArr = selectedValue.split("/");
    console.log({weekendsArr})
    const data = await firebase
      .firestore()
      .collection("users")
      .doc(reviewId)
      .update({
        weekends: weekendsArr,
      }).then(
        dispatch({
          type:"UPDATE_USER_WEEKEND",
           payload: weekendsArr
        })
      )

    console.log("Ahsan iqbal>>>", selectedValue, data);
    dispatch({ type: "UPDATE_WEEKEND", payload: reviewId });
  } catch (error) {
    console.error("Error Updating review:", error);
  }
};

export const updateProductReview =
  (reviewId, status, description) => async (dispatch) => {
    const productReviews = firebase.firestore().collection("productReviews");

    try {
      await productReviews.doc(reviewId).update({
        disputeStatus: status,
        disputeDescription: description,
      });

      dispatch({ type: "UPDATE_REVIEW_STATUS", payload: reviewId });
    } catch (error) {
      console.error("Error Updating review:", error);
    }
  };