import { role } from "constant/teamRoles";
import jwt from "jsonwebtoken";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";
import { getDashboardStats } from "store/actions/dashboardStatsAction";
import { getOrders } from "store/actions/orderAction";
import { addUserAction, logout } from "../../store/actions/authAction";

const Header = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { hash, inviteVerification, logAuth, user, uid } = useSelector(
    (state) => state.authUser
  );
  const { allOrders } = useSelector((state) => state.order);
  const { stats } = useSelector((state) => state.dashboardStats);
  let sellerId = "";
  useEffect(() => {
    async function getCookieValue(cookieName) {
      const cookies = document.cookie.split("; ");
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const [name, value] = cookie.split("=");
        if (name === cookieName) {
          return decodeURIComponent(value);
        }
      }
      return null;
    }
    const decodeToken = async () => {
      try {
        await getCookieValue("cbo_short_session").then(
          async (cboShortSessionValue) => {
            if (cboShortSessionValue) {
              const decoded = jwt.decode(cboShortSessionValue);
              if (decoded) {
                let userDetail = {};
                if (hash === "seller") {
                  userDetail = {
                    email: decoded?.email,
                    username: decoded.name,
                    role: "seller",
                    organization: "",
                    country: "",
                    stats: {
                      items: 0,
                      promotions: 0,
                      orders: 0,
                      distributors: 0,
                    },
                    active: true,
                  };
                } else {
                  userDetail = {
                    email: decoded?.email,
                    username: decoded.name,
                    role: hash,
                    active: true,
                  };
                }

                // else if (hash === "distributor") {
                //   userDetail = {
                //     email: decoded?.email,
                //     username: decoded.name,
                //     role: "distributor",
                //     active: true,
                //   };
                // } else if (hash === "distributor-member") {
                //   userDetail = {
                //     email: decoded?.email,
                //     username: decoded.name,
                //     role: "distributor-member",
                //     active: true,
                //   };
                // }
                if (!logAuth) {
                  console.log("userDetail: ", JSON.stringify(userDetail));
                  await dispatch(
                    addUserAction(userDetail, inviteVerification, history)
                  );
                }
              }
            } else {
              dispatch(logout(history, ""));
            }
          }
        );
      } catch (error) {
        console.error("JWT decoding failed:", error);
      }
    };
    decodeToken();
  }, []);

  useEffect(() => {
    if (user) {
      if (user.role == "seller" || user.role === role.LOGISTICS) {
        let id = user.role === "seller" ? uid : user.creatorId;
        dispatch(getDashboardStats(id));
      } else if (user?.role == "distributor" || user?.role == role.LOGISTICS) {
        dispatch(
          getOrders(null, user?.creatorId, user.role, user.country.value)
        );
      } else if (user?.role == "distributor-member") {
        dispatch(
          getOrders(null, user?.sellerId, user.role, user.country.value)
        );
      }
    }
  }, [user]);
  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            {/* Card stats */}
            <Row>
              {(user && user.role == "seller") ||
              (user && user?.role == "logistics") ? (
                <>
                  <Col lg="6" xl="3">
                    <Card className="card-stats mb-4 mb-xl-0">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0 fs--12"
                            >
                              Products
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {stats.items}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-primary text-white rounded-circle shadow">
                              <i class="fas fa-list-ul"></i>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="6" xl="3">
                    <Card className="card-stats mb-4 mb-xl-0">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0 fs--12"
                            >
                              Distributors
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {stats.distributors}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                              <i class="ni ni-delivery-fast"></i>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  {user && user.role == "seller" && (
                    <Col lg="6" xl="3">
                      <Card className="card-stats mb-4 mb-xl-0">
                        <CardBody>
                          <Row>
                            <div className="col">
                              <CardTitle
                                tag="h5"
                                className="text-uppercase text-muted mb-0 fs--12"
                              >
                                Promotions
                              </CardTitle>
                              <span className="h2 font-weight-bold mb-0">
                                {stats.promotions}
                              </span>
                            </div>
                            <Col className="col-auto">
                              <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                                <i className="ni ni-notification-70" />
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  )}
                </>
              ) : (
                ""
              )}

              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0 fs--12"
                        >
                          Orders
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {stats && Object.keys(stats).length === 0
                            ? allOrders.length
                            : stats.orders}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-orange text-white rounded-circle shadow">
                          <i className="ni ni-cart" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Header;
