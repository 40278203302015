import React from "react";
import { Badge } from "reactstrap";

const DisputeBadge = ({ status }) => {
  let BadgeColor = {
    removed: "success",
    disputed: "warning",
    published: "danger",
  };
  return (
    <Badge color={BadgeColor[status]}>
      {status === "disputed" ? "pending" : status}
    </Badge>
  );
};

export default DisputeBadge;
