const initialData = {
  laoding: false,
};
const authReducer = (state = initialData, action) => {
  switch (action.type) {
    case "LOGIN_LOADER":
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

export default authReducer;
