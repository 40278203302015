import firebase from "../firebase";
import { v4 as uuidv4 } from "uuid";
import generateFileName from "utils/generateFileName";
import { uploadingProgress } from "./authAction";

export const toggleIssueModal = () => (dispatch) => {
  try {
    dispatch({ type: "SET_MODAL_ISSUE" });
  } catch (error) {
    console.error("Error: modal is not working!", error);
  }
};
export const setIsContinueClicked = (payload) => (dispatch) => {
  try {
    dispatch({ type: "SET_IS_CONTINUE", payload });
  } catch (error) {
    console.error("Error: modal is not working!", error);
  }
};
export const setIssueDetails = (payload) => (dispatch) => {
  try {
    dispatch({ type: "SET_ISSUE_DETAILS", payload });
  } catch (error) {
    console.error("Error: error in setting issue details!", error);
  }
};
export const setIssueDetailsIfUserLoggedIn = () => (dispatch, getState) => {
  try {
    const state = getState();
    const { user, uid } = state?.authUser;
    // console.log("user logged in ID!", uid);
    // console.log("user logged in!", user);
    dispatch(
      setIssueDetails({
        issuerEmail: user.email,
        issuerId: uid,
        issuerName: user?.username,
        role: user?.role,
      })
    );
    dispatch(setEmailFieldDisable(true));
    dispatch(setNameFieldDisable(true));
  } catch (error) {
    console.error("Error: error in setting issue details!", error);
  }
};
export const addIssueToDB =
  (issueDetails, history) => async (dispatch, getState) => {
    try {
      dispatch(setIsLoading(true));
      let videoFile = issueDetails?.videoFile;
      let videoUrl = null;
      if (videoFile) {
        videoUrl = await uploadVideo(videoFile, dispatch);
      }
      let imageURLs = [];
      if (issueDetails?.images && issueDetails?.images?.length > 0) {
        for await (let item of issueDetails?.images) {
          if (item?.originFileObj) {
            let filName1 = item?.originFileObj.name;
            let filName2 = filName1.slice(filName1.lastIndexOf("."));
            let filName3 = uuidv4() + filName2.toLowerCase();
            let storageRef = await firebase
              .storage()
              .ref("PlatformTickets/" + filName3)
              .put(item?.originFileObj);
            let url = await storageRef.ref.getDownloadURL();
            imageURLs.push(url);
          }
        }
      }

      const ticketNo = await generateTicketNumber();

      const issueDetailsWithCreatedAt = {
        ...issueDetails,
        images: imageURLs,
        videoFile: videoUrl,
        createdAt: firebase.firestore.Timestamp.now(),
        history: history,
        ticketNumber: ticketNo,
      };

      // console.log(">>Report an Issue to send in db:", issueDetailsWithCreatedAt);

      await firebase
        .firestore()
        .collection("platformTickets")
        .add(issueDetailsWithCreatedAt);

      const state = getState();
      const { uid } = state?.authUser;

      if (uid) {
        dispatch(
          setIssueDetails({ category: null, description: "", images:[], videoFile: ""})
        );
      } else {
        dispatch(setIssueDetailsToInitial());
        dispatch(setEmailFieldDisable(false));
      }
      dispatch(setIsLoading(false));
      alert("Issue submitted successfully!");
      dispatch(toggleIssueModal());
    } catch (error) {
      console.error("Error: error in setting issue details!", error);
      dispatch(setIsLoading(false));
    }
  };

const generateTicketNumber = async () => {
  const ticketNumbersRef = firebase.firestore().collection("ticketCounter");
  const ticketDoc = await ticketNumbersRef.doc("platformTicketsCounter").get();
  const currentCount = ticketDoc.exists ? ticketDoc.data().count : 0;

  await ticketNumbersRef
    .doc("platformTicketsCounter")
    .set({ count: currentCount + 1 });

  return currentCount + 1;
};

export const uploadVideo = async (file, dispatch) => {
  try {
    let fileName = generateFileName(file.name);
    let storageRef = await firebase
      .storage()
      .ref("PlatformTickets/" + fileName);

    let uploadTask = storageRef.put(file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        dispatch(uploadingProgress(parseInt(progress)));
      },
      (error) => {
        alert("Upload failed due to: " + error.message);
      }
    );

    if (uploadTask) {
      const snapshot = await uploadTask;
      let url = await snapshot.ref.getDownloadURL();
      return url;
    }
  } catch (error) {
    console.log(error);
  }
};

export const checkUserByEmail = (email) => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    // console.log(email);
    const doc = await firebase
      .firestore()
      .collection("users")
      .where("email", "==", email)
      .get();
    if (doc.empty) {
      dispatch(
        setIssueDetails({ issuerName: "", issuerId: "", role: "guest" })
      );
      dispatch(setEmailFieldDisable(false));
      dispatch(setNameFieldDisable(false));
    } else {
      const userRef = doc.docs[0];
      const user = { id: userRef.id, ...userRef.data() };
      // console.log(">>USER:", user);
      dispatch(
        setIssueDetails({
          issuerName: user?.username,
          issuerId: user?.id,
          role: user?.role,
        })
      );
      dispatch(setNameFieldDisable(true));
    }

    dispatch(setIsContinueClicked(true));
    dispatch(setIsLoading(false));
  } catch (error) {
    console.error("Error: error in checking User by email try later!", error);
    dispatch(setIsLoading(false));
  }
};

export const setNameFieldDisable = (payload) => (dispatch) => {
  dispatch({ type: "SET_NAME_FIELD_DISABLE", payload });
};
export const setEmailFieldDisable = (payload) => (dispatch) => {
  dispatch({ type: "SET_EMAIL_FIELD_DISABLE", payload });
};
export const setIssueDetailsToInitial = () => (dispatch) => {
  dispatch({ type: "SET_ISSUE_DETAILS_TO_INITIAL" });
};

export const setIsLoading = (payload) => (dispatch) => {
  dispatch({ type: "SET_IS_LOADING", payload });
};
