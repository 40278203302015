import OnlyHeader from "../components/Headers/OnlyHeader";
import ItemModel from "components/Models/ItemModel";
import ImageModel from "components/Models/ImageModel";
import React, { useState } from "react";
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Spinner,
  CardBody,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getItems, uploadImages } from "../store/actions/itemAction";
import LoadingOverlay from "react-loading-overlay";
import { clearSingleItem, deleteItem } from "../store/actions/itemAction";
import debounce from "lodash/debounce";
import ReviewCard from "components/Reviews/ReviewCard";
import CSVModal from "components/CSVModal";
import { verifyAccountStatus } from "store/actions/paymentsAction";
import { getConnectAccounts } from "store/actions/paymentsAction";

const Products = () => {
  const { allItems, loading, deleteLoading, active, lastDocument } =
    useSelector((state) => state.item);
  const { uid, user } = useSelector((state) => state.authUser);
  const { connectAccounts, accountStatus } = useSelector(
    (state) => state.payments
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [imgModal, setImgModal] = useState(false);
  const [type, setType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedItemImages, setSelectedItemImages] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [reviewDetail, setReviewDetail] = useState([]);
  const [reviewModal, setReviewModal] = useState(false);
  const [search, setSearch] = useState("");
  const [lastDocuments, setLastDocuments] = useState(null);
  const reviewToggle = () => setReviewModal(!reviewModal);
  const [confirmModal, setConfirmModal] = useState(false);
  const confirmtoggle = () => setConfirmModal(!confirmModal);
  const toggle = () => setModal(!modal);
  const imagesToggle = () => setImgModal(!imgModal);
  const [modalCSV, setModalCSV] = useState(false);

  const handleImages = (e, item) => {
    let filesArray = Array.from(e.target.files);
    const allowedExtensions = ["jpg", "jpeg", "png"];
    const validFiles = [];
    const rejectedFiles = [];

    filesArray.forEach((file) => {
      const fileName = file.name.toLowerCase();
      const fileExtension = fileName.split(".").pop();

      if (allowedExtensions.includes(fileExtension)) {
        validFiles.push(file);
      } else {
        rejectedFiles.push(file.name);
      }
    });

    if (rejectedFiles.length > 0) {
      alert(
        `Rejected ${rejectedFiles.length} image(s): ${rejectedFiles.join(
          ", "
        )}. Please choose only jpg, jpeg, or png files.`
      );
    }
    if (validFiles.length === 0) {
      console.error(
        "No valid images selected. Please choose only jpg, jpeg, or png files."
      );
      return;
    }

    dispatch(uploadImages({ images: validFiles, item }, item?.id));
  };

  const toggleCSV = () => setModalCSV(!modalCSV);

  const handleSearch = debounce(async (e) => {
    try {
      await dispatch(getItems(null, uid, e.target.value));
      setIsLoading(false);
    } catch (error) {
      console.error("An error occurred:", error);
      setIsLoading(false);
    }
  }, 3000);

  const handleLoadMore = () => {
    setSearch("");
    dispatch(getItems(lastDocuments, uid, ""));
  };

  useEffect(() => {
    setLastDocuments(lastDocument);
  }, [lastDocument]);

  useEffect(() => {
    dispatch(getConnectAccounts(uid));
    dispatch(verifyAccountStatus(uid));
    dispatch(getItems(null, uid));
    dispatch(clearSingleItem());
  }, [uid]);

  return (
    <>
      <OnlyHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            {!connectAccounts || accountStatus?.status !== "verified" ? (
              connectAccounts === -1 || accountStatus === -1 ? (
                <Card>
                  <CardBody>
                    <div className="mt-7 mb-7 text-center">
                      <Spinner size="sm" />
                    </div>
                  </CardBody>
                </Card>
              ) : (
                <Card className="shadow-sm">
                  <CardBody className="d-flex flex-column justify-content-center align-items-center p-5">
                    <h2 className="text-center mt-3">
                      Unlock the Power of Selling: Supercharge Your Profile with
                      Stripe Before Adding Your Amazing Products!
                    </h2>
                    <Button
                      onClick={() => history.push("/admin/profile")}
                      className="mt-3"
                      color="primary"
                    >
                      Connect with Stripe
                    </Button>
                  </CardBody>
                </Card>
              )
            ) : (
              <LoadingOverlay active={loading} spinner text="Items Loading....">
                <Card className="shadow-sm">
                  <CardHeader className="border-0">
                    <div className="d-flex flex-wrap justify-content-between align-items-center">
                      <h3>Product</h3>
                      <div style={{ maxWidth: "200px" }}>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fas fa-search" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="text"
                            placeholder="Search by name, price"
                            onChange={(e) => {
                              setIsLoading(true);
                              handleSearch(e);
                            }}
                          />
                        </InputGroup>
                      </div>
                      <div className="d-lg-flex d-md-flex flex-column flex-md-row">
                        <Button
                          className="mt-1"
                          color="info"
                          type="button"
                          onClick={toggleCSV}
                        >
                          Upload CSV
                        </Button>
                        <Button
                          className="mt-1 ml-md-2"
                          color="primary"
                          type="button"
                          onClick={() => {
                            history.push({
                              pathname: "/admin/create-item",
                              state: "Create Item",
                            });
                          }}
                        >
                          Create Item
                        </Button>
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody className="p-0">
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead">
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Pricing</th>
                          <th scope="col">Images</th>
                          <th>Rating</th>
                          <th scope="col" className="text-center">
                            Actions
                          </th>
                          <th scope="col" className="text-start">
                            Upload Images
                          </th>
                        </tr>
                      </thead>
                      {allItems?.length === 0 ? (
                        <tbody>
                          <tr>
                            <td colSpan="6" className="text-center">
                              Yet Items not found!
                            </td>
                          </tr>
                        </tbody>
                      ) : (
                        <tbody>
                          {allItems.map((item) => (
                            <tr key={item?.id}>
                              <td className="truncate">
                                <span
                                  className="mb-0 text-sm"
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  title={item?.detail?.name}
                                >
                                  {item?.detail?.name}
                                </span>
                              </td>
                              <td>{item?.detail?.price}</td>
                              <td>
                                <Button
                                  color="info"
                                  onClick={() => {
                                    imagesToggle();
                                    setSelectedItemImages(item?.detail?.images);
                                  }}
                                  size="sm"
                                >
                                  View images
                                </Button>
                              </td>
                              <td>{item?.averageRating || 0}</td>
                              <td className="text-right">
                                <Button
                                  color="success"
                                  onClick={() => {
                                    history.push({
                                      pathname: `/admin/product-reviews/${item?.id}`,
                                      state: item?.detail?.name,
                                    });
                                  }}
                                  size="sm"
                                >
                                  Reviews
                                </Button>
                                <Button
                                  color="primary"
                                  onClick={() => {
                                    history.push({
                                      pathname: `/admin/edit-item/${item?.id}`,
                                      state: "Edit Item",
                                    });
                                  }}
                                  size="sm"
                                >
                                  Edit
                                </Button>
                                <Button
                                  color="danger"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setSelectedItem({
                                      id: item?.id,
                                      detailImages: item?.detail?.images,
                                      cadFile: item?.cadFile,
                                      threeSixty: item?.threeSixtyUrl,
                                    });
                                    confirmtoggle();
                                  }}
                                  size="sm"
                                >
                                  Delete
                                </Button>
                              </td>
                              <td>
                                <label
                                  htmlFor={item?.id}
                                  className="btn-sm btn-primary mt-2 upload-button"
                                  style={{ cursor: "pointer" }}
                                >
                                  Upload
                                </label>
                                <input
                                  multiple
                                  accept=".jpg, .jpeg, .png"
                                  onChange={(e) => handleImages(e, item)}
                                  type="file"
                                  id={item?.id}
                                  className="d-none"
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      )}
                    </Table>
                
                  </CardBody>
                  
                </Card>
                {!loading && (
                      <div className="text-center">
                        {active === false && <p>No more items</p>}
                        <Button
                          onClick={handleLoadMore}
                          className="mx-3 mt-2 text-secondary mb-2"
                          disabled={!active}
                          color="success"
                        >
                          Load more
                        </Button>
                      </div>
                    )}
              </LoadingOverlay>
            )}
          </div>
        </Row>
      </Container>
      <Modal isOpen={reviewModal} toggle={reviewToggle}>
        <ModalHeader toggle={reviewToggle}>Review</ModalHeader>
        <ModalBody>
          <ReviewCard reviewsDetail={reviewDetail} />
        </ModalBody>
      </Modal>
      <ItemModel isOpen={modal} toggle={toggle} type={type} />
      <ImageModel
        isOpen={imgModal}
        selectedItemImages={selectedItemImages}
        toggle={imagesToggle}
      />
      <Modal isOpen={confirmModal} toggle={confirmtoggle}>
        <ModalHeader toggle={confirmtoggle}>Confirmation Box</ModalHeader>
        <ModalBody>
          <h5 className="text-center ">
            Are you sure you want to delete this item?
          </h5>
          <div className="d-flex justify-content-center align-items-center my-3">
            <Button
              className="w-25 mx-2"
              color="primary"
              disabled={deleteLoading}
              onClick={() => {
                dispatch(
                  deleteItem(selectedItem, () => {
                    confirmtoggle();
                  })
                );
              }}
            >
              {deleteLoading ? <Spinner size="md" /> : "Yes"}
            </Button>
            <Button className="w-25" color="danger" onClick={confirmtoggle}>
              No
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <CSVModal toggle={toggleCSV} modal={modalCSV} />
    </>
  );
};

export default Products;
