import OnlyHeader from "../components/Headers/OnlyHeader";
import React, { useState } from "react";
import {
  Card,
  CardHeader,
  Container,
  Row,
  Button,
  Spinner,
  CardBody,
  Col,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardGroup,
  CardImg,
  UncontrolledDropdown,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import LoadingOverlay from "react-loading-overlay";
import { Link } from "@mui/material";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { getSingleTicket } from "store/actions/ticketsActions";
import { updateTicket } from "store/actions/ticketsActions";
import { MdArrowBackIosNew } from "react-icons/md";
import { assignTicket } from "store/actions/ticketsActions";

const TicketDetail = () => {
  const { id } = useParams();
  const history = useHistory();
  const { uid } = useSelector((state) => state.authUser);
  const { singleTicket, ticketsLoading } = useSelector((state) => state.ticket);
  const { usersWithRole } = useSelector((state) => state.distributor);
  const [status, setStatus] = useState("close");
  const [attachmentsIsOpen, setAttachmentsIsOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dispatch = useDispatch();
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [loadingIndex, setloadingIndex] = useState(-1);

  useEffect(() => {
    if (singleTicket) {
      setSelectedTicket(singleTicket);
    }
  }, [singleTicket]);

  useEffect(() => {
    dispatch(getSingleTicket(id));
  }, [dispatch, id]);

  function formatDate(timestamp) {
    const date = new Date(
      timestamp._seconds * 1000 + timestamp._nanoseconds / 1e6
    );
    return date.toLocaleString();
  }
  function getAssignedToName(id) {
    const userFound = usersWithRole?.find((user) => user?.id === id);
    const name = userFound?.username;
    if (name) {
      return name;
    } else {
      return "";
    }
  }
  function getAssignedToInitials(id) {
    const userFound = usersWithRole?.find((user) => user?.id === id);
    const name = userFound?.username?.split(" ");
    if (name && name.length >= 2) {
      return name[0].charAt(0) + name[1].charAt(0);
    } else if (name && name.length === 1) {
      return name[0].charAt(0);
    } else {
      return "";
    }
  }

  const handleDownload = (imageUrl, index) => {
    setloadingIndex(index);
    fetch(`https://cors-platform.herokuapp.com/${imageUrl}`)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.download = "downloaded-image.jpg";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        setloadingIndex(-1);
      })
      .catch((error) => {
        console.error("Error fetching image:", error);
        setloadingIndex(-1);
      });
  };
  const handleUpdateTicket = (ticket, status) => {
    let payload = {
      ticketId: ticket?.id,
      updatedById: uid,
      status: status,
    };
    dispatch(
      updateTicket(payload, () => {
        setSelectedTicket((prev) => ({
          ...prev,
          status: status,
        }));
      })
    );
  };

  return (
    <>
      <OnlyHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <LoadingOverlay
              active={ticketsLoading}
              spinner
              text="Ticket Details Loading...."
            >
              <Card className="shadow-sm">
                <CardHeader className="border-0 ">
                  <Row>
                    <Col lg={3} className="d-flex align-items-center">
                      <MdArrowBackIosNew
                        className="mx-2"
                        style={{ cursor: "pointer" }}
                        onClick={() => history.goBack()}
                      />
                      <h3 className="mb-0  text-capitalize">
                        #{selectedTicket?.ticketNo} {selectedTicket?.category}
                      </h3>
                    </Col>
                    <Col lg={9}>
                      <div className="float-right">
                        <UncontrolledDropdown group size="sm">
                          <Button
                            color={`${
                              selectedTicket?.status === "close"
                                ? "secondary"
                                : "warning"
                            }`}
                            size="sm"
                            className="text-capitalize"
                          >
                            {selectedTicket?.status}
                          </Button>
                          <DropdownToggle
                            caret
                            color={`${
                              selectedTicket?.status === "close"
                                ? "secondary"
                                : "warning"
                            }`}
                          />
                          <DropdownMenu>
                            <DropdownItem
                              onClick={() =>
                                handleUpdateTicket(selectedTicket, "close")
                              }
                            >
                              Close
                            </DropdownItem>
                            <DropdownItem
                              onClick={() =>
                                handleUpdateTicket(selectedTicket, "open")
                              }
                            >
                              Re-Open
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                        <span className="text-sm text-muted ml-2 text-capitalize">
                          {/* <span className="mr-1 mb-0 fs--16 text-uppercase bg-info px-3 py-1 rounded text-white border">
                            {selectedTicket?.assignTo == null ||
                            selectedTicket?.assignTo === ""
                              ? "Unassigned"
                              : getAssignedToInitials(selectedTicket?.assignTo)}
                          </span> */}
                          {selectedTicket?.assignTo
                            ? getAssignedToName(selectedTicket?.assignTo)
                            : ""}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Card
                    className="shadow-sm flex-fill mb-2"
                    color="gray"
                    outline
                  >
                    <CardBody>
                      <Row>
                        <Col sm={6} xs={6}>
                          <h3 className="heading-product fs--16 mt-1 mb-0">
                            <UncontrolledDropdown
                              group
                              size="sm"
                              direction="down"
                            >
                              <Button
                                color="info"
                                size="sm"
                                className="text-uppercase"
                              >
                                {selectedTicket?.assignTo == null ||
                                selectedTicket?.assignTo === ""
                                  ? "Unassigned"
                                  : getAssignedToInitials(
                                      selectedTicket?.assignTo
                                    )}
                              </Button>
                              <DropdownToggle caret color="info" />
                              <DropdownMenu>
                                {usersWithRole?.map((person) => {
                                  return (
                                    <DropdownItem
                                      onClick={() => {
                                        let payload = {
                                          ticketId: selectedTicket?.id,
                                          updatedById: uid,
                                          assignTo: person.id,
                                        };
                                        dispatch(assignTicket(payload));
                                      }}
                                    >
                                      {person?.username}
                                    </DropdownItem>
                                  );
                                })}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                            <strong className="text-capitalize">
                              {" "}
                              {selectedTicket?.assignTo
                                ? getAssignedToName(selectedTicket?.assignTo)
                                : ""}
                            </strong>
                          </h3>
                        </Col>
                        <Col sm={6} xs={6}>
                          <div
                            className="float-right fs--12 text-muted"
                            style={{ letterSpacing: "1px" }}
                          >
                            {selectedTicket?.updatedAt &&
                              formatDate(selectedTicket?.updatedAt)}
                          </div>
                        </Col>
                      </Row>

                      <p className="fs--13 mt-3 text-muted">
                        <strong>{selectedTicket?.description}</strong>
                      </p>
                      <Button
                        size="sm"
                        className="mb-3"
                        onClick={() => setAttachmentsIsOpen((state) => !state)}
                      >
                        Attachments ({selectedTicket?.attachments?.length || 0}){" "}
                        <i
                          className={`fas fa-solid ${
                            attachmentsIsOpen ? "fa-caret-down" : "fa-caret-up"
                          }`}
                        ></i>
                      </Button>
                      {attachmentsIsOpen && (
                        <>
                          {selectedTicket?.attachments?.length > 0 ? (
                            <>
                              <CardGroup className="row row-cols-xl-4 row-cols-lg-3 row-cols-1 row-cols-md-2 row-cols-sm-2 g-4">
                                {selectedTicket?.attachments.length !== 0 &&
                                  selectedTicket?.attachments?.map(
                                    (img, index) => {
                                      return (
                                        <>
                                          <div className="col">
                                            <Card
                                              key={index}
                                              width={25}
                                              className="m-1 border"
                                            >
                                              <CardImg
                                                style={{
                                                  height: "150px",
                                                  width: "100%",
                                                }}
                                                alt={`Attachment ${index + 1}`}
                                                src={img}
                                                top
                                                width="100%"
                                              />
                                              <CardBody className="p-2 text-center">
                                                <Link
                                                  to="#"
                                                  style={{ cursor: "pointer", textDecoration:"none"}}
                                                  onClick={() =>
                                                    handleDownload(img, index)
                                                  }
                                                >
                                                  {loadingIndex == index ? (
                                                    <Spinner size="sm" />
                                                  ) : (
                                                    "Download"
                                                  )}
                                                </Link>
                                              </CardBody>
                                            </Card>
                                          </div>
                                        </>
                                      );
                                    }
                                  )}
                              </CardGroup>
                            </>
                          ) : (
                            <p className="text-center">
                              No Attachments Attached
                            </p>
                          )}
                        </>
                      )}
                    </CardBody>
                  </Card>
                  <hr className="hr my-2" />
                  <Card
                    className="shadow-sm flex-fill mb-2 overflow-scroll"
                    color="gray"
                    outline
                  >
                    <CardHeader className="border-0 ">
                      <h3
                        style={{ letterSpacing: "2px" }}
                        className="mb-0 mt-1 text-uppercase text-center"
                      >
                        History
                      </h3>
                    </CardHeader>
                    <hr className="hr my-0" />
                    <CardBody>
                      {selectedTicket?.history?.length <= 0 ? (
                        <p className="text-center">Yet, No history found!</p>
                      ) : (
                        <>
                          {selectedTicket?.history &&
                            selectedTicket?.history?.map((hist, index) => {
                              return (
                                <Card
                                  className="shadow-sm flex-fill mb-2"
                                  color="info"
                                  outline
                                  key={index}
                                >
                                  <CardBody>
                                    <Row>
                                      <Col sm={6} xs={8}>
                                        <h3 className="heading-product fs--16 mt-1 mb-0">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            className="bi bi-gear text-danger mr-2"
                                            viewBox="0 0 16 16"
                                          >
                                            <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z" />
                                            <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z" />
                                          </svg>
                                          <span className="fs--13 mt-1 text-danger">
                                            {hist?.event ? (
                                              <strong>{hist?.event}</strong>
                                            ) : (
                                              <strong>{hist}</strong>
                                            )}
                                          </span>
                                        </h3>
                                      </Col>
                                      {hist?.createdAt ? (
                                        <Col sm={6} xs={4}>
                                          <div className="fs--10 float-right text-muted">
                                            <em>
                                              {hist?.createdAt &&
                                                formatDate(hist?.createdAt)}
                                            </em>
                                          </div>
                                        </Col>
                                      ) : (
                                        <Col sm={6} xs={4}>
                                          <div className="fs--10 float-right text-muted">
                                            <em>
                                              {selectedTicket?.createdAt &&
                                                formatDate(
                                                  selectedTicket?.createdAt
                                                )}
                                            </em>
                                          </div>
                                        </Col>
                                      )}
                                    </Row>
                                  </CardBody>
                                </Card>
                              );
                            })}
                        </>
                      )}
                    </CardBody>
                  </Card>
                </CardBody>
              </Card>
            </LoadingOverlay>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default TicketDetail;
