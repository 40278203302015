import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input } from "reactstrap";
import { updateProductReview } from "store/actions/reviewsAction";
import { updateSellerReview } from "store/actions/reviewsAction";

const DisputeButton = ({ item, selectedReviews }) => {
  const [modal, setModal] = useState(false);
  const [description, setDescription] = useState('');
const dispatch = useDispatch();
  const toggleModal = () => setModal(!modal);

  const handleSend = () => {
    if(selectedReviews === "Seller Reviews"){    dispatch(updateSellerReview(item, "disputed", description));
  }
  else{
    dispatch(updateProductReview(item, "disputed", description))
  }
    toggleModal();
  };
  return (
    <>
      <Button color="danger" size="sm" onClick={toggleModal}>
        Dispute
      </Button>
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Update Status</ModalHeader>
        <ModalBody>
          <label>Description:</label>
          <Input
            type="textarea"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </ModalBody>
        <ModalFooter>
        <Button color="primary" onClick={handleSend}>
            Send
          </Button>
          {' '}
          <Button color="secondary" onClick={toggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default DisputeButton;