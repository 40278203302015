import React, { useEffect, useState } from "react";
import { Col, Collapse, Form, Row } from "reactstrap";
import Select from "react-select";
import laptop from "../../assets/img/laptop.png";
import phone from "../../assets/img/phone.jpg";
import bike from "../../assets/img/bike.jpg";
import { useSelector } from "react-redux";
export default function RecommendedProducts({
  selectedRecommedProduct,
  setSelectedRecommendProduct,
}) {
  const { selectedCategories } = useSelector((state) => state.item);
  const [IsOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!IsOpen);
  };
  const [recommedProduct, setRecommendProduct] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const handleSelect = (opt) => {
    setSelectedRecommendProduct(opt);
  };
  // const products = [
  //   {
  //     id: 1,
  //     name: "Laptop",
  //     description: "this is the testing description of laptop",
  //     image: laptop,
  //   },
  //   {
  //     id: 2,
  //     name: "Phone",
  //     description: "this is the testing description of phone",
  //     image: phone,
  //   },
  //   {
  //     id: 3,
  //     name: "Bike",
  //     description: "this is the testing description of Bike",
  //     image: bike,
  //   },
  // ];
  useEffect(() => {
    if (selectedCategories && selectedRecommedProduct) {
      const filteredProducts = selectedCategories.filter((product) =>
        selectedRecommedProduct.some(
          (selectedProduct) => selectedProduct.value === product.id
        )
      );
      setAllProducts(filteredProducts);
    }
  }, [selectedCategories, selectedRecommedProduct]);
  useEffect(() => {
    let tempdata = [];
    selectedCategories?.map((item) => {
      return tempdata.push({ label: item?.detail?.name, value: item.id });
    });
    setRecommendProduct(tempdata);
  }, [selectedCategories]);
  return (
    <div className="my-3 border border-2 p-2 shadow-sm">
      <Form className="my-2">
        <div
          className="d-flex justify-content-between align-items-center cursor"
          onClick={() => {
            toggle();
            setIsOpen(!IsOpen);
          }}
        >
          <h4 className="mb-0 pb-0 font-weight-bold">Recommended Products</h4>
          <i
            className={`fas ${IsOpen ? "fa-chevron-up" : "fa-chevron-down"}`}
          ></i>
        </div>
        <Collapse isOpen={IsOpen}>
          <Select
            isMulti
            className="w-50"
            options={recommedProduct}
            value={selectedRecommedProduct}
            onChange={handleSelect}
          />
          <Row className="mt-4">
            {allProducts &&
              allProducts?.map((item) => {
                return (
                  <Col md={4}>
                    <div className="card shadow-sm h-100  d-flex flex-column justify-content-between">
                      <div className="my-auto d-flex align-items-center justify-content-center">
                        <img
                          src={item?.detail?.images[0]}
                          className="card-img-top w-50 h-100"
                          alt="..."
                        />
                      </div>
                      <div className="card-footer">
                        <h3 className="text-center">{item?.detail?.name}</h3>
                        <p className="text-center">
                          {item?.detail?.description}
                        </p>
                      </div>
                    </div>
                  </Col>
                );
              })}
          </Row>
        </Collapse>
      </Form>
    </div>
  );
}
