import Repository from "./Repository";
const STATS = "/stats/seller-earnings";
const LOGISTICS = "/stats/sold-items";
export default {
  getUserEarnings(sellerID, year) {
    return Repository.get(`${STATS}/${sellerID}/${year}`);
  },
  getUserLogictics(SellerID, year) {
    return Repository.get(`${LOGISTICS}/${year}?sellerId=${SellerID}`);
  },
};
