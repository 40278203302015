const initialData = {
  questions: [],
  loading: false,
  addLoader: false,
};
const askQuestionsReducer = (state = initialData, action) => {
  switch (action.type) {
    case "ALL_ASKQUESTIONS":
      return {
        ...state,
        questions: action.payload,
      };
    case "LOADER":
      return {
        ...state,
        loading: action.payload,
      };
    case "ADD_LOADER":
      return {
        ...state,
        addLoader: action.payload,
      };
    default:
      return state;
  }
};

export default askQuestionsReducer;
