const initialData = {
    loading: false,
    allCategories:[],
  };
  const itemReducer = (state = initialData, action) => {
    switch (action.type) {
      case "LOADING":
        return {
          ...state,
          loading: action.payload,
        };
      case "GET_ALL_CATEGORIES":
        return {
          ...state,
          allCategories: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default itemReducer;
  