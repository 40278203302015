import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  FormGroup,
  Form,
  Input,
} from "reactstrap";
const ItemModel = (props) => {
  return (
    <>
      <Modal isOpen={props.isOpen} toggle={props.toggle}>
        <ModalHeader toggle={props.toggle} className="border-bottom">
          <h3 className="mb-0">{props.type} Item</h3>
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="exampleName">Name</Label>
              <Input
                id="exampleName"
                name="name"
                placeholder="Enter Item name"
                type="text"
              />
            </FormGroup>
            <FormGroup>
              <Label for="examplePrice">Pricing</Label>
              <Input
                id="examplePrice"
                name="price"
                placeholder="Enter Item price"
                type="number"
              />
            </FormGroup>
            <FormGroup>
              <Label for="exampleFile">Image</Label>
              <Input
                id="exampleFile"
                name="image"
                type="file"
                accept="image/x-png,image/gif,image/jpeg"
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={props.toggle}
            className="px-5 d-flex mx-auto"
          >
            Submit
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ItemModel;
