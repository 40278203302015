const initialData = {
    supportTicket: {},
  };
  

const SupportReducer = (state = initialData, action) => {
    switch(action.type){
       case "SUPPORT_TICKET":
        return{
            ...state,
            supportTicket:action.payload,
        }
        default:
       return state
    }

}

export default SupportReducer